import React from "react";
import { Link, Redirect } from "react-router-dom";
import SelectSearch from "react-select-search";
import Util from "../../Util";
import style from "./Style.module.css";
import org from "../../asset/org.jpg";
import { withAlert } from "react-alert";
import { withRouter } from "react-router-dom";
//import './style.css';
const monthName = [
  "มกราคม",
  "กุมภาพันธ์",
  "มีนาคม",
  "เมษายน",
  "พฤษภาคม",
  "มิถุนายน",
  "กรกฎาคม",
  "สิงหาคม",
  "กันยายน",
  "ตุลาคม",
  "พฤศจิกายน",
  "ธันวาคม",
];
class EditSheet extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      out: [
        {
          name: "Transfer to BBL x7697 ANANYA CHUENAROM วันที่ 2024-11-02",
          date: "2024-11-02",
          total: "36.00",
        },
        {
          name: "Transfer to KBANK x5631 MS. JANTHARAWIMO วันที่ 2024-11-02",
          date: "2024-11-02",
          total: "204.00",
        },
        {
          name: "โอนไป KBANK x1579 นาย ธีรภัทร ปานวงษ์ วันที่ 2024-11-04",
          date: "2024-11-04",
          total: "7760.00",
        },
        {
          name: "Transfer to KBANK x4395 LAWXTECH CO.,LTD วันที่ 2024-11-05",
          date: "2024-11-05",
          total: "10000.00",
        },
        {
          name: "Transfer to KBANK x4395 LAWXTECH CO.,LTD วันที่ 2024-11-05",
          date: "2024-11-05",
          total: "400.00",
        },
        {
          name: "PAY Payroll        20241106 วันที่ 2024-11-06",
          date: "2024-11-06",
          total: "80086.91",
        },
        {
          name: "IBFT Other Bank T 44d1870245824b0dbda068 วันที่ 2024-11-06",
          date: "2024-11-06",
          total: "77494.29",
        },
        {
          name: "PAY Payroll        20241107 วันที่ 2024-11-07",
          date: "2024-11-07",
          total: "8500.00",
        },
        {
          name: "IBFT Other Bank T 4f703026d06f4058afcf16 วันที่ 2024-11-07",
          date: "2024-11-07",
          total: "44300.00",
        },
        {
          name: "Transfer to SCB x2578 บริษัท วีพี ซินดิเ วันที่ 2024-11-07",
          date: "2024-11-07",
          total: "4000.00",
        },
        {
          name: "Transfer to KBANK x3433 MS. Virawan Saer วันที่ 2024-11-09",
          date: "2024-11-09",
          total: "240.00",
        },
        {
          name: "Transfer to SCB x4662 นาย อดิเทพ ปัสรีจา วันที่ 2024-11-12",
          date: "2024-11-12",
          total: "1423.00",
        },
        {
          name: "Transfer to SCB x4662 นาย อดิเทพ ปัสรีจา วันที่ 2024-11-12",
          date: "2024-11-12",
          total: "964.00",
        },
        {
          name: "จ่ายบิล REVENUE DEPARTMENT วันที่ 2024-11-14",
          date: "2024-11-14",
          total: "9552.69",
        },
        {
          name: "Transfer to KBANK x5110 MS. THATSANAN PU วันที่ 2024-11-21",
          date: "2024-11-21",
          total: "9.00",
        },
        {
          name: "Transfer to KBANK x3155 MS. WARASSAYA SU วันที่ 2024-11-21",
          date: "2024-11-21",
          total: "2926.00",
        },
        {
          name: "Transfer to SCB x4662 นาย อดิเทพ ปัสรีจา วันที่ 2024-11-23",
          date: "2024-11-23",
          total: "10974.00",
        },
        {
          name: "Transfer to SCB x4662 นาย อดิเทพ ปัสรีจา วันที่ 2024-11-23",
          date: "2024-11-23",
          total: "4026.00",
        },
        {
          name: "Transfer to KBANK x6114 MS. MERISA SINGH วันที่ 2024-11-23",
          date: "2024-11-23",
          total: "11640.00",
        },
        {
          name: "Transfer to KTB x7567 POOMPATAI PUNTITPO วันที่ 2024-11-23",
          date: "2024-11-23",
          total: "10000.00",
        },
        {
          name: "Transfer to KTB x7567 POOMPATAI PUNTITPO วันที่ 2024-11-23",
          date: "2024-11-23",
          total: "7760.00",
        },
        {
          name: "Transfer to SCB x3529 นาย จิรวีร์ ตานีพั วันที่ 2024-11-23",
          date: "2024-11-23",
          total: "5389.00",
        },
        {
          name: "Transfer to SCB x4662 นาย อดิเทพ ปัสรีจา วันที่ 2024-11-26",
          date: "2024-11-26",
          total: "8594.00",
        },
        {
          name: "Transfer to SCB x4662 นาย อดิเทพ ปัสรีจา วันที่ 2024-11-26",
          date: "2024-11-26",
          total: "2000.00",
        },
        {
          name: "Transfer to KTB x6530 MISSSIRINAN POOLSI วันที่ 2024-11-27",
          date: "2024-11-27",
          total: "36.00",
        },
        {
          name: "Transfer to SCB x0877 นางสาว ณฐมน อังศุพ วันที่ 2024-11-27",
          date: "2024-11-27",
          total: "139.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.ธัญญลักษณ์ รัตนประดิษฐกุล",
          date: "2024-11-04",
          total: "261.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวปภัชญา สร้อยอากาศ",
          date: "2024-11-04",
          total: "9.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายธนพงษ์ อินต๊ะรัตน์",
          date: "2024-11-04",
          total: "210.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพิชญ์ชนก ขจรบริรักษ์",
          date: "2024-11-04",
          total: "586.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวชญานิศ จิตซื่อ",
          date: "2024-11-04",
          total: "162.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวประภัสสรา",
          date: "2024-11-04",
          total: "1126.17",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นาย พีรดนย์ รัชตะพรทรัพย์",
          date: "2024-11-04",
          total: "93.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว สุธาสินี ประสงค์มณี",
          date: "2024-11-04",
          total: "1396.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวสุชาวดี มะคนมอญ",
          date: "2024-11-04",
          total: "390.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาววรินทร",
          date: "2024-11-04",
          total: "270.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว โกลัญญา แก้วยงกฎ",
          date: "2024-11-04",
          total: "288.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ปีย์วรา พงศาธิรัตน์",
          date: "2024-11-04",
          total: "144.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวชนิกานต์ ธรามานิตย์",
          date: "2024-11-04",
          total: "228.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวฮัยฟาซ สาและ",
          date: "2024-11-04",
          total: "17.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวชลลดา จิตติชานนท์",
          date: "2024-11-04",
          total: "126.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวอัญชิษฐา สุนทรปิยะพันธ์",
          date: "2024-11-04",
          total: "249.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวศศิวิมล",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวณัฐมล อินทะเขื่อน",
          date: "2024-11-04",
          total: "36.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวธัญลักษณ์ สุขเกษมชัย",
          date: "2024-11-04",
          total: "979.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพลอยนิสา จิรมาศสุนทร",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายภูมิพัฒน์ ไชยชาติ",
          date: "2024-11-04",
          total: "180.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว พนิตนันท์ แก้วอุทัยเสถียร",
          date: "2024-11-04",
          total: "57.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวอภิชญา โพธิ์สิน",
          date: "2024-11-04",
          total: "402.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.ปพิชญา ธนนิลกุล",
          date: "2024-11-04",
          total: "160.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายสุพศิน เกษมศักรโยธิน",
          date: "2024-11-04",
          total: "285.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ปณญา นภาพงศ์สุริยา",
          date: "2024-11-04",
          total: "64.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวธนภรณ์ กำเนิดกาญจน์",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวศลิษา อ่อนพานิช",
          date: "2024-11-04",
          total: "36.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นส.พีรยา จีนเมือง",
          date: "2024-11-04",
          total: "270.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวอัญนิกา เพ็ญพร มาแทร์น",
          date: "2024-11-04",
          total: "171.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวบุณยาพร ทองไทยากุล",
          date: "2024-11-04",
          total: "27.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวอารียา ปุณโณปกรณ์",
          date: "2024-11-04",
          total: "239.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวลักษิกา แดงขำ",
          date: "2024-11-04",
          total: "30.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป Miss Kenika Wongpia",
          date: "2024-11-04",
          total: "15.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว อิศราภรณ์",
          date: "2024-11-04",
          total: "30.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายปรมินทร คชารักษ์",
          date: "2024-11-04",
          total: "513.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวอชิรญาณ์ ชัยณรงค์",
          date: "2024-11-04",
          total: "176.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวฉัตร์ฐิตา พรเลิศพิทยกิจ",
          date: "2024-11-04",
          total: "198.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายปัณณวัฒน์เภตรานนท์",
          date: "2024-11-04",
          total: "267.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวเขมิกา",
          date: "2024-11-04",
          total: "297.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวชนัญชิดา ทุมมา",
          date: "2024-11-04",
          total: "407.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.อารีรัตน์ แซ่โค้ว",
          date: "2024-11-04",
          total: "84.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ภาวนา",
          date: "2024-11-04",
          total: "16.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวชลดา พึ่งนุศล",
          date: "2024-11-04",
          total: "312.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายศุภณัฐ นพรัตน์",
          date: "2024-11-04",
          total: "42.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.ศศิกัณฑ์ อยู่ภักดี",
          date: "2024-11-04",
          total: "252.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวหทัยชนก สิริศุภโภ",
          date: "2024-11-04",
          total: "28.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวบุญชภัก นามสีฐาน",
          date: "2024-11-04",
          total: "39.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพัชราภา",
          date: "2024-11-04",
          total: "564.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวลดาวัลย์ อินต๊ะ",
          date: "2024-11-04",
          total: "15.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.ชวัลรัตน์ ตรีวิมล",
          date: "2024-11-04",
          total: "52.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวณัฐชยา ทะเลทอง",
          date: "2024-11-04",
          total: "66.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป Ms. Ploypichcha Anupatanun",
          date: "2024-11-04",
          total: "9.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพิรญาณ์ หมวดใหม่",
          date: "2024-11-04",
          total: "24.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว นิศากร กุลธิดาวัฒน์",
          date: "2024-11-04",
          total: "262.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นาวสาวเบญญาภา สุทธดิส",
          date: "2024-11-04",
          total: "34.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป Patcharapa",
          date: "2024-11-04",
          total: "25.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ชญานิน ดุษฎีกุลวิวัฒน์",
          date: "2024-11-04",
          total: "324.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพิทยารัตน์ โง่นสุข",
          date: "2024-11-04",
          total: "54.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวภะคินี ห่านหริรักษ์",
          date: "2024-11-04",
          total: "57.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ปณญา นภาพงศ์สุริยา",
          date: "2024-11-04",
          total: "104.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ขวัญพร มหัทธนะพฤทธิ์",
          date: "2024-11-04",
          total: "235.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวชาริสา",
          date: "2024-11-04",
          total: "24.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.อักษราภัค พัวไพบูลย์",
          date: "2024-11-04",
          total: "30.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวศศิธร สิทธิ์เหล่าถาวร",
          date: "2024-11-04",
          total: "126.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.ณัฐชา อ้นบุตร",
          date: "2024-11-04",
          total: "210.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ธณิกานต์",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวดุษยา",
          date: "2024-11-04",
          total: "48.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาววรานุชพัฒนสุวรรณ์",
          date: "2024-11-04",
          total: "15.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายธนยศ ศักดิ์ทรัพย์ทวี",
          date: "2024-11-04",
          total: "45.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป ธนสิกานต์",
          date: "2024-11-04",
          total: "42.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป Miss parichamon",
          date: "2024-11-04",
          total: "130.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพรทิพย์ แก้วประเสริฐ",
          date: "2024-11-04",
          total: "676.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นันทภพ ดำรงค์ชีพ",
          date: "2024-11-04",
          total: "12.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.ฐิติรัตน์ ปิติสินชูชัย",
          date: "2024-11-04",
          total: "64.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวชนม์ณกานต์ ปามุทา",
          date: "2024-11-04",
          total: "30.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวบุณยวีร์",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายพงศธร ขุนราช",
          date: "2024-11-04",
          total: "7.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวชลดา มีมูล",
          date: "2024-11-04",
          total: "12.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพิมพิกา เดชประภัสสร",
          date: "2024-11-04",
          total: "24.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวอาจรีย์",
          date: "2024-11-04",
          total: "126.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาววราภรณ์ มาตรา",
          date: "2024-11-04",
          total: "23.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวสุธิมา ผิวเงิน",
          date: "2024-11-04",
          total: "54.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายกฤติเดช วิจิตรโชติ",
          date: "2024-11-04",
          total: "23.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวแพรวา",
          date: "2024-11-04",
          total: "99.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวภาวิณี เรืองชัยศิวเวท",
          date: "2024-11-04",
          total: "108.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว สิริณิกร เสถียรุจิกานนท์",
          date: "2024-11-04",
          total: "60.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพรพรรณ ศิริไพบูลย์",
          date: "2024-11-04",
          total: "125.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นาย เอกวุฒิ โตชูวงศ์",
          date: "2024-11-04",
          total: "36.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายสราวุธ พรหมหาญ",
          date: "2024-11-04",
          total: "60.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายบรรณรุจ",
          date: "2024-11-04",
          total: "12.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวชมพูนุท ฤกษ์สวัสดิ์",
          date: "2024-11-04",
          total: "77.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวอุษา ไกรวงศ์",
          date: "2024-11-04",
          total: "205.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพชรพร อภิภัทรากร",
          date: "2024-11-04",
          total: "16.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป พรรณกานต์",
          date: "2024-11-04",
          total: "61.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.แทนหทัย แสงภู่",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวปรียากร จิวากานนท์",
          date: "2024-11-04",
          total: "22.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.กิติยา มงคลบวรนิมิต",
          date: "2024-11-04",
          total: "19.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวอัญญ์ชิสา ตรีศัชัยนนท์",
          date: "2024-11-04",
          total: "24.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว เรืองริน อ่อนเนียม",
          date: "2024-11-04",
          total: "36.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวนันทชพร",
          date: "2024-11-04",
          total: "24.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายธนพัฒน์ พันธ์ก่ำ",
          date: "2024-11-04",
          total: "36.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวนิชาภา",
          date: "2024-11-04",
          total: "156.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวปวีณ์นุช แก้วมณีวัฒน์",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวปพัชญา คล้ายเครือญาติ",
          date: "2024-11-04",
          total: "54.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวกฤตยา บุญช่วย",
          date: "2024-11-04",
          total: "710.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวปิยฉัตร ไกรแก้ว",
          date: "2024-11-04",
          total: "54.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว จีระภรณ์ เศรษฐเสรี",
          date: "2024-11-04",
          total: "24.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวศิโรมณี เจริญชัย",
          date: "2024-11-04",
          total: "10.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวธีรนาถ",
          date: "2024-11-04",
          total: "60.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.ชญานิน มีสันเทียะ",
          date: "2024-11-04",
          total: "30.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวธนวรรณ ชูชัยยะ",
          date: "2024-11-04",
          total: "159.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ลรินทิพย์ ปานิสัย",
          date: "2024-11-04",
          total: "1.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาววริสรา เจริญอาภารัศมี",
          date: "2024-11-04",
          total: "33.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว แพรววราภรณ์ กลิ่นศรีสุข",
          date: "2024-11-04",
          total: "27.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป ปทิตตา",
          date: "2024-11-04",
          total: "7.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.ยลระวี ชะนะมา",
          date: "2024-11-04",
          total: "90.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว บัวชมพู เจริญพรสุข",
          date: "2024-11-04",
          total: "45.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว กมลชนก น้อยคำ",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป Miss Pannita",
          date: "2024-11-04",
          total: "372.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวธัญชนก เชยพันธุ์",
          date: "2024-11-04",
          total: "30.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป พิมพิไลย์ แดงเจริญ",
          date: "2024-11-04",
          total: "42.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวแก้วทิพย์",
          date: "2024-11-04",
          total: "29.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ณุตรา สันติชัยกุล",
          date: "2024-11-04",
          total: "67.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ธัญลักษณ์ เกียรติอุทัยวงศ์",
          date: "2024-11-04",
          total: "63.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.ณัฐพิมล สมบูรณ์สินชัย",
          date: "2024-11-04",
          total: "36.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวฎยฎา ปุจฉาธรรม",
          date: "2024-11-04",
          total: "15.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวธัญภัทร ธัญรัตน์ศรีสกุล",
          date: "2024-11-04",
          total: "15.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.ฉัตรชนก ผลินสุคนธ์",
          date: "2024-11-04",
          total: "36.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวภัทรดา สุขอ่ำ",
          date: "2024-11-04",
          total: "30.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นาย สุทธินันท์",
          date: "2024-11-04",
          total: "66.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว จุฑามาศ วิกรยานนท์",
          date: "2024-11-04",
          total: "21.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.อามิดาลา ธารไพศาลสมุทร",
          date: "2024-11-04",
          total: "42.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายภูมิพัฒน์ ตันติอลงการ",
          date: "2024-11-04",
          total: "30.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.ฮานีฟะฮ์ โส๊ะประจิน",
          date: "2024-11-04",
          total: "6.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวอะตอม ดีบุตร",
          date: "2024-11-04",
          total: "12.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวฐิติมา เพ็ญประทุม",
          date: "2024-11-04",
          total: "12.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.พิณญดา",
          date: "2024-11-04",
          total: "118.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวนภัสสรณ์ เรี่ยวแรงศรัทธา",
          date: "2024-11-04",
          total: "8.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพันธพร เบ็ญจไชยรัตน์",
          date: "2024-11-04",
          total: "13.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวภูษณิศา พวงศรีเคน",
          date: "2024-11-04",
          total: "12.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวปุญญาพร ทิพย์รส",
          date: "2024-11-04",
          total: "21.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายสันกำแพง กอรี",
          date: "2024-11-04",
          total: "4.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวณฐมน ภคเลิศรวี",
          date: "2024-11-04",
          total: "27.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.ปรางค์ทิพย์ พงศ์เจตน์พงศ์",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวบัณฑิตา ผันอากาศ",
          date: "2024-11-04",
          total: "30.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นาย อภิสิทธิ์ ปานสง่า",
          date: "2024-11-04",
          total: "24.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ณัฐณิชา",
          date: "2024-11-04",
          total: "3.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ริญญารัตน์ ตั้งสุวงศ์ชัยเดชา",
          date: "2024-11-04",
          total: "57.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวดลฤทัย ตะถะวิบูลย์",
          date: "2024-11-04",
          total: "30.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายชลกานต์ เชื้อคำ",
          date: "2024-11-04",
          total: "21.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวญาดา จินาปุก",
          date: "2024-11-04",
          total: "21.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ชนัญชิดา",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว สายธาร สุวรรณเรือง",
          date: "2024-11-04",
          total: "15.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวมนทกานต์ วงษ์ถาวร",
          date: "2024-11-04",
          total: "17.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวธนัชพร โล่กุลทรัพย์",
          date: "2024-11-04",
          total: "72.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส. เอวิตา วงษ์แสงแก้ว",
          date: "2024-11-04",
          total: "24.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว นัจนันทน์ เลื่อมใส",
          date: "2024-11-04",
          total: "30.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวสานันธินี นาคะวิโรจน์",
          date: "2024-11-04",
          total: "60.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวธมนวรรณ นิรันดร์สุข",
          date: "2024-11-04",
          total: "27.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ธิติพร",
          date: "2024-11-04",
          total: "29.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพาทิศา เอี่ยมสอาด",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว นาริฐา พรหมชาติ",
          date: "2024-11-04",
          total: "13.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นุสบา ทองใหญ่",
          date: "2024-11-04",
          total: "33.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวณัฐณี ลีลาวรรณี",
          date: "2024-11-04",
          total: "42.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว กฤติกานต์",
          date: "2024-11-04",
          total: "24.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว กนธิชา เล็กกระจ่าง",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป ศิรฎา ศีลธาดาพงศ์",
          date: "2024-11-04",
          total: "36.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว วรัทยา เลขมาศ",
          date: "2024-11-04",
          total: "58.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.กุลจิรา ศรีวาณิชรักษ์",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวปิยะพร แสงอ่อน",
          date: "2024-11-04",
          total: "10.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว รตา รอดทอง",
          date: "2024-11-04",
          total: "30.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.ภารวี ตันติวิษณุโสภิต",
          date: "2024-11-04",
          total: "102.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส. จิตต์ปิติ เพิ่มพูล",
          date: "2024-11-04",
          total: "27.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวเกศราภรณ์ พรหมพนัส",
          date: "2024-11-04",
          total: "539.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวโชติกา สารียอด",
          date: "2024-11-04",
          total: "570.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.ไอริณ แท่นประมูล",
          date: "2024-11-04",
          total: "23.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว นุจมีย์ แวมูซอ",
          date: "2024-11-04",
          total: "410.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายสิรวิชญ์ ปันกาศ",
          date: "2024-11-04",
          total: "2091.13",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวปาณิสรา",
          date: "2024-11-04",
          total: "1062.15",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพันปวีณ์ กลีบประทุม",
          date: "2024-11-04",
          total: "780.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวจิระภัทร คำค้อม",
          date: "2024-11-04",
          total: "667.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นาย ศุทธวีร์ เนื่องศาสตร์",
          date: "2024-11-04",
          total: "240.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.สมพรรณ์ หาลือ",
          date: "2024-11-04",
          total: "211.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวนฤภัทร ปัฐวี",
          date: "2024-11-04",
          total: "4365.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว จณิสชา พิสิฐเสนีย์",
          date: "2024-11-04",
          total: "315.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวนัชชนิกา รินรุน",
          date: "2024-11-04",
          total: "766.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว สิริรัตน์ ปุรณะวิทย์",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว สิริวรรณ โปธา",
          date: "2024-11-04",
          total: "27.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ธัญสรณ์ บัวราช",
          date: "2024-11-04",
          total: "90.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.พรรษชล โคววนิช",
          date: "2024-11-04",
          total: "90.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายเจตนิภัทธ์ ก๋องชัย",
          date: "2024-11-04",
          total: "613.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป Miss Paravisa Aarre",
          date: "2024-11-04",
          total: "594.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายภัคพงษ์ แพร่สกุลเจริญกิจ",
          date: "2024-11-04",
          total: "60.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นาย ธีรพงศ์ บุญตือ",
          date: "2024-11-04",
          total: "58.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป Sunita juaming",
          date: "2024-11-04",
          total: "1322.30",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวชญาณ์นินท์ งามยอด",
          date: "2024-11-04",
          total: "1983.46",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวนริศา โอดคง",
          date: "2024-11-04",
          total: "84.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.มาลัย อินดี",
          date: "2024-11-04",
          total: "198.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพรรามา เจริญยิ่ง",
          date: "2024-11-04",
          total: "27.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ศุภารนันท์",
          date: "2024-11-04",
          total: "441.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวนภัสสร อินทรส",
          date: "2024-11-04",
          total: "4.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.พัชรพร กันคุ้ม",
          date: "2024-11-04",
          total: "84.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป Mr.Ruayrung Tokamolthum",
          date: "2024-11-04",
          total: "165.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวศศิภาพร",
          date: "2024-11-04",
          total: "558.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายศุภวิชญ์  เทียมแสน",
          date: "2024-11-04",
          total: "195.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.สุนิษา สารวงษ์",
          date: "2024-11-04",
          total: "4594.89",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวปัณพร หมีเฟื่อง",
          date: "2024-11-04",
          total: "90.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวภัทรานิษฐ์ พงษ์มณี",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพรนภา เสมอใจ",
          date: "2024-11-04",
          total: "75.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.กีรติญา กฤตสุวรรณ์",
          date: "2024-11-04",
          total: "135.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวณัฐพร วิลิมพชพรกุล",
          date: "2024-11-04",
          total: "191.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพรพัฒน์",
          date: "2024-11-04",
          total: "504.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว อาทิตยา หน่อเทพ",
          date: "2024-11-04",
          total: "6.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.ภัตติมา พึ่งประชา",
          date: "2024-11-04",
          total: "1558.01",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพิมพ์มาดา",
          date: "2024-11-04",
          total: "3290.63",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว กมลชนก วิทามงคล",
          date: "2024-11-04",
          total: "974.85",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวชณิดา อ้วนมาก",
          date: "2024-11-04",
          total: "270.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว เมธินี มุกดา",
          date: "2024-11-04",
          total: "870.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวรุ่งนภา คำใจ",
          date: "2024-11-04",
          total: "13.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นาย พามณัฎฐ์",
          date: "2024-11-04",
          total: "12.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพัชราภรณ์ แสงจันทร์",
          date: "2024-11-04",
          total: "300.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายปิยราช บุตดีอ้วน",
          date: "2024-11-04",
          total: "3152.69",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวสุพิชฌาย์ สุวรรณปราการ",
          date: "2024-11-04",
          total: "41.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวจิดารัตน์ หลักแหลม",
          date: "2024-11-04",
          total: "291.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว นภสร ยะเป็ง",
          date: "2024-11-04",
          total: "88.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวสุภัสสรา พรหมโยธา",
          date: "2024-11-04",
          total: "12.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวมลธิชา วรรโณ",
          date: "2024-11-04",
          total: "2220.33",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวปลิตา",
          date: "2024-11-04",
          total: "45.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวศิรภัสสร",
          date: "2024-11-04",
          total: "75.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.สมพรรณ์ หาลือ",
          date: "2024-11-04",
          total: "182.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ปรีชญารัตน์ ไชยศิลา",
          date: "2024-11-04",
          total: "171.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวธัญทิพย์",
          date: "2024-11-04",
          total: "426.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวปรารถนา ปัญญา",
          date: "2024-11-04",
          total: "324.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว รวิศรา จันทราพูน",
          date: "2024-11-04",
          total: "114.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายระติพงษ์",
          date: "2024-11-04",
          total: "134.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวเซี่ยวโหยว ฟง",
          date: "2024-11-04",
          total: "357.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาววิลาวัณย์ ปัญโญ",
          date: "2024-11-04",
          total: "195.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวธรรณชนก",
          date: "2024-11-04",
          total: "561.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาววราภรณ์ ไชยนอก",
          date: "2024-11-04",
          total: "36.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวนุมา จินเดหวา",
          date: "2024-11-04",
          total: "504.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว พิมพิชชา ไชยสุทธิ์",
          date: "2024-11-04",
          total: "141.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวโศรดา",
          date: "2024-11-04",
          total: "1004.53",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวฐิติมา หงษ์ภักดี",
          date: "2024-11-04",
          total: "522.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว หนึ่งฤทัย ใจกลม",
          date: "2024-11-04",
          total: "234.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.ทัตพิชา จอมศรี",
          date: "2024-11-04",
          total: "378.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวอรณภา",
          date: "2024-11-04",
          total: "36.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวฐนิศรา ธงทอง",
          date: "2024-11-04",
          total: "228.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวเมธินี ยนตยศ",
          date: "2024-11-04",
          total: "279.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวณัฐพร สุชัยทัศน์ศรี",
          date: "2024-11-04",
          total: "510.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายภูบดินทร์ เทพสุภา",
          date: "2024-11-04",
          total: "84.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป Ratchadawan",
          date: "2024-11-04",
          total: "867.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวอารีย์",
          date: "2024-11-04",
          total: "99.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวเจษฎาพร สิมศิริวงษ์",
          date: "2024-11-04",
          total: "828.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.กนกวรรณ นนทะสร",
          date: "2024-11-04",
          total: "288.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวเสาวณีย์",
          date: "2024-11-04",
          total: "108.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวญาณิน  ดีปินตา",
          date: "2024-11-04",
          total: "26.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวปิยศรี พรมุนีประสิทธิ์",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว สิรภัทร ไพคำนาม",
          date: "2024-11-04",
          total: "501.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวเปรมมิกา  ทะขัติ",
          date: "2024-11-04",
          total: "90.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวเจนนภา ลีลาวศิน",
          date: "2024-11-04",
          total: "208.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว พิชญาลักษณ์ วิจิตรปัญญา",
          date: "2024-11-04",
          total: "213.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป Miss papichaya",
          date: "2024-11-04",
          total: "21.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ฝนพรรษา ธรรมธิ",
          date: "2024-11-04",
          total: "546.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายธิติพันธ์ ศุภสิทธิ์กมล",
          date: "2024-11-04",
          total: "210.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว วรวีร์ สุขศรี",
          date: "2024-11-04",
          total: "45.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวอลันดา ทุมชะ",
          date: "2024-11-04",
          total: "160.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายสรวิชญ์ บุญจันทร์คง",
          date: "2024-11-04",
          total: "222.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวรัตติกาล คล้ายมาลา",
          date: "2024-11-04",
          total: "1059.24",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ภัคจิรา โคกแปะ",
          date: "2024-11-04",
          total: "288.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวนกพิกุล สุขศรีสกุล",
          date: "2024-11-04",
          total: "330.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว เกวลิน ปินะสุ",
          date: "2024-11-04",
          total: "84.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวศุภนารี",
          date: "2024-11-04",
          total: "936.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวอริษา แสนอินต๊ะ",
          date: "2024-11-04",
          total: "48.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวกัลยกร วิริยะไกรศรีกุล",
          date: "2024-11-04",
          total: "54.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายอลงกรณ์ วิริยา",
          date: "2024-11-04",
          total: "9.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป อธิรักษ์ อินทพัฒน์",
          date: "2024-11-04",
          total: "318.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวอนัญญา",
          date: "2024-11-04",
          total: "180.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวชลธิชา สอนมี",
          date: "2024-11-04",
          total: "21.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว อัณร์ชญาน์ ไสยวงค์",
          date: "2024-11-04",
          total: "24.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวเจณิษา วงษามาลย์",
          date: "2024-11-04",
          total: "54.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวกาญจนธัช ยลสิริธัม",
          date: "2024-11-04",
          total: "75.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวชลิตา ตุ่นหล้า",
          date: "2024-11-04",
          total: "15.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวนิสิตา ปันสุวรรณ",
          date: "2024-11-04",
          total: "75.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวจริยา เชียงแรง",
          date: "2024-11-04",
          total: "275.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวศิศิรา",
          date: "2024-11-04",
          total: "231.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายจักริน อ้อปิมปา",
          date: "2024-11-04",
          total: "66.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.วสิตา อินใจ",
          date: "2024-11-04",
          total: "98.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ดีชูก้า เสวะกะ",
          date: "2024-11-04",
          total: "397.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวณัฐธิดา คำมา",
          date: "2024-11-04",
          total: "237.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพนัชกร วีระจินตนา",
          date: "2024-11-04",
          total: "90.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวปวริษา ชนาภัทราวรโชติ",
          date: "2024-11-04",
          total: "1149.45",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ร้อยแก้ว",
          date: "2024-11-04",
          total: "120.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายฉัตร สิงห์หนุ",
          date: "2024-11-04",
          total: "258.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายจักรกฤษณ์ พรหมสูตร์",
          date: "2024-11-04",
          total: "9.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวชุติกาญจน์ กันทวงศ์",
          date: "2024-11-04",
          total: "207.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพัทธ์จิรา โพธิสัตย์",
          date: "2024-11-04",
          total: "37.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวศิขริน เกษม",
          date: "2024-11-04",
          total: "130.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวศิรภัสสร คำมาบุตร",
          date: "2024-11-04",
          total: "96.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวศุภิสรา ม่วงจีบ",
          date: "2024-11-04",
          total: "240.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวภัทรากรณ์ พัฒนจันทร์",
          date: "2024-11-04",
          total: "108.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวสุธารัตน์ แก้วทองหลาง",
          date: "2024-11-04",
          total: "105.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวณัทภัค กูลการขาย",
          date: "2024-11-04",
          total: "130.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวนิษฐ์ธรีย์ โกษีอำนวย",
          date: "2024-11-04",
          total: "468.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวสุปรียา มงคลคลี",
          date: "2024-11-04",
          total: "716.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว นวพร ระรวยทรง",
          date: "2024-11-04",
          total: "15.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว เบญจพร ถาวงษ์",
          date: "2024-11-04",
          total: "168.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวนันทิยา",
          date: "2024-11-04",
          total: "414.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวนันทนา ต๊ะวงษ์",
          date: "2024-11-04",
          total: "48.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.พิชามญชุ์ ฉัตรเฉลิมพงศ์",
          date: "2024-11-04",
          total: "126.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวปณิดา อนุชัย",
          date: "2024-11-04",
          total: "60.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพิมพกานต์",
          date: "2024-11-04",
          total: "90.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว อุมาพร จันดี",
          date: "2024-11-04",
          total: "235.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส. ภูษิตา จันทร์แก้ว",
          date: "2024-11-04",
          total: "81.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวสินีนารถ โลไธสง",
          date: "2024-11-04",
          total: "289.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว จินตรัตน์ อริยศุภกุล",
          date: "2024-11-04",
          total: "233.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวตะวันฉาย คืนดี",
          date: "2024-11-04",
          total: "45.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นส.อาภากร",
          date: "2024-11-04",
          total: "144.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.ทิวาสวัสดิ์ ศิริโสม",
          date: "2024-11-04",
          total: "210.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป ธวัลรัตน์ กังตระกูล",
          date: "2024-11-04",
          total: "45.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว พิมพ์มาดา สุริยะ",
          date: "2024-11-04",
          total: "315.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว สุวภัทร กาชาติ",
          date: "2024-11-04",
          total: "45.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว รัตติยาภรณ์ หมอนประเสริฐ",
          date: "2024-11-04",
          total: "130.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวศศินา ปัญญา",
          date: "2024-11-04",
          total: "108.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวลดาพร โชคสุขทรัพย์สิน",
          date: "2024-11-04",
          total: "1579.55",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวมาลี อินดี",
          date: "2024-11-04",
          total: "6.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นาย เศรษฐพงษ์ อุโฆษกุล",
          date: "2024-11-04",
          total: "121.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว แก้วกัญญพัชร วรปภาดากุล",
          date: "2024-11-04",
          total: "24.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นาย พิชญุตม์ ลิ้มสิริสันติกุล",
          date: "2024-11-04",
          total: "8.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวธนพร พึ่งพา",
          date: "2024-11-04",
          total: "6.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวณัฐชยา แสงมะณี",
          date: "2024-11-04",
          total: "15.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายพงศ์พล นิสยันท์",
          date: "2024-11-04",
          total: "186.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวกานต์ชนิต ภักดี",
          date: "2024-11-04",
          total: "253.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวเขมจิรา มาลัย",
          date: "2024-11-04",
          total: "9.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวฐิติกานต์ อ้ายกาศ",
          date: "2024-11-04",
          total: "162.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.สุนิตา เจือมิ่ง",
          date: "2024-11-04",
          total: "9.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ธัญสุดา บุญเรือง",
          date: "2024-11-04",
          total: "42.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวสราญรัตน์",
          date: "2024-11-04",
          total: "72.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.ฐิตาภา ชุ่มใจ",
          date: "2024-11-04",
          total: "42.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพีรดา อึ้งสุกิจ",
          date: "2024-11-04",
          total: "52.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.ธัญชนก บุญสูงเพชร",
          date: "2024-11-04",
          total: "12.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวสิปรางค์ อาษา",
          date: "2024-11-04",
          total: "100.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวกชกร เอี่ยมสุวรรร",
          date: "2024-11-04",
          total: "120.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายพัชรพงษ์ เนรวงค์",
          date: "2024-11-04",
          total: "309.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว เชษฐ์ธิดา ศิริวัฒนา",
          date: "2024-11-04",
          total: "57.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวอรทัย ชุ่มใจ",
          date: "2024-11-04",
          total: "141.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวชุติกาญจน์ ชัยมงคล",
          date: "2024-11-04",
          total: "106.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นส.ทัตพิชา แก้วประเสริฐกุล",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป ปิยะธิดา โพธาเจริญ",
          date: "2024-11-04",
          total: "29.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาววิลาวัลย์ แสงแก้ว",
          date: "2024-11-04",
          total: "192.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวปวริศา ชาลีวงษ์",
          date: "2024-11-04",
          total: "135.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายสิรภพ โกวิทยานนท์",
          date: "2024-11-04",
          total: "4.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาววรญา บุญจอม",
          date: "2024-11-04",
          total: "24.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวศิริลักษณ์ ต๊ะจี๋",
          date: "2024-11-04",
          total: "52.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป โอลีฟกับเสือดาว",
          date: "2024-11-04",
          total: "108.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพรชิตา เหมราช",
          date: "2024-11-04",
          total: "2.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพิชญธิดา พันระกา",
          date: "2024-11-04",
          total: "264.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวกฤติกา แปงคำ",
          date: "2024-11-04",
          total: "104.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวณัฏฐณิชา วรรณศักดิ์",
          date: "2024-11-04",
          total: "96.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวธัญจิรา เชื้อดี",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.อัญมณี ไซรลบ",
          date: "2024-11-04",
          total: "21.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวณัฐพร บัวสด",
          date: "2024-11-04",
          total: "6.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายวฒนา วฒนดิษย์",
          date: "2024-11-04",
          total: "70.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวจิรัชชา จิตโสภา",
          date: "2024-11-04",
          total: "9.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพัชรนุช วุฒิวณิชย์",
          date: "2024-11-04",
          total: "30.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ปกเกศ สัจจะปกาสิต",
          date: "2024-11-04",
          total: "60.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป ผู้เขียน",
          date: "2024-11-04",
          total: "9.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวนพณัฐ",
          date: "2024-11-04",
          total: "12.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ปาริชาต หงษ์ทอง",
          date: "2024-11-04",
          total: "54.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพิลิปดา เสริมศรีทอง",
          date: "2024-11-04",
          total: "41.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวชนิดา จันทร์สอน",
          date: "2024-11-04",
          total: "96.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายพัชรพล อนันตพันธ์",
          date: "2024-11-04",
          total: "252.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นส.ภวรัญชน์ โตมณีพิทักษ์",
          date: "2024-11-04",
          total: "3.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป Miss Anchanika",
          date: "2024-11-04",
          total: "24.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ขวัญพลอย พลอยกระจ่างศรี",
          date: "2024-11-04",
          total: "81.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวชุลีพร ประคำแหง",
          date: "2024-11-04",
          total: "442.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวณัฐฐิธิดา ไทยเก่ง",
          date: "2024-11-04",
          total: "540.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป กฤติกา",
          date: "2024-11-04",
          total: "30.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวธนะสุดา  พรมจันทร์",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาววราภรณ์ มาตรา",
          date: "2024-11-04",
          total: "3.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว สร้อยฟ้า แก้วศรีพจน์",
          date: "2024-11-04",
          total: "9.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวจิรัชญา พรมตาใกล้",
          date: "2024-11-04",
          total: "72.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวปณิตา ธาตุชนะ",
          date: "2024-11-04",
          total: "33.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว สรินดา ยองเพชร",
          date: "2024-11-04",
          total: "90.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.ณัฐวลัญช๋ เชยบาน",
          date: "2024-11-04",
          total: "17.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวนภัสสร ตันติยะสกุล",
          date: "2024-11-04",
          total: "15.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวกฤติยากร เมืองใจ",
          date: "2024-11-04",
          total: "81.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวเทียนมณี บูรณ",
          date: "2024-11-04",
          total: "3.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวอรกช พินิจสุวรรณ",
          date: "2024-11-04",
          total: "24.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ณัชชา เจริญธนากุล",
          date: "2024-11-04",
          total: "42.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพิชชาภัสส์ เชี่ยวสกุลวัฒนา",
          date: "2024-11-04",
          total: "28.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว สุจิรา สุขแก้ว",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวกานต์ชนิต บุญมา",
          date: "2024-11-04",
          total: "45.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวศุภดา แสงสมนึก",
          date: "2024-11-04",
          total: "6.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป กฤตพร จินาพันธ์",
          date: "2024-11-04",
          total: "12.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.เกตุมณี กางหอม",
          date: "2024-11-04",
          total: "29.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว วาสนา บุญมาศ",
          date: "2024-11-04",
          total: "21.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวรมิตา ธูปทอง",
          date: "2024-11-04",
          total: "7.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป Ms. Lalin Limchitti",
          date: "2024-11-04",
          total: "6.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวญาณิศา สุขดอนญวน",
          date: "2024-11-04",
          total: "6.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว อาทิตยา เรือนทราย",
          date: "2024-11-04",
          total: "6.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.ปภัสรา",
          date: "2024-11-04",
          total: "27.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นาย ชิตพล สุวรรณวงค์",
          date: "2024-11-04",
          total: "90.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวชนิสรา สุภาเวียง",
          date: "2024-11-04",
          total: "6.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวอารียา นาละออง",
          date: "2024-11-04",
          total: "42.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายอิทธิพัทธ์ สามัญเขตกิจ",
          date: "2024-11-04",
          total: "15.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ศตพร สังขวรรณะ",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวปัทมพร  อิ่มขันต์",
          date: "2024-11-04",
          total: "29.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นาย พูนพิตตินันท์ ยุปา",
          date: "2024-11-04",
          total: "7.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว คีตภัทร เจริญสุวรรณ",
          date: "2024-11-04",
          total: "12.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวบุญชนิตา มะณีรัตน์",
          date: "2024-11-04",
          total: "30.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.นารา อยู่มากญาติ",
          date: "2024-11-04",
          total: "27.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว รินรดา วงษ์บูรณาวาทย์",
          date: "2024-11-04",
          total: "12.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวลัญฉกร เขียวอ่อน",
          date: "2024-11-04",
          total: "198.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ชญานิษฐ์ พงษ์เพ็ชร์วิเศษ",
          date: "2024-11-04",
          total: "27.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวปัณฑารีย์ ชัยสุธาทิพย์",
          date: "2024-11-04",
          total: "4.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.ชามา ธรรมจำเนียร",
          date: "2024-11-04",
          total: "90.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวณัฐธิดา รอดพ้น",
          date: "2024-11-04",
          total: "273.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว อิสรีย์ ปุณฑริกพันธ์",
          date: "2024-11-04",
          total: "150.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว กัญญาณัฐ ลือวิริยะพันธุ์",
          date: "2024-11-04",
          total: "532.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว สุพิชฌาย์ จงเพิ่มวัฒนะผล",
          date: "2024-11-04",
          total: "361.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวศศิวิมล บุญมาก",
          date: "2024-11-04",
          total: "120.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.ภูญดา แย้มพรรณราย",
          date: "2024-11-04",
          total: "311.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวกมลวรรณ ปานทองสิน",
          date: "2024-11-04",
          total: "34.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวภัคจิรา ม้าเทศ",
          date: "2024-11-04",
          total: "84.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นาย ณัฏฐพัชร ศรัณย์วรนาถ",
          date: "2024-11-04",
          total: "459.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวอภิชญา",
          date: "2024-11-04",
          total: "39.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวปาลิดา ไชยานนท์",
          date: "2024-11-04",
          total: "48.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป Ms. Sarinya Rattanaruangsup",
          date: "2024-11-04",
          total: "118.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส. ชญานันท์ อนุเอกจิตร",
          date: "2024-11-04",
          total: "30.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ฐิตาภรณ์ จักเดชไชย",
          date: "2024-11-04",
          total: "150.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป Mrs. Wannisa Cheapisanjaroen",
          date: "2024-11-04",
          total: "48.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว สุภัสสรา นิมิตรกอบลาภ",
          date: "2024-11-04",
          total: "171.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ศุภาพิชญ์ อันทะเกต",
          date: "2024-11-04",
          total: "17.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว สิริรัตน์ จิระนภากุล",
          date: "2024-11-04",
          total: "622.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป Ms. Jaomai",
          date: "2024-11-04",
          total: "135.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวอรวรรณ ทองใสพร",
          date: "2024-11-04",
          total: "180.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นาย ศุภชัย ศักดิ์สิงห์สกุล",
          date: "2024-11-04",
          total: "660.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว เบญจภรณ์ วัชรากรณ์ไพศาล",
          date: "2024-11-04",
          total: "82.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป Ms.",
          date: "2024-11-04",
          total: "229.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป จิรกิตต์ แทนนิมิต",
          date: "2024-11-04",
          total: "198.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว รินรดา สรรพวัฒน์",
          date: "2024-11-04",
          total: "71.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวมณฑาทิพย์",
          date: "2024-11-04",
          total: "12.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ปณรรฏฐ์ษร",
          date: "2024-11-04",
          total: "11.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว สาธกา ศิริเยี่ยม",
          date: "2024-11-04",
          total: "9.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ณัฎฐ์ชุดา ปินสุวรรณบุตร",
          date: "2024-11-04",
          total: "45.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวเจนนภา ลีลาวศิน",
          date: "2024-11-04",
          total: "23.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ธัญญพัฒน์",
          date: "2024-11-04",
          total: "78.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นาย ณัฐวุฒิ โนรี",
          date: "2024-11-04",
          total: "30.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวกรรวี จิตต์โกศล",
          date: "2024-11-04",
          total: "6.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป Natcha",
          date: "2024-11-04",
          total: "126.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นส. รวิศรา ทองจันทรา",
          date: "2024-11-04",
          total: "27.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.ภัทรวดี แก้วเจริญสุข",
          date: "2024-11-04",
          total: "36.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป Ms.Thunwalai malakam",
          date: "2024-11-04",
          total: "39.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวอรจิรา",
          date: "2024-11-04",
          total: "15.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว สุธีกานต์ โชติรัตนศักดิ์",
          date: "2024-11-04",
          total: "54.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาววทันยา เอี่ยมทอง",
          date: "2024-11-04",
          total: "30.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวธนพร คูหากาญจน์",
          date: "2024-11-04",
          total: "163.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวณัชชา",
          date: "2024-11-04",
          total: "60.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นาย ดล",
          date: "2024-11-04",
          total: "15.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว วริศรา โภคุบตร",
          date: "2024-11-04",
          total: "6.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวกมลวรรณ อ้อยแขม",
          date: "2024-11-04",
          total: "21.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวจิตกา บัวหอม",
          date: "2024-11-04",
          total: "54.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ณัฏฐณิชา ผงสุข",
          date: "2024-11-04",
          total: "420.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป Miss.Patchiya Posri",
          date: "2024-11-04",
          total: "377.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวอนันญา รอญยุทธ",
          date: "2024-11-04",
          total: "493.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวรัชนีกร รัตนะ",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส. หทัยชนก ชมภูพงษ์",
          date: "2024-11-04",
          total: "216.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายดนัย สาริมา",
          date: "2024-11-04",
          total: "151.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวสุจิตรา อนุมณฑล",
          date: "2024-11-04",
          total: "1.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายธนากร พงษ์โภช",
          date: "2024-11-04",
          total: "534.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวศุภัศศิริ ภูมิภักดิ์",
          date: "2024-11-04",
          total: "99.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นาย ดนัย สาริมา",
          date: "2024-11-04",
          total: "3395.39",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาววราภรณ์ มาตรา",
          date: "2024-11-04",
          total: "216.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวธิรารัตน์ ตีเมืองซ้าย",
          date: "2024-11-04",
          total: "1321.72",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายณรงค์ศักดิ์ พรมดี",
          date: "2024-11-04",
          total: "63.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวอุบลรัตน์ อุดมรัตน์",
          date: "2024-11-04",
          total: "21.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวใบเฟิร์น นิลนามะ",
          date: "2024-11-04",
          total: "6.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพรไพลิน สุธรรมมา",
          date: "2024-11-04",
          total: "72.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวณิชาภัทร กรเพชร",
          date: "2024-11-04",
          total: "57.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพัชรดา ค้ำชู",
          date: "2024-11-04",
          total: "240.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายอภิวัฒน์ นาทองชัย",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น้ำพลอย ดงพลัง",
          date: "2024-11-04",
          total: "9.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว มนัสนันท์ เพียรชัยสงค์",
          date: "2024-11-04",
          total: "174.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว จิดาภา",
          date: "2024-11-04",
          total: "876.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว กิ่งมณี พรปรัชญากุล",
          date: "2024-11-04",
          total: "1105.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวฐิติมากร ยะอนันต์",
          date: "2024-11-04",
          total: "756.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวนรกมล",
          date: "2024-11-04",
          total: "36.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาววรินทร",
          date: "2024-11-04",
          total: "104.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวณิชกานต์ ทานทน",
          date: "2024-11-04",
          total: "2063.77",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส. มนัสชนก อินทรมุขดา",
          date: "2024-11-04",
          total: "672.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป ฐิติชญา",
          date: "2024-11-04",
          total: "531.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาววรัญญา ชัวเมือง",
          date: "2024-11-04",
          total: "522.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.นัสลีน มะยูนุห์",
          date: "2024-11-04",
          total: "429.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวภัทราพร พันธุรัตน์",
          date: "2024-11-04",
          total: "297.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.พลอยสวย",
          date: "2024-11-04",
          total: "60.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวสุภาภรณ์",
          date: "2024-11-04",
          total: "90.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาววัชรพร ชูประยูร",
          date: "2024-11-04",
          total: "705.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวเมธาวี",
          date: "2024-11-04",
          total: "90.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวจีรนันท์ กันทุกข์",
          date: "2024-11-04",
          total: "1319.98",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป Nuttida",
          date: "2024-11-04",
          total: "295.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.ปวีณ์ธิดา",
          date: "2024-11-04",
          total: "558.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวณัฐกานต์",
          date: "2024-11-04",
          total: "480.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว มัชฌิมา ม่วงสวย",
          date: "2024-11-04",
          total: "537.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวสิริกุล มีชูชัย",
          date: "2024-11-04",
          total: "423.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวชยาภรณ์ ทรัพย์เกษมสุข",
          date: "2024-11-04",
          total: "150.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพรรษชล",
          date: "2024-11-04",
          total: "9.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาววรรณวนัช ขวัญดี",
          date: "2024-11-04",
          total: "399.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวชลิดา ธีรมาศมงคล",
          date: "2024-11-04",
          total: "352.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว นัสรีย์ กาเจ",
          date: "2024-11-04",
          total: "888.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวจิราพร",
          date: "2024-11-04",
          total: "126.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.สไลลา หรับหลี",
          date: "2024-11-04",
          total: "60.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวฉัตรติยา แสนทวี",
          date: "2024-11-04",
          total: "619.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวศิริพร ศิริจักรวาล",
          date: "2024-11-04",
          total: "192.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นาย ยชนันท์",
          date: "2024-11-04",
          total: "429.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวภววรรณ คำภีร์",
          date: "2024-11-04",
          total: "282.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวภาสุรีย์ แสงโทโพ",
          date: "2024-11-04",
          total: "858.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวภวริศา",
          date: "2024-11-04",
          total: "4061.78",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.พิมพ์พิศา",
          date: "2024-11-04",
          total: "60.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวปภัทสรา หนูแดง",
          date: "2024-11-04",
          total: "360.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวธิราภรณ์ จันทร์งาม",
          date: "2024-11-04",
          total: "58.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวจันทร์นิภา ผ่องอำไพ",
          date: "2024-11-04",
          total: "1670.34",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว สุพิชญา  เกษมศักรโยธิน",
          date: "2024-11-04",
          total: "798.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวมณีสร วิจารณกุล",
          date: "2024-11-04",
          total: "109.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวธมลวรรณ",
          date: "2024-11-04",
          total: "657.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวธันยมัย ไพยรัตน์",
          date: "2024-11-04",
          total: "350.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ปุณยภา",
          date: "2024-11-04",
          total: "72.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวลักษิกา เล้าเจริญพนา",
          date: "2024-11-04",
          total: "168.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวธมนวรรณ กาญจนอักษร",
          date: "2024-11-04",
          total: "234.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวลภัสรดา เรืองมณี",
          date: "2024-11-04",
          total: "138.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส. สุธารินี ตรีศุภธาร",
          date: "2024-11-04",
          total: "39.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ปริญญา",
          date: "2024-11-04",
          total: "293.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวฤทัยรัตน์ พุดชวา",
          date: "2024-11-04",
          total: "147.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวนิชาภัทร พึ่งผล",
          date: "2024-11-04",
          total: "201.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวกมลชนก  เล็กมณี",
          date: "2024-11-04",
          total: "24.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวกชกร บุญจันทร์",
          date: "2024-11-04",
          total: "4.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวกัญญพัชร ดิษสงวน",
          date: "2024-11-04",
          total: "108.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวสุปรีญา ลิ้มพุทธพงศ์",
          date: "2024-11-04",
          total: "33.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาววรัชญา",
          date: "2024-11-04",
          total: "180.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป Thanyamon",
          date: "2024-11-04",
          total: "135.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว มนัญชยา วิรุฬห์ธนวงศ์",
          date: "2024-11-04",
          total: "9.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพรทิพา พันธ์วงษ์",
          date: "2024-11-04",
          total: "120.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.ศศิกาญจน์",
          date: "2024-11-04",
          total: "180.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ปาจารีย์ บินฮาซัน",
          date: "2024-11-04",
          total: "63.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวญาดา แซ่หลิน",
          date: "2024-11-04",
          total: "261.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพรลภัส เกิดแก้ว",
          date: "2024-11-04",
          total: "216.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวชัญญา ลิมาวงษ์ปราณี",
          date: "2024-11-04",
          total: "552.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป สิริรัตน์",
          date: "2024-11-04",
          total: "81.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป เบญญาภา เบญจจริยาภรณ์",
          date: "2024-11-04",
          total: "192.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวนฤภร บวรสันติสุทธิ์",
          date: "2024-11-04",
          total: "657.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวชนานันท์",
          date: "2024-11-04",
          total: "123.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวโยษิตา สุธิรัตน์",
          date: "2024-11-04",
          total: "108.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป MS. Yanisa Aiempinitkul",
          date: "2024-11-04",
          total: "48.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว รินรดา เพ็ญศิริพัฒนา",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวสุฐาศิณี",
          date: "2024-11-04",
          total: "84.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวเพ็ญเพชร",
          date: "2024-11-04",
          total: "135.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวชลพร ชื่นเจริญ",
          date: "2024-11-04",
          total: "36.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวจิราภรณ์ ตั้งใจซื่อ",
          date: "2024-11-04",
          total: "357.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาววริษฐา จงวิสุทธิ์",
          date: "2024-11-04",
          total: "90.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวขนิษฐา สิงห์ศรีโว",
          date: "2024-11-04",
          total: "39.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวรมย์มณี สาริบุตร",
          date: "2024-11-04",
          total: "111.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว จีราพัชร์",
          date: "2024-11-04",
          total: "72.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวอักษราภัค บุญยสาร",
          date: "2024-11-04",
          total: "105.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายอนุภาพ ขาวสำอางค์",
          date: "2024-11-04",
          total: "703.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายวศิน เอียงสวาท",
          date: "2024-11-04",
          total: "176.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.นีรมัย",
          date: "2024-11-04",
          total: "35.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวบุณรฎา กัจฉมาภรณ์",
          date: "2024-11-04",
          total: "1568.49",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายภัคพล",
          date: "2024-11-04",
          total: "291.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพัชราภรณ์ ข้าวบัว",
          date: "2024-11-04",
          total: "30.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป วัฒนชัย วงษ์พันธุ์",
          date: "2024-11-04",
          total: "24.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.นทีนาถ ตั้งวิฑูรธรรม",
          date: "2024-11-04",
          total: "12.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป บุญยวีร์",
          date: "2024-11-04",
          total: "45.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นาย พชรพนธ์ ประกอบ",
          date: "2024-11-04",
          total: "84.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นาย ชัยชนะ เจริญสิทธิ์",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวขนิษฐา มังคลา",
          date: "2024-11-04",
          total: "36.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวนันท์นภัส แหลมเพชร",
          date: "2024-11-04",
          total: "163.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายรัชชานนท์ แสงจันทร์",
          date: "2024-11-04",
          total: "225.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวชัญญานุช วิเชียรนิตย์",
          date: "2024-11-04",
          total: "30.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวณกมล รัตนเศวตศักดิ์",
          date: "2024-11-04",
          total: "27.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวณฤมน บรรสพผล",
          date: "2024-11-04",
          total: "429.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป Siriwat  phatcharaphanawee",
          date: "2024-11-04",
          total: "387.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป miss kemika atheboon",
          date: "2024-11-04",
          total: "46.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวณัฐกมล",
          date: "2024-11-04",
          total: "216.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวกันตา เสรีรักษ์",
          date: "2024-11-04",
          total: "78.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น้อง.ส.ภิญญาพัชญ์ พรหมเพชร",
          date: "2024-11-04",
          total: "60.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว สุณิชา",
          date: "2024-11-04",
          total: "117.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวอนัญญา",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.ธวัลพร สิริวัฒนสกุล",
          date: "2024-11-04",
          total: "147.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ณัฏฐ์รดา",
          date: "2024-11-04",
          total: "23.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวรัชกานต์ บุณยภักดิ์",
          date: "2024-11-04",
          total: "772.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว นาฏศิลป์ ชื่นเกษม",
          date: "2024-11-04",
          total: "12.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพุธิตา เขียวรัตน์",
          date: "2024-11-04",
          total: "12.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวเจนจิรา กล้าหาญ",
          date: "2024-11-04",
          total: "24.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวกรกนก แท่นพุดซา",
          date: "2024-11-04",
          total: "48.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ณัฏฐธิดา",
          date: "2024-11-04",
          total: "460.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายณัฐกร คล้ายสุบิน",
          date: "2024-11-04",
          total: "294.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวอันนา สัมพันธรัตน์",
          date: "2024-11-04",
          total: "356.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายไชยวัฒน์",
          date: "2024-11-04",
          total: "66.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นาย ภูเบศ",
          date: "2024-11-04",
          total: "70.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว กุลณัฐ คุณสารานนท์",
          date: "2024-11-04",
          total: "222.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป Ms. Wannapa Aunjai",
          date: "2024-11-04",
          total: "76.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวญานิศา ชุมอินทอง",
          date: "2024-11-04",
          total: "12.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวชลธิชา ผจญจิตต์",
          date: "2024-11-04",
          total: "7.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ณัฐริกา รวงทอง",
          date: "2024-11-04",
          total: "46.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว จิรภิญญา",
          date: "2024-11-04",
          total: "141.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวธนัญญา อุทัยฉาย",
          date: "2024-11-04",
          total: "126.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาววลัยภรณ์ ไร่วิบูลย์",
          date: "2024-11-04",
          total: "499.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวณัฐติญาภรณ์",
          date: "2024-11-04",
          total: "252.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวณิชารีย์ ธรรมกุลกระจ่าง",
          date: "2024-11-04",
          total: "252.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป วิภาณี",
          date: "2024-11-04",
          total: "120.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวแพรพรรณ อินทรสุวรรณ",
          date: "2024-11-04",
          total: "47.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายภราดร โรจนพานิชย์",
          date: "2024-11-04",
          total: "135.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายฐิติพันธุ์",
          date: "2024-11-04",
          total: "570.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวดลฤทัย สุจริยา",
          date: "2024-11-04",
          total: "53.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวนัทธ์ทนัน",
          date: "2024-11-04",
          total: "17.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวนภัสวรรณ กำเนิดหิน",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป Miss Petra",
          date: "2024-11-04",
          total: "12.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวมัทวัน จิระเจริญวงศ์ษา",
          date: "2024-11-04",
          total: "66.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายภัทรพล",
          date: "2024-11-04",
          total: "180.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวรมิตา วิสุทธิ์วัฒนศักดิ์",
          date: "2024-11-04",
          total: "34.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ปอฝ้าย สังข์มงคล",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.ธีรารัตน์",
          date: "2024-11-04",
          total: "6.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวจิรัชญา ศรีชะฎา",
          date: "2024-11-04",
          total: "6.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวธีรกานต์",
          date: "2024-11-04",
          total: "9.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.พิจิตราภรณ์ เกษมสุข",
          date: "2024-11-04",
          total: "63.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.การะเกด",
          date: "2024-11-04",
          total: "93.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป Pattanan",
          date: "2024-11-04",
          total: "96.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.ณัฐชนันท์",
          date: "2024-11-04",
          total: "21.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวสไบทิพย์ ปัตตาเทศา",
          date: "2024-11-04",
          total: "418.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.พัฐสุดา หวันดะหวา",
          date: "2024-11-04",
          total: "78.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายศุภโชค ประทีปะผลิน",
          date: "2024-11-04",
          total: "15.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวนภัสสร ยอดระบำ",
          date: "2024-11-04",
          total: "6.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวศิริรัตน์ คำโม้ง",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวสกุลกาญจน์",
          date: "2024-11-04",
          total: "14.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.จัตุมน ด่านตระกูล",
          date: "2024-11-04",
          total: "30.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวศรุตา จินดาเมธี",
          date: "2024-11-04",
          total: "16.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวลลิตวดี",
          date: "2024-11-04",
          total: "69.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว กชพร เกษรบุษบา",
          date: "2024-11-04",
          total: "11.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.กนกวรรณ ภูยานนท์",
          date: "2024-11-04",
          total: "27.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวธัญชนก",
          date: "2024-11-04",
          total: "54.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.จิดาภา ฐิติถาวรวงศ์",
          date: "2024-11-04",
          total: "15.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ณัฐวรรณ โพธิ์งาม",
          date: "2024-11-04",
          total: "30.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวณัฐพร ประดับชัยมงคล",
          date: "2024-11-04",
          total: "30.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป กัญญาณัฐ",
          date: "2024-11-04",
          total: "46.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว วิจิตรตรา",
          date: "2024-11-04",
          total: "35.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป กัญญาวีร์",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว สุพิชฌาย์ นทีพิทักษ์",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายชยธร แง้เจริญกุล",
          date: "2024-11-04",
          total: "120.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวอโนมา",
          date: "2024-11-04",
          total: "21.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ชญานุช สินมา",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นาย ภูริต วรพัฒนพงศ์",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวภัทรวดี วิชัย",
          date: "2024-11-04",
          total: "8631.64",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวศศิปรียา กิตติวราพล",
          date: "2024-11-04",
          total: "2588.15",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวปรียานุช จิตรโคตร",
          date: "2024-11-04",
          total: "4796.26",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวภัทรพร โควสุภัทร",
          date: "2024-11-04",
          total: "195.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพรพรรณ น้อยกัลยา",
          date: "2024-11-04",
          total: "21.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวนุสรา ขำเขว้า",
          date: "2024-11-04",
          total: "31.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว จิราภรณ์",
          date: "2024-11-04",
          total: "3081.69",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป คุณสายชล",
          date: "2024-11-04",
          total: "91.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ณัฐณิชา ชิดโคกสูง",
          date: "2024-11-04",
          total: "2330.91",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาววราภรณ์ มาตรา",
          date: "2024-11-04",
          total: "218.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป ปราณปริยา เชื่อมขุนทด",
          date: "2024-11-04",
          total: "246.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวเบญฑิญา เขียวโพธิ์",
          date: "2024-11-04",
          total: "121.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวปภัสษร เอื้อเฟื้อ",
          date: "2024-11-04",
          total: "54.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพิมพ์มาดา สังตะคุ",
          date: "2024-11-04",
          total: "1573.73",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวณัฐธิวรรณ เต็มเมืองปัก",
          date: "2024-11-04",
          total: "291.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพิมพ์มาดา วิโรจน์ศิรศักดิ์",
          date: "2024-11-04",
          total: "142.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นาย ณภัทร วงค์ปัญญา",
          date: "2024-11-04",
          total: "21.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว วราภรณ์ คันสินธุ์",
          date: "2024-11-04",
          total: "144.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นาย ณัฐกุล สมานใจ",
          date: "2024-11-04",
          total: "420.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายฮาฟีซี  ลูแป",
          date: "2024-11-04",
          total: "36.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว พิมนาริน งัดสันเทียะ",
          date: "2024-11-04",
          total: "394.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายเศรษฐวุฒิ โรจน์บูรณาวงศ์",
          date: "2024-11-04",
          total: "678.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวณัฏฐนิชา ตาสว่าง",
          date: "2024-11-04",
          total: "97.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวจิรัชญา ศรีสระน้อย",
          date: "2024-11-04",
          total: "253.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวณิชากร ปลั่งกลาง",
          date: "2024-11-04",
          total: "121.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวปัญณิษา ไตรณรงค์",
          date: "2024-11-04",
          total: "57.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นส.จิตรฬดา เมฆกระบัว",
          date: "2024-11-04",
          total: "643.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.มินทร์ตรา นุ่นปินปัก",
          date: "2024-11-04",
          total: "60.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป เอกภพ เพ็งบุญ",
          date: "2024-11-04",
          total: "30.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นส.ณัฎฐณิชา ประสิทธิเวชชากูร",
          date: "2024-11-04",
          total: "36.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวเบญจมาพร บานไธสง",
          date: "2024-11-04",
          total: "54.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายธนานุวัติ สุยุพร",
          date: "2024-11-04",
          total: "381.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นาย วิธวินท์",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวเกวลิน อุทุมพร",
          date: "2024-11-04",
          total: "117.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายคมสัน วารีศรี",
          date: "2024-11-04",
          total: "21.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายพีระพงศ์ วิชชุวัชรากร",
          date: "2024-11-04",
          total: "4756.10",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวรัตตาภัค ดาวกระจาย",
          date: "2024-11-04",
          total: "46.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายพิพัฒน์ แลสันเที๊ยะ",
          date: "2024-11-04",
          total: "379.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวนฤภร ณ นคร",
          date: "2024-11-04",
          total: "843.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป Pornsuda JhanPhan",
          date: "2024-11-04",
          total: "59.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาววราพร มีระหันนอก",
          date: "2024-11-04",
          total: "43.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวชลธิชา สร้อยสมุทร",
          date: "2024-11-04",
          total: "10.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพรชนก  พันธุ์หิรัญ",
          date: "2024-11-04",
          total: "42.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวอุษาวรินทร์ ถาวรฤทธิ์",
          date: "2024-11-04",
          total: "42.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นาย อนุชา ชัยกิ่ง",
          date: "2024-11-04",
          total: "108.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพิชชา หาญริษ",
          date: "2024-11-04",
          total: "990.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวกรนันท์ ต้นจันทร์",
          date: "2024-11-04",
          total: "24.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวอรสุมา  วงอรุณ",
          date: "2024-11-04",
          total: "118.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวอาริซ่า พรมพิลา",
          date: "2024-11-04",
          total: "363.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวศุจิกาญจน์ พิมใจ",
          date: "2024-11-04",
          total: "50.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวนันท์นภัส",
          date: "2024-11-04",
          total: "60.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวนภัทร์ สมสวย",
          date: "2024-11-04",
          total: "510.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวสุชานันท์",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวเบญจวรรณ จันทร์ทิพย์",
          date: "2024-11-04",
          total: "34.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว สิริกัญญา หงษ์เจริญ",
          date: "2024-11-04",
          total: "132.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวศุภัชญา บาตรโพธิ์",
          date: "2024-11-04",
          total: "30.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวมัลลิกา สุกใส",
          date: "2024-11-04",
          total: "72.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวศิริวรรณ โยธี",
          date: "2024-11-04",
          total: "11.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายไชยวัฒน์ โพธิ์ราษฎร์",
          date: "2024-11-04",
          total: "252.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวจีรภา คงเป็นนิจ",
          date: "2024-11-04",
          total: "31.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวศศิวิมล พรมจันทร์",
          date: "2024-11-04",
          total: "21.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ณรตพร  ศรีถาวร",
          date: "2024-11-04",
          total: "0.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวเกศวรินทร์",
          date: "2024-11-04",
          total: "7.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายทรงชัย",
          date: "2024-11-04",
          total: "30.90",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นาย มโนธรรม ประทุมเขตต์",
          date: "2024-11-04",
          total: "9.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป กนกวรรณ ภู่เสภา",
          date: "2024-11-04",
          total: "12.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวจารุกัญญ์ พิมลนอก",
          date: "2024-11-04",
          total: "20.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวณัฐภัทร ทัศบุตร",
          date: "2024-11-04",
          total: "27.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายศรัณธนิตย์ พัวพัฒนพงศ์",
          date: "2024-11-04",
          total: "3.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายสาวเพชรลดา",
          date: "2024-11-04",
          total: "108.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวสาวิตรี พิมศร",
          date: "2024-11-04",
          total: "15.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นาย จักรพล แสนสี",
          date: "2024-11-04",
          total: "9.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป MissApitchaya Yangyuen",
          date: "2024-11-04",
          total: "171.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายก้องภพ อุทโยธา",
          date: "2024-11-04",
          total: "9.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวอัณณ์ศยา บางจั่น",
          date: "2024-11-04",
          total: "66.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวนิภาวรรณ หอยสังข์",
          date: "2024-11-04",
          total: "42.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวสุทธิพร พรมพัฒน์",
          date: "2024-11-04",
          total: "6.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป พอฤทัย แรงจบ",
          date: "2024-11-04",
          total: "9.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวนฤชยา ทานุสาร",
          date: "2024-11-04",
          total: "72.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาววรนุช ภูมิโคกรักษ์",
          date: "2024-11-04",
          total: "106.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายนนทวัฒน์ จันทเพ็ชร",
          date: "2024-11-04",
          total: "24.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวปาณิสรา",
          date: "2024-11-04",
          total: "9.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป ณัฐจิรา พันธ์ทอง",
          date: "2024-11-04",
          total: "11.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป มณฑาทิพย์ เชื้อจิตร์",
          date: "2024-11-04",
          total: "30.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาววริศรา นวลตา",
          date: "2024-11-04",
          total: "69.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวกมลชนก ไชยสาร",
          date: "2024-11-04",
          total: "12.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวสุฑามาศ มีเกาะ",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวญดาพิศุทธิ์ ตันวรรณรักษ์",
          date: "2024-11-04",
          total: "42.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว อารีรัตน์ ผ่องใส",
          date: "2024-11-04",
          total: "12.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวธนภรณ์ พูลเมือง",
          date: "2024-11-04",
          total: "180.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายอภิชิต ละคร",
          date: "2024-11-04",
          total: "54.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว สุขาวดี นาคแก้วบาง",
          date: "2024-11-04",
          total: "33.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวนัชชา บุญสวัสดิ์",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายสิทธิศักดิ์ พรหมดีราช",
          date: "2024-11-04",
          total: "15.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.สุกานดา พวงเนตร",
          date: "2024-11-04",
          total: "64.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวอาทิตยา",
          date: "2024-11-04",
          total: "180.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.ปราณปรียา ผ่องสุภาพ",
          date: "2024-11-04",
          total: "57.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นาย ณัฐภัทร ไทยน้อย",
          date: "2024-11-04",
          total: "126.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวสิตานันท์ ชัยประทุม",
          date: "2024-11-04",
          total: "35.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.ณิชนันทน์",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวอินทิรา เอี่ยมทรัพย์",
          date: "2024-11-04",
          total: "141.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายอชิรวิชญ์ สายแวว",
          date: "2024-11-04",
          total: "240.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว พลอยชมพู มะยม",
          date: "2024-11-04",
          total: "36.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวขวัญจิรา",
          date: "2024-11-04",
          total: "13.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวณัฐพร เอี่ยมสมบัติ",
          date: "2024-11-04",
          total: "35.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป ขวัญชนก สุริยะ",
          date: "2024-11-04",
          total: "35.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวชุติกาญจน์ พลประสิทธิ์",
          date: "2024-11-04",
          total: "51.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวญาณิศศาแย้มโสภี",
          date: "2024-11-04",
          total: "9.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว สายธาร ปะดังพลัง",
          date: "2024-11-04",
          total: "8762.59",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว รวงอรุณ หาญยุทธ",
          date: "2024-11-04",
          total: "817.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ณฐมน อังศุพันธุ์",
          date: "2024-11-04",
          total: "139.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาววิชิตา ไชยชาญยุทธ์",
          date: "2024-11-04",
          total: "691.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวกมลวัฒ ศิริพันธ์",
          date: "2024-11-04",
          total: "135.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวกุสุมา",
          date: "2024-11-04",
          total: "558.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นาย ขวัญลดา ตุ้ยพาณิชย์",
          date: "2024-11-04",
          total: "66.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวศิริลักษณ์ เปลี่ยนเหล็ก",
          date: "2024-11-04",
          total: "630.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวอาทิตติยา ปินใจ",
          date: "2024-11-04",
          total: "270.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวกรกช หิริรัตน์",
          date: "2024-11-04",
          total: "30.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว อรศยา",
          date: "2024-11-04",
          total: "108.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นส.ชลธิกานต์ มีดี",
          date: "2024-11-04",
          total: "24.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวปราชญา มีทรัพย์",
          date: "2024-11-04",
          total: "105.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ชัญญานุช สาระติ",
          date: "2024-11-04",
          total: "48.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาววิชญาพร บุญหนุน",
          date: "2024-11-04",
          total: "12.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวนรีกานต์ จันคีรี",
          date: "2024-11-04",
          total: "12.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวสุดารัตน์ ปู่รัตน์",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวดลยา ปิ่นม่วง",
          date: "2024-11-04",
          total: "12.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวปัญญาพร ทองฮวด",
          date: "2024-11-04",
          total: "24.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวธิดารัตน์ พวงรัตน์",
          date: "2024-11-04",
          total: "225.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวหยาดฝน บำรุงบ้านทุ่ม",
          date: "2024-11-04",
          total: "490.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวณัฐริกา มารศรี",
          date: "2024-11-04",
          total: "552.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพิมพ์ชนก แสงจันทร์",
          date: "2024-11-04",
          total: "45.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายณัฐวิทย์ พันฆ้อง",
          date: "2024-11-04",
          total: "9.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว นันทชพร สะใบบาง",
          date: "2024-11-04",
          total: "336.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาววราภรณ์ มาตรา",
          date: "2024-11-04",
          total: "18.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวนิดานุช แสนสุพรรณ",
          date: "2024-11-04",
          total: "15.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวศุภิสรา เพลินสัมพันธ์",
          date: "2024-11-04",
          total: "198.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวญาดา อภิรัชไพฑูรย์",
          date: "2024-11-04",
          total: "15.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวลักษณ์พร วิศาล",
          date: "2024-11-04",
          total: "31.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว จุฑามาศ โคตรแก้ว",
          date: "2024-11-04",
          total: "12.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายนิธิศ เฟื่องประเสริฐ",
          date: "2024-11-04",
          total: "120.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพชรพรรณ พันธ์ประสิทธิเวช",
          date: "2024-11-04",
          total: "12.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาววิภาภรณ์ มาลีรัตน์",
          date: "2024-11-04",
          total: "12.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายเศรษฐวิชญ์ เห็มชาติ",
          date: "2024-11-04",
          total: "36.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวญาดา สนั่นเมือง",
          date: "2024-11-04",
          total: "210.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาววริษฐา ลีลายุวัฒน์",
          date: "2024-11-04",
          total: "21.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวเเพรดาว ระหงษ์",
          date: "2024-11-04",
          total: "6.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวนันทิตา ไต่ตาม",
          date: "2024-11-04",
          total: "6.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวอริศรา จิตธรรม",
          date: "2024-11-04",
          total: "3.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายหลี่ลี่ฟ่ง",
          date: "2024-11-04",
          total: "6.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายชัยรัตน์ ศรีชนะ",
          date: "2024-11-04",
          total: "3.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายทัตเทพ แดงลาด",
          date: "2024-11-04",
          total: "24.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวนูรูลมุมีนะห์ เจ๊ะเด็ง",
          date: "2024-11-04",
          total: "3.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายศิวโรฒ สุคนธาภิรมย์ ณ พัทลุง",
          date: "2024-11-04",
          total: "36.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวณิชารีย์",
          date: "2024-11-04",
          total: "24.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวณัญช์สรัล เมธาวินโภคกร",
          date: "2024-11-04",
          total: "77.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.พัทธนันท์ แดงพันธ์",
          date: "2024-11-04",
          total: "432.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายวัชรินทร์ ขวัญทอง",
          date: "2024-11-04",
          total: "25.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวนฤภร บวรสันติสุทธิ์",
          date: "2024-11-04",
          total: "30.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว พชรดา ซาโนะ",
          date: "2024-11-04",
          total: "45.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวเนตรนภา อิ่มสอาด",
          date: "2024-11-04",
          total: "12.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นาย อันซอรีย์ แก้วพิทักษ์",
          date: "2024-11-04",
          total: "210.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.ธัญญนุช เกียรติสุข",
          date: "2024-11-04",
          total: "261.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวศริญรักษ์ บุญถนอม",
          date: "2024-11-04",
          total: "64.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวบัณฑิตา วัตรสุข",
          date: "2024-11-04",
          total: "54.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวอุไรวรรณ นุชภู่",
          date: "2024-11-04",
          total: "66.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ณัฐนิชา คำบุผา",
          date: "2024-11-04",
          total: "203.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป พนมวัสส์ จันทร์แก้ว",
          date: "2024-11-04",
          total: "60.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวชนากานต์ วิมลโนช",
          date: "2024-11-04",
          total: "60.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ศิรินภา นาคคำแหง",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว กานต์สินี",
          date: "2024-11-04",
          total: "619.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวปานสิณี",
          date: "2024-11-04",
          total: "9.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.แสง ลุงกรม",
          date: "2024-11-04",
          total: "24.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.พิชญ์สินี กงเพชร",
          date: "2024-11-04",
          total: "4.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวสมิตานัน",
          date: "2024-11-04",
          total: "6.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นาย ชลิต ไชยรัตน์",
          date: "2024-11-04",
          total: "23.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ณัฐชา แสงงาม",
          date: "2024-11-04",
          total: "23.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาววรณัน พันธุ์ไพโรจน์",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวกมลวรรณ หว่างพัด",
          date: "2024-11-04",
          total: "6.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวกุลภรณ์ แซ่อึ่ง",
          date: "2024-11-04",
          total: "89.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายธนเดช เดชโชติธนากุล",
          date: "2024-11-04",
          total: "5389.32",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวกมลวรรณ หว่างพัด",
          date: "2024-11-04",
          total: "64.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาววราภรณ์ มาตรา",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.จิดาภา เอี่ยมอรุณกิจ",
          date: "2024-11-04",
          total: "16.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวจุฑามาศ มัคคภาวี",
          date: "2024-11-04",
          total: "24.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว สุชาวดี พี่งอินทร์",
          date: "2024-11-04",
          total: "108.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นาวสาว ณิชาภา ลาภกิตติวรชา",
          date: "2024-11-04",
          total: "423.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.ภาธิศา กิตติจุฑามาศ",
          date: "2024-11-04",
          total: "270.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ชนัญญา พิมพา",
          date: "2024-11-04",
          total: "189.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว กัญญาณัฐ  แสงศรี",
          date: "2024-11-04",
          total: "36.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว แอนนี่ โบ ลุ๊ค",
          date: "2024-11-04",
          total: "126.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป ภัคจิรา วสุวัต",
          date: "2024-11-04",
          total: "264.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวภัคภรณ์ ลีไตรรงค์",
          date: "2024-11-04",
          total: "391.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวกฤติยากร สำอางศรี",
          date: "2024-11-04",
          total: "54.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวโรสยา เข็มขาว",
          date: "2024-11-04",
          total: "408.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวนัฐญาพร ทิศาใต้",
          date: "2024-11-04",
          total: "9.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายธีรพงษ์ สุทธิเสน",
          date: "2024-11-04",
          total: "36.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.วนัสนันท์ ประเสริฐศรี",
          date: "2024-11-04",
          total: "189.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวกัลยกร ธำรงอุดมมงคล",
          date: "2024-11-04",
          total: "3641.57",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นาย พฤกษ์ สัมประสิทธิ์",
          date: "2024-11-04",
          total: "944.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป Phanchita",
          date: "2024-11-04",
          total: "105.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป ธนสิทธิ์ อ่ำทองอยู่",
          date: "2024-11-04",
          total: "297.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวเมธาวี ธิพันธ์",
          date: "2024-11-04",
          total: "45.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวญาติกา ราชสม",
          date: "2024-11-04",
          total: "93.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว อมรรัตน์ เจียะสถิตย์",
          date: "2024-11-04",
          total: "243.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวรัตนา ไชยธงรัตน์",
          date: "2024-11-04",
          total: "225.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป บุษบา",
          date: "2024-11-04",
          total: "60.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวอริศรา",
          date: "2024-11-04",
          total: "173.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ปุณยรัตน์ ธัมอุทัยพร",
          date: "2024-11-04",
          total: "1701.77",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวมาริสา ภูมิจันทร์",
          date: "2024-11-04",
          total: "23.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาววิญาดา จันทรัตนะ",
          date: "2024-11-04",
          total: "90.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวปนัดดา ศรรุ่ง",
          date: "2024-11-04",
          total: "1273.42",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว วนัชพร ทุยเที่ยงสัตย์",
          date: "2024-11-04",
          total: "210.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป ชลชาติ โพธิ์เงิน",
          date: "2024-11-04",
          total: "242.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวนฤมล พวงบุญชู",
          date: "2024-11-04",
          total: "624.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป กิตติกร สมบัติพรพิริยา",
          date: "2024-11-04",
          total: "323.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว เมภากร กลมเกลี้ยง",
          date: "2024-11-04",
          total: "261.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวนัฐวดี เขียวไกร",
          date: "2024-11-04",
          total: "285.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวลักษิกา",
          date: "2024-11-04",
          total: "19.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพิชชาอร สาลีโภชน์",
          date: "2024-11-04",
          total: "111.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายอมินธดา มะลิทอง",
          date: "2024-11-04",
          total: "60.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวโศภิรตา วณิชวัชระกุล",
          date: "2024-11-04",
          total: "108.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวศลิษา เจนสัญญายุทธ",
          date: "2024-11-04",
          total: "126.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.ศุภกานต์ มุ่งหาเงิน",
          date: "2024-11-04",
          total: "1568.49",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ธันยาภรณ์ อนุสรณ์วงศ์ชัย",
          date: "2024-11-04",
          total: "42.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายธนเดช เดชโชติธนากุล",
          date: "2024-11-04",
          total: "447.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป พิชญาพร แก้วเนตร",
          date: "2024-11-04",
          total: "192.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายนภสินธุ์ โภคทรัพย์",
          date: "2024-11-04",
          total: "115.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวณัฐทิตา",
          date: "2024-11-04",
          total: "12.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว สุทธิดา",
          date: "2024-11-04",
          total: "603.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวนริศรา สังข์กรม",
          date: "2024-11-04",
          total: "1739.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวนารีรัตน์ ยางก้อน",
          date: "2024-11-04",
          total: "258.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นาย ถิรภัทร ตันวิทยา",
          date: "2024-11-04",
          total: "75.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวเปมิกา ศรีโมรา",
          date: "2024-11-04",
          total: "424.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวธัญญุตา กมลรัตนกุล",
          date: "2024-11-04",
          total: "144.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.สิรีธร",
          date: "2024-11-04",
          total: "288.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวภัทธรีญา บุญพิทักษ์",
          date: "2024-11-04",
          total: "441.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวเบญญาภา ไชยณรงค์",
          date: "2024-11-04",
          total: "15.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป ชลิดา บำรุง",
          date: "2024-11-04",
          total: "253.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส. ศิวาพร อบมาสุ่ย",
          date: "2024-11-04",
          total: "285.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาววริยา ธนสารวัน",
          date: "2024-11-04",
          total: "90.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นาย ศุภณัฐ จันทร์เลข",
          date: "2024-11-04",
          total: "397.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ณมน",
          date: "2024-11-04",
          total: "495.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว นาตาชา",
          date: "2024-11-04",
          total: "24.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.มินตรา บุญมี",
          date: "2024-11-04",
          total: "267.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวญาณันธร นวมจิตต์",
          date: "2024-11-04",
          total: "72.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวณีรนุช พันธุมาศ",
          date: "2024-11-04",
          total: "162.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวเบญจมาภรณ์ แก้วนุ่ม",
          date: "2024-11-04",
          total: "3614.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวฑิตยา",
          date: "2024-11-04",
          total: "12.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว เบญญาภา พวงอินทร์",
          date: "2024-11-04",
          total: "475.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวสิรภัทร โอปนพันธุ์",
          date: "2024-11-04",
          total: "209.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาววัลลภาภรณ์ สุนทรวิวัฒนาการ",
          date: "2024-11-04",
          total: "45.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว นภัสสร ยอดแก้ว",
          date: "2024-11-04",
          total: "12.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวลลิตา สุมณฑา",
          date: "2024-11-04",
          total: "219.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว รัตนธรรม เทียนส่องสกุล",
          date: "2024-11-04",
          total: "27.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ปิย์วรา ทินวัง",
          date: "2024-11-04",
          total: "405.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวสลิลทิพย์",
          date: "2024-11-04",
          total: "39.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว วสุธาร แซ่เตียว",
          date: "2024-11-04",
          total: "553.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นภาภรณ์",
          date: "2024-11-04",
          total: "276.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว นิโลบล ยอดจิตร์",
          date: "2024-11-04",
          total: "63.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายชนารัท ทองอินทร์",
          date: "2024-11-04",
          total: "512.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวอาทิตยา เยอเสาะ",
          date: "2024-11-04",
          total: "135.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ขวัญข้าว แก้วคำ",
          date: "2024-11-04",
          total: "805.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว วริสรา หวังวรวาณิชย์",
          date: "2024-11-04",
          total: "90.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวปัญจนาภรณ์",
          date: "2024-11-04",
          total: "81.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นาวสาว ธารีทิพย์",
          date: "2024-11-04",
          total: "210.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวธีร์วรา อารีรอบ",
          date: "2024-11-04",
          total: "763.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป เอ้ะเอ",
          date: "2024-11-04",
          total: "35.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป ณัฐวรรณ ทระกุลพันธ์",
          date: "2024-11-04",
          total: "15.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวสุชาดา สุวิวัฒนา",
          date: "2024-11-04",
          total: "42.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวนุชวรา วิจักษณาพงษ์",
          date: "2024-11-04",
          total: "177.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวนพรัตน์ ภูมี",
          date: "2024-11-04",
          total: "753.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ณัฐวรา อินทร์ปิ่น",
          date: "2024-11-04",
          total: "54.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวชนัญชิดา นคราวงศ์",
          date: "2024-11-04",
          total: "208.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส นันธิดา สืบเพ็ชร",
          date: "2024-11-04",
          total: "60.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวณัฏฐชา ดอกสันเทียะ",
          date: "2024-11-04",
          total: "699.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวณัฐณิชา ชิณมาตย์",
          date: "2024-11-04",
          total: "15.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวนภสร",
          date: "2024-11-04",
          total: "45.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวชนกานต์",
          date: "2024-11-04",
          total: "54.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส ธันยาภัทร์ ธนวัจน์อัฐญา",
          date: "2024-11-04",
          total: "147.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวรพีภัทร ท้วมชมธรรม",
          date: "2024-11-04",
          total: "413.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวนภัสวรรณ คำเกิด",
          date: "2024-11-04",
          total: "97.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวภัทรวดี",
          date: "2024-11-04",
          total: "36.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.ณภัทร",
          date: "2024-11-04",
          total: "358.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป Thidarat",
          date: "2024-11-04",
          total: "108.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป รัตน์ชนก",
          date: "2024-11-04",
          total: "57.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวนันทัชพร เวฬุวรรณ",
          date: "2024-11-04",
          total: "63.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ศิริกัลยา เงินคำ",
          date: "2024-11-04",
          total: "17.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายจิระวัฒน์ ประภาพันธ์",
          date: "2024-11-04",
          total: "72.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวธนิษฐา วงษ์สิงห์",
          date: "2024-11-04",
          total: "30.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวนารีรัตน์ อัครพัฒนากูล",
          date: "2024-11-04",
          total: "108.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวชานิดา สิทธิวิไล",
          date: "2024-11-04",
          total: "10.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวอัญธิกา หรุ่นสกุล",
          date: "2024-11-04",
          total: "57.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวชญานิษฐ์ นาคศรีสังข์",
          date: "2024-11-04",
          total: "162.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวบุณยนุช",
          date: "2024-11-04",
          total: "282.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายธัญเทพ",
          date: "2024-11-04",
          total: "107.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวปิยวรรณ ศรีพูล",
          date: "2024-11-04",
          total: "36.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวกฤตพร จันทร์คง",
          date: "2024-11-04",
          total: "58.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ชุติกาญจน์ อิทธิศักดิ์สกุล",
          date: "2024-11-04",
          total: "31.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวกัญญาพัชร เสือท้วม",
          date: "2024-11-04",
          total: "510.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวณัฏฐนิช กาญจรัส",
          date: "2024-11-04",
          total: "12.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวชนัฐดา",
          date: "2024-11-04",
          total: "124.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายสรวิศ ชาวพัวทอง",
          date: "2024-11-04",
          total: "84.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวอริสรา ปักกาเว",
          date: "2024-11-04",
          total: "351.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.มนัญญา",
          date: "2024-11-04",
          total: "15.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.สรัลชนา",
          date: "2024-11-04",
          total: "108.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ญาณิศา อังคณาวิศัลย์",
          date: "2024-11-04",
          total: "244.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวธัญนาฏ ณ นคร",
          date: "2024-11-04",
          total: "354.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นาย ปัณณทัต",
          date: "2024-11-04",
          total: "1196.01",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวภาวิดา",
          date: "2024-11-04",
          total: "102.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.เขมศิริ จารุนันท์",
          date: "2024-11-04",
          total: "210.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวกัลยรัตน์ เพชรแพง",
          date: "2024-11-04",
          total: "15.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวโศธนา",
          date: "2024-11-04",
          total: "41.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส. เขมศิริ จารุนันท์",
          date: "2024-11-04",
          total: "420.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวอธิชา แน่นอุดร",
          date: "2024-11-04",
          total: "29.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวนิธินันท์ ณัฐนิธิโรจน์",
          date: "2024-11-04",
          total: "48.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวอรพรรณ ราชยม",
          date: "2024-11-04",
          total: "66.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายนัฐนันท์",
          date: "2024-11-04",
          total: "39.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวภาสิตา ธัญนันทน์",
          date: "2024-11-04",
          total: "87.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวอภิสรา มากล้น",
          date: "2024-11-04",
          total: "324.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวกัญญาวีร์ ถาวรจิต",
          date: "2024-11-04",
          total: "94.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวสิริวรรณ มงคลชัยพัฒนา",
          date: "2024-11-04",
          total: "69.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายนนทพัทธ์ วัฒนโยธิน",
          date: "2024-11-04",
          total: "228.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวอภิชญา อันปัญญา",
          date: "2024-11-04",
          total: "303.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวสิริกานต์",
          date: "2024-11-04",
          total: "819.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายกีรติ จำปาศรี",
          date: "2024-11-04",
          total: "1282.15",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวกชกร",
          date: "2024-11-04",
          total: "222.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.พิมพ์ลดา รวินาราสวัสดิ์",
          date: "2024-11-04",
          total: "79.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาววิภาวี กุลวงศ์",
          date: "2024-11-04",
          total: "23.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายภานุวัฒน์ นาพรมเทพ",
          date: "2024-11-04",
          total: "54.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.อาทิตยา",
          date: "2024-11-04",
          total: "351.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวภัครวรรณ",
          date: "2024-11-04",
          total: "9.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว กมลรดา บุญฉัตรเมือง",
          date: "2024-11-04",
          total: "192.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป ภูกิจ เทศนอก",
          date: "2024-11-04",
          total: "12.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวปัทมพร จันทร์โต",
          date: "2024-11-04",
          total: "72.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวณัฐนันท์ ศรีเดช",
          date: "2024-11-04",
          total: "39.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวนภสร ขันธนะเวชชศักดิ์",
          date: "2024-11-04",
          total: "15.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวปราณปรีญา สเลลานนท์",
          date: "2024-11-04",
          total: "84.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวณัฐณิชา พันธ์ศรี",
          date: "2024-11-04",
          total: "11.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวมานาทิพย์ อัตถาชน",
          date: "2024-11-04",
          total: "52.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวมันนา คหาปนะ",
          date: "2024-11-04",
          total: "5.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวจิตธีรา วงศ์กระพันธุ์",
          date: "2024-11-04",
          total: "17.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวธันสินี อิสริยานุพงศ์",
          date: "2024-11-04",
          total: "150.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายไอลวิล ศรีตะเจริญไพบูลย์",
          date: "2024-11-04",
          total: "240.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาววาเนสสา พลอยไพลิน ร็อคเคอร์",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพิชญา มณีสาคร",
          date: "2024-11-04",
          total: "57.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวกายา สัจจเลิศรัตน์",
          date: "2024-11-04",
          total: "219.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวนภัสสร",
          date: "2024-11-04",
          total: "12.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.ธัญยธรญ์",
          date: "2024-11-04",
          total: "19.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวโปร่งฟ้า แก้วตาทิพย์",
          date: "2024-11-04",
          total: "65.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวนภัสสร หทัยเดชะดุษฎี",
          date: "2024-11-04",
          total: "214.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวกนกนาถ ฉัตรทิวากุล",
          date: "2024-11-04",
          total: "72.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวดนยา โป๊ะขันที",
          date: "2024-11-04",
          total: "21.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวนภัสสร ยาศรี",
          date: "2024-11-04",
          total: "138.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว อติญา",
          date: "2024-11-04",
          total: "105.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายวรพล สมุทธารักษ์",
          date: "2024-11-04",
          total: "12.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวปุณญพัฒน์ ดำรงวิริยะขจร",
          date: "2024-11-04",
          total: "171.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวฟาติน หะยีวานิ",
          date: "2024-11-04",
          total: "300.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวหัษยา รุ่งวิตรี",
          date: "2024-11-04",
          total: "100.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.ภัทราพร ศิลาเปรื่อง",
          date: "2024-11-04",
          total: "180.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวนาถนภา กลัดกลีบ",
          date: "2024-11-04",
          total: "9.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวณิชา พฤกษอาภรณ์",
          date: "2024-11-04",
          total: "103.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวณัฐยา นวมกระจ่าง",
          date: "2024-11-04",
          total: "303.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายณัฐพล รื่นพรต",
          date: "2024-11-04",
          total: "350.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นส สปัญญา เชิดชัย",
          date: "2024-11-04",
          total: "12.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวภัทรินทร์",
          date: "2024-11-04",
          total: "12.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ชนากานต์",
          date: "2024-11-04",
          total: "78.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวชัญญา กรรณจนะศิลป์",
          date: "2024-11-04",
          total: "144.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายนนทกร ชัยยารังกิจรัตน์",
          date: "2024-11-04",
          total: "93.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป ณัฏฐณิชา แซ่ลี้",
          date: "2024-11-04",
          total: "96.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.ฐณิตา มงคลรังสฤษฎ์",
          date: "2024-11-04",
          total: "503.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวอนัญญา ชื่นอารมย์",
          date: "2024-11-04",
          total: "42.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวชลลดา สุขสม",
          date: "2024-11-04",
          total: "100.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวชัญญานุช",
          date: "2024-11-04",
          total: "60.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพัชราภรณ์",
          date: "2024-11-04",
          total: "960.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.กุลกันยา ภูมีศรี",
          date: "2024-11-04",
          total: "273.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว กฤษทร ประทับสิงห์",
          date: "2024-11-04",
          total: "245.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพรรษชล",
          date: "2024-11-04",
          total: "191.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวกัลยกร ธำรงอุดมมงคล",
          date: "2024-11-04",
          total: "27.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวธัญรดา จันทร์เศรษฐี",
          date: "2024-11-04",
          total: "106.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวกรวรรณญา ถาวรวิริยะนันท์",
          date: "2024-11-04",
          total: "504.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.นันทพร จารุจันทร์",
          date: "2024-11-04",
          total: "120.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวปสุตา แก้วบางกะพ้อม",
          date: "2024-11-04",
          total: "78.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว นภัสวรรณ ทัศจันทร์",
          date: "2024-11-04",
          total: "27.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพิชชากานต์ ลีพุด",
          date: "2024-11-04",
          total: "9.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายศรนรินทร์",
          date: "2024-11-04",
          total: "48.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวรวิสรา จงสมจิตต์",
          date: "2024-11-04",
          total: "60.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวศรสวรรค์ สาระคุณ",
          date: "2024-11-04",
          total: "194.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวณัฐญาดา ชูประเสริฐ",
          date: "2024-11-04",
          total: "36.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว กมลกร",
          date: "2024-11-04",
          total: "93.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวจิดาภา",
          date: "2024-11-04",
          total: "34.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวกสิณา หังสาจล",
          date: "2024-11-04",
          total: "27.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาววรรณพร ชาญสูงเนิน",
          date: "2024-11-04",
          total: "37.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวมนัสขนก ไกรสูรย์",
          date: "2024-11-04",
          total: "61.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวฐิติมา ธุระกิจ",
          date: "2024-11-04",
          total: "27.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นาย อันเดรอีโน โรต้า",
          date: "2024-11-04",
          total: "23.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นาย ดุลยรัตน์ โปร่งสันเทียะ",
          date: "2024-11-04",
          total: "96.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.รัตนาภรณ์ คุณโฑทอง",
          date: "2024-11-04",
          total: "135.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.พรนภัส ปานมมีสิทธิ์",
          date: "2024-11-04",
          total: "30.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นาย ดามาพันธุ์ วีรวัฒนานนท์",
          date: "2024-11-04",
          total: "78.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ภัททิยา บ่อทรัพย์",
          date: "2024-11-04",
          total: "72.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวภูษณิศา",
          date: "2024-11-04",
          total: "6.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวศุภาวีร์ จุลบุตร",
          date: "2024-11-04",
          total: "33.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาววนิดา วรรณพงษ์",
          date: "2024-11-04",
          total: "12.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวสุพิชญา ศิลปาจารย์",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวกรณ์ศศิร์",
          date: "2024-11-04",
          total: "34.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวสุพิชญา หน่อแก้ว",
          date: "2024-11-04",
          total: "12.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายวชิรวิทย์ นาคชุมนุม",
          date: "2024-11-04",
          total: "42.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ศลิษา วีระสินธุ์",
          date: "2024-11-04",
          total: "240.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายพีรพัฒน์ ดอนเหนือ",
          date: "2024-11-04",
          total: "278.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป Miss Yolwadee S.",
          date: "2024-11-04",
          total: "99.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวอภัสรา รอดคำ",
          date: "2024-11-04",
          total: "51.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวกันตา พวงมณี",
          date: "2024-11-04",
          total: "330.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวดมิสา กฤตภาสพงศ์",
          date: "2024-11-04",
          total: "159.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวอภิชญา เพียสม",
          date: "2024-11-04",
          total: "96.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวณัชชา ตุพิมาย",
          date: "2024-11-04",
          total: "27.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาววริศรา ศราวุธานุกูล",
          date: "2024-11-04",
          total: "138.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวฐิติมาภรณ์ คชพันธ์",
          date: "2024-11-04",
          total: "94.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพิยดา พนิตวงศ์ตระกูล",
          date: "2024-11-04",
          total: "29.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวนุศรา นภาวัฒนากูล",
          date: "2024-11-04",
          total: "123.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวสิริยาภา จะจง",
          date: "2024-11-04",
          total: "27.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวชุติกาญจน์ มกรนันทน์",
          date: "2024-11-04",
          total: "12.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวธิดาพร ทาดาวุธ",
          date: "2024-11-04",
          total: "397.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวนภัสสร โพธิ์วัน",
          date: "2024-11-04",
          total: "216.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวนพณัฐ โอนอ่อน",
          date: "2024-11-04",
          total: "48.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.เมริสา สิงหเดโช",
          date: "2024-11-04",
          total: "70.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพรปวีณ์",
          date: "2024-11-04",
          total: "1424.74",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาววริศรา ธาราชีพ",
          date: "2024-11-04",
          total: "21.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวนภัสวรรณ เลิศประเสริฐภากร",
          date: "2024-11-04",
          total: "36.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวสิริรัตน์ ผายพิมาย",
          date: "2024-11-04",
          total: "261.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวสิขนภา",
          date: "2024-11-04",
          total: "11.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.ฐิตภา ปัทมวิทูร",
          date: "2024-11-04",
          total: "45.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวศิริรัตน์",
          date: "2024-11-04",
          total: "30.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวไอรินทร์ นิติวัฒน์วรภัทร",
          date: "2024-11-04",
          total: "75.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.อรปรียา วิชิตวาสุเทพ",
          date: "2024-11-04",
          total: "29.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นาย ศรีภูมิ",
          date: "2024-11-04",
          total: "317.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพัชรพร",
          date: "2024-11-04",
          total: "81.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.ธัญยธรญ์",
          date: "2024-11-04",
          total: "115.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ณัฐกมล หาญกิจจะกุล",
          date: "2024-11-04",
          total: "57.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ศิศิรา",
          date: "2024-11-04",
          total: "1.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.นภัสสร คงคา",
          date: "2024-11-04",
          total: "175.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวศิรประภา เพ็งพุ่ม",
          date: "2024-11-04",
          total: "24.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ศศิธร อยู่รุ่ง",
          date: "2024-11-04",
          total: "30.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายต้องครรลอง เต็มนา",
          date: "2024-11-04",
          total: "387.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายวีรภัทร",
          date: "2024-11-04",
          total: "16.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป napatsawan kanda",
          date: "2024-11-04",
          total: "36.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ภวรัญชนา",
          date: "2024-11-04",
          total: "32.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาววิชชุริณี ชุมพล",
          date: "2024-11-04",
          total: "72.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.ณฤดี",
          date: "2024-11-04",
          total: "306.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวอรณิชา ปะลิเตสังข์",
          date: "2024-11-04",
          total: "63.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวปวิชญา  รางน้อย",
          date: "2024-11-04",
          total: "24.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.ณัฐริกานต์ กลิ่นขจร",
          date: "2024-11-04",
          total: "9.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวณิชา ปาละมุล",
          date: "2024-11-04",
          total: "46.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว กิตติยา ไมล์หรือ",
          date: "2024-11-04",
          total: "96.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวณัฐปภัสร ผ่องหทัยกุล",
          date: "2024-11-04",
          total: "21.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว อริสา",
          date: "2024-11-04",
          total: "114.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพรพิมล ปาป้อง",
          date: "2024-11-04",
          total: "120.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว อาฏีณา โคกเขา",
          date: "2024-11-04",
          total: "168.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวศิริลักษณ์",
          date: "2024-11-04",
          total: "19.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวอิศรากรณ์ พิเชฐจำรัสชีพ",
          date: "2024-11-04",
          total: "45.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพนิดา อุณหทวีทรัพย์",
          date: "2024-11-04",
          total: "27.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป ศุภิสรา ภิญโญธัญธัต",
          date: "2024-11-04",
          total: "52.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นส.กรภัทร์  ศิริโภคานนท์",
          date: "2024-11-04",
          total: "8.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป Miss Cher",
          date: "2024-11-04",
          total: "45.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวอภิรตรา ทันอ่อน",
          date: "2024-11-04",
          total: "4.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว อรธีรา สุภาพักตร์",
          date: "2024-11-04",
          total: "195.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.ภีมภา แพทยานนท์",
          date: "2024-11-04",
          total: "33.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ภูพิณ สุขสายชล",
          date: "2024-11-04",
          total: "399.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวกัญญาวีร์ กลั่นนุรักษ์",
          date: "2024-11-04",
          total: "129.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.สุประวีณ์ สุวรรณจิตร",
          date: "2024-11-04",
          total: "75.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป มิลันตา",
          date: "2024-11-04",
          total: "17.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวศิรินาจ",
          date: "2024-11-04",
          total: "15.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว แพรทอง",
          date: "2024-11-04",
          total: "27.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวมุฑิตา ท้าวสัน",
          date: "2024-11-04",
          total: "31.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายเมธพนธ์",
          date: "2024-11-04",
          total: "27.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวชนิกานต์ ธชีพันธ์",
          date: "2024-11-04",
          total: "113.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวนงนภัส จิตรพินิจ",
          date: "2024-11-04",
          total: "286.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวมาดา",
          date: "2024-11-04",
          total: "88.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.ปาณิสรา ช้างพลาย",
          date: "2024-11-04",
          total: "60.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว พิราอร",
          date: "2024-11-04",
          total: "844.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวเกตุวดี ทรัพย์เล็ก",
          date: "2024-11-04",
          total: "45.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ปัญฌิการ์",
          date: "2024-11-04",
          total: "23.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวธนพร จิตต์จำนงค์",
          date: "2024-11-04",
          total: "30.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวชวัลนุช รัตนกระทุ่ม",
          date: "2024-11-04",
          total: "57.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพัณณิตา ทบชิน",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายจิรวัฒน์ พึ่งวงศ์ตระกูล",
          date: "2024-11-04",
          total: "7.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวบุณณดา บุญญานุพงศ์",
          date: "2024-11-04",
          total: "30.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นส. สุจิตรภัค ศรีสุพรรณราช",
          date: "2024-11-04",
          total: "189.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวอภิญญา กลับส่ง",
          date: "2024-11-04",
          total: "36.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวบุณย์ทิวา  มหาพัณณาภรณ์",
          date: "2024-11-04",
          total: "138.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.เวทิสา ชาตรีวุฒิชัย",
          date: "2024-11-04",
          total: "213.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นันท์นภัส ประสพสิลป์",
          date: "2024-11-04",
          total: "60.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายฐากร แสงไชย",
          date: "2024-11-04",
          total: "374.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวมีณฑิศิญากร ศุภประภากร",
          date: "2024-11-04",
          total: "54.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายฉัตรมงคล ขวดทอง",
          date: "2024-11-04",
          total: "54.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวณัชชาพร สำเภา",
          date: "2024-11-04",
          total: "96.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป Kanitchanok",
          date: "2024-11-04",
          total: "73.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวญาณิศา",
          date: "2024-11-04",
          total: "24.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวสลิลภรณ์ ผดุงพัฒน์",
          date: "2024-11-04",
          total: "68.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ปรัชญา อำมาตย์โยธิน",
          date: "2024-11-04",
          total: "25.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป ชญาดา แสงอินทร์",
          date: "2024-11-04",
          total: "122.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวธมนวรรณ",
          date: "2024-11-04",
          total: "22.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาววรรณภา แหละหล๊ะ",
          date: "2024-11-04",
          total: "195.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายอนาวิล คงนอง",
          date: "2024-11-04",
          total: "106.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวกวินทิพย์ โลหวิบูลย์กิจ",
          date: "2024-11-04",
          total: "36.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นาวสาวสุพิชฌาย์ แก้วปัตตา",
          date: "2024-11-04",
          total: "54.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวมาลินี เดชด่าน",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวกฤตกานต์ เกิดผล",
          date: "2024-11-04",
          total: "45.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพิมพ์มุก ฉวีภักดิ์",
          date: "2024-11-04",
          total: "44.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.จณิตา พหลยุทธ",
          date: "2024-11-04",
          total: "237.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวปริชญ์ลดา พาดำเนิน",
          date: "2024-11-04",
          total: "27.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวชลิตดา",
          date: "2024-11-04",
          total: "195.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวญาณิศา",
          date: "2024-11-04",
          total: "120.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวกัลยกร ชัชวาลาพันธุ์",
          date: "2024-11-04",
          total: "42.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.ปิยพร พลอยนิน",
          date: "2024-11-04",
          total: "7.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นาย ปิยวัช สิริรัตนตรัย",
          date: "2024-11-04",
          total: "174.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ณัฐมนต์ อังคเศกวินัย",
          date: "2024-11-04",
          total: "164.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายสิทธิเดช",
          date: "2024-11-04",
          total: "178.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวนัฐชนก อารัญภาณุ",
          date: "2024-11-04",
          total: "33.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวกัญญาณัฐ ทองทิพย์",
          date: "2024-11-04",
          total: "561.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาววริยา พัฒนศักดิ์ศิริ",
          date: "2024-11-04",
          total: "36.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวภัทรนิษฐ์ แย้มละมัย",
          date: "2024-11-04",
          total: "90.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ณัฐนันท์",
          date: "2024-11-04",
          total: "63.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวชัชฎากาญจน์",
          date: "2024-11-04",
          total: "84.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นส.พรชิตา ไวยกัญมา",
          date: "2024-11-04",
          total: "81.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวณัชชา",
          date: "2024-11-04",
          total: "3.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวณภัทร เลิศสัตตพรกุล",
          date: "2024-11-04",
          total: "72.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.ยศวิมล บุญครอง",
          date: "2024-11-04",
          total: "34.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว อานีซะ ปิยา",
          date: "2024-11-04",
          total: "12.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวกานต์ธีรา ตุมรสุนทร",
          date: "2024-11-04",
          total: "30.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวฐิติกาญจน์",
          date: "2024-11-04",
          total: "6.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวภัทรวดี อุ่นกิจการเจริญ",
          date: "2024-11-04",
          total: "22.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวกณิศา อินต๊ะเนตร",
          date: "2024-11-04",
          total: "180.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาววริษา วิโมกข์เจริญสุข",
          date: "2024-11-04",
          total: "6.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว สาริศา ปัญญารักษากุล",
          date: "2024-11-04",
          total: "324.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวรินรดา",
          date: "2024-11-04",
          total: "315.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวปวิชญา ติณกิตติ",
          date: "2024-11-04",
          total: "11.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายนินนาท ควรเสนา",
          date: "2024-11-04",
          total: "35.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวกุลรดา เทพมณี",
          date: "2024-11-04",
          total: "12.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวปรารถนา  แก้วศิลา",
          date: "2024-11-04",
          total: "68.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ธนพร ดวงสุภา",
          date: "2024-11-04",
          total: "84.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ปพิชญา ทิพย์แดง",
          date: "2024-11-04",
          total: "24.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายเฉลิมชัย ขุนอินทร์",
          date: "2024-11-04",
          total: "464.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว เปรมยุดา จันทร์ศิริ",
          date: "2024-11-04",
          total: "61.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพราวษา เพลินศิลป์",
          date: "2024-11-04",
          total: "105.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส. ศุภิสรา สุขศรี",
          date: "2024-11-04",
          total: "129.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว สิริรักษ์",
          date: "2024-11-04",
          total: "60.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวธนัชชา แดงอรุณ",
          date: "2024-11-04",
          total: "108.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ปิยพัชร นาคเพชร",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.ชนันธร เอี่ยมกาย",
          date: "2024-11-04",
          total: "72.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวปารณัท สัมฤทธิ์เดชขจร",
          date: "2024-11-04",
          total: "24.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวอลิศรา ประชามาตย์",
          date: "2024-11-04",
          total: "33.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายยุทธภูมิ",
          date: "2024-11-04",
          total: "70.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว พิมพ์ชนก ชาติมนตรี",
          date: "2024-11-04",
          total: "81.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวรักษ์สุมน สุกสิ",
          date: "2024-11-04",
          total: "54.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวจิณัฐตามาศ",
          date: "2024-11-04",
          total: "15.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายธนภัทร",
          date: "2024-11-04",
          total: "125.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายปิยวัฒน์",
          date: "2024-11-04",
          total: "41.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวกมลชนก วิทยา",
          date: "2024-11-04",
          total: "42.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวญาณิศา สุวรรณรักษา",
          date: "2024-11-04",
          total: "12.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวณัชชา ทวีศรีตระกูล",
          date: "2024-11-04",
          total: "48.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.ธิรณี",
          date: "2024-11-04",
          total: "27.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นาย ชนินทร์ ผลพึ่งคิด",
          date: "2024-11-04",
          total: "15.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว กันติชา พิมพ์ใจ",
          date: "2024-11-04",
          total: "27.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป ผู้เขียน",
          date: "2024-11-04",
          total: "16.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว สุธิดา เณรมณี",
          date: "2024-11-04",
          total: "30.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายธีรศักดิ์ วงษ์ตุ่น",
          date: "2024-11-04",
          total: "21.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ธนิดา เหล่าสิริพิพัฒน์",
          date: "2024-11-04",
          total: "9.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวผุสชา นพศิริวงศ์",
          date: "2024-11-04",
          total: "42.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายเกษมสันต์ ศรีสุวรรณ์",
          date: "2024-11-04",
          total: "30.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.กรชวัล เพชรเลิศอนันต์",
          date: "2024-11-04",
          total: "21.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวสาวิตรี แก้วขันตี",
          date: "2024-11-04",
          total: "9.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป ผู้เขียน",
          date: "2024-11-04",
          total: "19.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายธนากร เกิดโภคา",
          date: "2024-11-04",
          total: "35.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวจิรภา ดีมั่น",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ลลิตา",
          date: "2024-11-04",
          total: "24.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป Miss Natnaran Muenphet",
          date: "2024-11-04",
          total: "81.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายภัทรพล ผลพิมาย",
          date: "2024-11-04",
          total: "60.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ณิชาพร",
          date: "2024-11-04",
          total: "85.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายสัณฑพงศ์",
          date: "2024-11-04",
          total: "85.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.ฐิตาภา ชัยรุ่งเรืองถาวร",
          date: "2024-11-04",
          total: "9.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวกมลรัตน์ วัฒนาภรณ์วิทย์",
          date: "2024-11-04",
          total: "61.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวณัฐณิชา จูฑะสุวรรณ์",
          date: "2024-11-04",
          total: "12.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.พิมพ์ชนก เสียงก้อง",
          date: "2024-11-04",
          total: "100.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวอภิชญา สุนทรศารทูล",
          date: "2024-11-04",
          total: "12.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายจักรกฤษณ์ มุสิกสาร",
          date: "2024-11-04",
          total: "15.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว วรนารี เกตุจันทร์",
          date: "2024-11-04",
          total: "90.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป ลิลลี่",
          date: "2024-11-04",
          total: "17.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายธันยธรณ์ ชำนาญวงค์",
          date: "2024-11-04",
          total: "15.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ศรุตา ยิ่งเจริญอนันต์",
          date: "2024-11-04",
          total: "16.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวปารียา กิตติธนาวิโรจน์",
          date: "2024-11-04",
          total: "249.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวบัวบุศยา ศุภศรี",
          date: "2024-11-04",
          total: "45.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว รัชเกล้า แซ่จง",
          date: "2024-11-04",
          total: "24.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวอักษรา ชมเมือง",
          date: "2024-11-04",
          total: "108.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวศุภิสรา อุ่นบริบูรณ์",
          date: "2024-11-04",
          total: "36.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวจิตรวดี คำพันธ์",
          date: "2024-11-04",
          total: "468.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นาย สิทธิพร เตี้ยมานะ",
          date: "2024-11-04",
          total: "70.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาววรรณฐิตา ตาลประเดิม",
          date: "2024-11-04",
          total: "48.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป พนัชกร มงคลฟัก",
          date: "2024-11-04",
          total: "113.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายณรงค์ฤทธิ์ กรุงแก้ว",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวธนกร ศรีนอก",
          date: "2024-11-04",
          total: "223.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพรปวีณ์ ตั้งเส้ง",
          date: "2024-11-04",
          total: "21.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวนิศารัตน์ สิงห์สุข",
          date: "2024-11-04",
          total: "24.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวฐิตาภรณ์ บุญอุ้มชู",
          date: "2024-11-04",
          total: "20.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นาย กฤต",
          date: "2024-11-04",
          total: "36.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายณรงค์ฤทธิ์ กรุงแก้ว",
          date: "2024-11-04",
          total: "30.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายวรินทร",
          date: "2024-11-04",
          total: "24.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ปลิดา ธรรมวิเศษ",
          date: "2024-11-04",
          total: "15.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว เบญญาภา",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวศุภิสรา อุ่นบริบูรณ์",
          date: "2024-11-04",
          total: "54.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวจิรัชญา แพงพงษ์มา",
          date: "2024-11-04",
          total: "36.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายเดชาวัต",
          date: "2024-11-04",
          total: "60.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวชญนนท ชินธรรมมิตร",
          date: "2024-11-04",
          total: "30.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.อริสรา จันทร์โชติญาณ",
          date: "2024-11-04",
          total: "12.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวธัญภา เจียมแพ",
          date: "2024-11-04",
          total: "14.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป phattasit",
          date: "2024-11-04",
          total: "3.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป ผู้เขียน",
          date: "2024-11-04",
          total: "12.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป รักษ์กิจ โภชนะกิจ",
          date: "2024-11-04",
          total: "15.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพิมพ์ปวีณ์ ภูดิทเดชวัฒน์",
          date: "2024-11-04",
          total: "35.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวจิตตมาส อติวรมันต์",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวภัททิยา ประกอบชาติ",
          date: "2024-11-04",
          total: "24.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายศุภากร เจียรพงศ์ไพศาล",
          date: "2024-11-04",
          total: "23.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป Munchukarn Sujiranon",
          date: "2024-11-04",
          total: "69.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นส.พชรภรณ์ งดงาม",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.รัตน์ศิรินทร์ โลหะลักษณาเดช",
          date: "2024-11-04",
          total: "87.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวบุญญิสา ธรรมวรัญญา",
          date: "2024-11-04",
          total: "30.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวณัฐนิช เลาหสุขไพศาล",
          date: "2024-11-04",
          total: "27.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายณัฐพล พิทยะวงค์",
          date: "2024-11-04",
          total: "36.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวภัคจิรา",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายภัทรพงษ์ วัฒนกูล",
          date: "2024-11-04",
          total: "9.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป ผู้เขียน",
          date: "2024-11-04",
          total: "14.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวกีรติ",
          date: "2024-11-04",
          total: "10.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.พิชญาภา ผานดอยแดน",
          date: "2024-11-04",
          total: "54.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวลลิตา",
          date: "2024-11-04",
          total: "154.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.สิริขวัญ หนูนคง",
          date: "2024-11-04",
          total: "10.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นส.ชนิตา ธนูศิลป์",
          date: "2024-11-04",
          total: "12.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวมันตา นิลโอภา",
          date: "2024-11-04",
          total: "42.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวณปภัช วรรณภิรมย์",
          date: "2024-11-04",
          total: "106.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว กชกร จำปีเรือง",
          date: "2024-11-04",
          total: "205.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวณัฐนิชา สมสุข",
          date: "2024-11-04",
          total: "45.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ธนาภา มาศธนพันธ์",
          date: "2024-11-04",
          total: "60.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ยลรดี",
          date: "2024-11-04",
          total: "30.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป อภิษฎา",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว กมลชนก",
          date: "2024-11-04",
          total: "12.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวธรรมรัต วงศ์สุวรรณ์",
          date: "2024-11-04",
          total: "3.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายปรกชน บุญฤทธิ์",
          date: "2024-11-04",
          total: "30.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ถัญญา",
          date: "2024-11-04",
          total: "3.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวชัญญา ใจแก้ว",
          date: "2024-11-04",
          total: "9.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวบุรัสกร จันทศิริ",
          date: "2024-11-04",
          total: "35.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวณัชชา",
          date: "2024-11-04",
          total: "52.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวสายฝัน สวาดดี",
          date: "2024-11-04",
          total: "30.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวอัญทิชา นครังสุ",
          date: "2024-11-04",
          total: "17.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวปณาลี",
          date: "2024-11-04",
          total: "33.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวณัฐกฤตา",
          date: "2024-11-04",
          total: "42.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.พรทิพย์ สมสมัย",
          date: "2024-11-04",
          total: "8.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ณัฐชยา",
          date: "2024-11-04",
          total: "17.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายปฏิภาณ รัตนาวิน",
          date: "2024-11-04",
          total: "7.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวหทัยชนก ชามาตย์",
          date: "2024-11-04",
          total: "21.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวนนทกร",
          date: "2024-11-04",
          total: "4.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส. ธัญวรัตน์ พงษ์ประเสริฐ",
          date: "2024-11-04",
          total: "11.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวบุณยาพร ไชยออย",
          date: "2024-11-04",
          total: "36.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.ธีรนาถ ต่ออุดม",
          date: "2024-11-04",
          total: "27.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวณัฎฐณิชา ปรีชาอดุลหิรัญ",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นส.ปัณณภัสร์ ศรุติปรีชาโรจน์",
          date: "2024-11-04",
          total: "28.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.พิมพ์ชนก พรมมาตย์",
          date: "2024-11-04",
          total: "33.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว นันท์นภัส คะอูป",
          date: "2024-11-04",
          total: "21.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวมณีทิพย์ เหลืองสิทธิกุล",
          date: "2024-11-04",
          total: "39.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นส.นันท์นภัส",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายสฐิรวิช หีบทอง",
          date: "2024-11-04",
          total: "17.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวกชกรณ์ สมบูรณ์",
          date: "2024-11-04",
          total: "34.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายทศพร สถิตย์เดช",
          date: "2024-11-04",
          total: "10.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวนภัสนันท์ พรโกมลรัตน์",
          date: "2024-11-04",
          total: "21.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ปัณชญา ศรีพงษ์",
          date: "2024-11-04",
          total: "48.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป ณัฐณิชา จันทร์พุทธิ",
          date: "2024-11-04",
          total: "17.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายธรณินทร์ แก้วฟู",
          date: "2024-11-04",
          total: "12.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวคณัสนันท์",
          date: "2024-11-04",
          total: "10.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวนลินี ประสิทธิเขตรกิจ",
          date: "2024-11-04",
          total: "17.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป พชรบงกช",
          date: "2024-11-04",
          total: "23.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว พรพัฒน์ระพี จิระภาค",
          date: "2024-11-04",
          total: "50.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายรัฐธนินท์",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายจิรายุ กาพล",
          date: "2024-11-04",
          total: "29.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวเวศิญา ถิ่นมะลวน",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ศตพร เกิดกรรณ์",
          date: "2024-11-04",
          total: "27.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป สิริอาภา ตากลม",
          date: "2024-11-04",
          total: "60.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.ปุณยนุช ปัญญาธนคุณ",
          date: "2024-11-04",
          total: "12.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว",
          date: "2024-11-04",
          total: "81.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป พัชรมน คีตะตระกูล",
          date: "2024-11-04",
          total: "30.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว กรชนก",
          date: "2024-11-04",
          total: "60.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว จินดาภา สังขปานันท์",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวปุณยภา มาศธนพันธ์",
          date: "2024-11-04",
          total: "36.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายนิฌามิล หะยีซะ",
          date: "2024-11-04",
          total: "51.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวชาลิสา",
          date: "2024-11-04",
          total: "24.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นาย เตธัท จามรลักษณ์",
          date: "2024-11-04",
          total: "3.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป Ms. Kalayaporn",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวญาณภา วรบวร",
          date: "2024-11-04",
          total: "133.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวจิราพัชร์ พงษ์เกษมวิวัฒน์",
          date: "2024-11-04",
          total: "24.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาววสุนันท์",
          date: "2024-11-04",
          total: "24.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพัชรลักษณ์",
          date: "2024-11-04",
          total: "22.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวเพชรลดา ศรีวิเศษ",
          date: "2024-11-04",
          total: "59.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวณัฏฐณิชา อำนวยโชคอนันต์",
          date: "2024-11-04",
          total: "46.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวนวพร",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพิณนรี เจิมสุรวงศ์",
          date: "2024-11-04",
          total: "30.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.วรรณา",
          date: "2024-11-04",
          total: "5849.10",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวปณิดา ทาโทน",
          date: "2024-11-04",
          total: "1309.50",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว อริสา ปานแขวง",
          date: "2024-11-04",
          total: "3203.91",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายวีรชัย พันธ์สืบ",
          date: "2024-11-04",
          total: "1222.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.ญาณิศา ปานพรหม",
          date: "2024-11-04",
          total: "81.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว พัฑรา จ๋าจุ๋มป๋า",
          date: "2024-11-04",
          total: "3993.10",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาววรันธร ม่วงทิพย์",
          date: "2024-11-04",
          total: "1874.04",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว กนกฉัตร",
          date: "2024-11-04",
          total: "300.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นส. จิดาภา ศรีเทศ",
          date: "2024-11-04",
          total: "1236.75",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวกมลพร ปิจดี",
          date: "2024-11-04",
          total: "169.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว วีณา กว้านสกุล",
          date: "2024-11-04",
          total: "783.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวเทียนทอง อินตารักษา",
          date: "2024-11-04",
          total: "70.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวชนัญชิดา จิรดาโภคินกุล",
          date: "2024-11-04",
          total: "4885.89",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายเทวฤทธิ์",
          date: "2024-11-04",
          total: "45.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส. กรรณิกา มีทุน",
          date: "2024-11-04",
          total: "111.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.นพเก้า จุไรรัตน์พร",
          date: "2024-11-04",
          total: "313.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวชนัญชิดา วงษ์แย้มพันธุ์",
          date: "2024-11-04",
          total: "45.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวปัญภัสภ์ หาญอุไรพงษ์",
          date: "2024-11-04",
          total: "1012.68",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวณัฎฐณิชา มาสุวรรณ",
          date: "2024-11-04",
          total: "228.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวภัทราพร วงค์ปินจันทร์",
          date: "2024-11-04",
          total: "1373.52",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว คุณิตา",
          date: "2024-11-04",
          total: "210.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาววนารี ใจอูบ",
          date: "2024-11-04",
          total: "192.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวณัฐกฤตา หล้าอินถา",
          date: "2024-11-04",
          total: "324.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ชญานิศ อิสสระ",
          date: "2024-11-04",
          total: "624.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวนิสากร ทำนุ",
          date: "2024-11-04",
          total: "489.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว สุพิชญา สายกลางดี",
          date: "2024-11-04",
          total: "372.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายเจษฎากร",
          date: "2024-11-04",
          total: "94.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวกันยารัตน์ พรตริไตร",
          date: "2024-11-04",
          total: "1222.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นาย ธนากร เสืออินทร์",
          date: "2024-11-04",
          total: "7674.25",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพิมพ์ประภา บุญช้างเผือก",
          date: "2024-11-04",
          total: "1001.04",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายชายชาญ",
          date: "2024-11-04",
          total: "198.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวสปัญญา รูจีจินดา",
          date: "2024-11-04",
          total: "78.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส. สุจิรา ชมภูงาม",
          date: "2024-11-04",
          total: "157.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ภัทรวดี ดีก้อน",
          date: "2024-11-04",
          total: "945.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายพัทธดนย์ เมืองมูล",
          date: "2024-11-04",
          total: "789.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวกุลรัศมิ์ ใจหาญ",
          date: "2024-11-04",
          total: "466.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายธนดล จิตไพบูลย์",
          date: "2024-11-04",
          total: "117.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวอภิชญา ธรรมวงษา",
          date: "2024-11-04",
          total: "277.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายศุภกร แก่นชา",
          date: "2024-11-04",
          total: "285.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป อุบลรัตน์",
          date: "2024-11-04",
          total: "36.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวอาทิตยา กันธิรา",
          date: "2024-11-04",
          total: "85.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวสลิลพร ภัทรวัฒนาภรณ์",
          date: "2024-11-04",
          total: "72.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวสุพัตรา คนแรง",
          date: "2024-11-04",
          total: "456.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวศศลักษณ์ อิศรางกูร ณ อยุธยา",
          date: "2024-11-04",
          total: "42.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป อิมอิม",
          date: "2024-11-04",
          total: "1524.84",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวนิตยา ตาปิน",
          date: "2024-11-04",
          total: "1894.41",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายอนุชา ชัยสุนทรโยธิน",
          date: "2024-11-04",
          total: "176.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวนาฏนฤตย์ สุทธิสวรรค์",
          date: "2024-11-04",
          total: "312.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.พรทิพย์ ไชยประพันธ์",
          date: "2024-11-04",
          total: "417.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพิมลวรรณ ปานทุ่ง",
          date: "2024-11-04",
          total: "849.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายจีรายุทธ สมบูรณ์",
          date: "2024-11-04",
          total: "270.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพิมจันทร์ นรินทรางกูล ณ อยุธยา",
          date: "2024-11-04",
          total: "120.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาววธูสิริ ลิ้มวัฒนา",
          date: "2024-11-04",
          total: "546.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวธาดาทิพย์  วงศ์ใหญ่",
          date: "2024-11-04",
          total: "375.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวธรรมรัต วงศ์สุวรรณ์",
          date: "2024-11-04",
          total: "168.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว กัลยากร วงศ์บุญ",
          date: "2024-11-04",
          total: "84.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวปิ่นมนัส เกตุปั้น",
          date: "2024-11-04",
          total: "262.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายกนกพล ขำสุวรรณ",
          date: "2024-11-04",
          total: "15.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายณรงค์ฤทธิ์ สุขผล",
          date: "2024-11-04",
          total: "75.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวนันทวรรณ เกื้อสม",
          date: "2024-11-04",
          total: "59.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวนริศรา สบบง",
          date: "2024-11-04",
          total: "836.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวกัญรัตน์ อุทัยไกรรักษ์",
          date: "2024-11-04",
          total: "3.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ศุลีภรณ์ จันทร์แก้ว",
          date: "2024-11-04",
          total: "156.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว พลอยไพรินทร์",
          date: "2024-11-04",
          total: "210.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวศุภมาส เจริญประพัฒน์",
          date: "2024-11-04",
          total: "1338.02",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว กฤษณวรรณ ศิริ",
          date: "2024-11-04",
          total: "945.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว สุวิมล ครองมี",
          date: "2024-11-04",
          total: "466.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป ms. Pitraporn seela",
          date: "2024-11-04",
          total: "429.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว พิชญา",
          date: "2024-11-04",
          total: "510.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวมัญชุภา ทิมศรี",
          date: "2024-11-04",
          total: "510.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวกนกกร พัฒนศิลป์",
          date: "2024-11-04",
          total: "372.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.ธนัชพร",
          date: "2024-11-04",
          total: "45.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป อภิสิทธิ์",
          date: "2024-11-04",
          total: "546.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว อัจจิมา งูเขียว",
          date: "2024-11-04",
          total: "60.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.กุลนันท์ กูลนะ",
          date: "2024-11-04",
          total: "1390.98",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวบุศรินทร์ เอื้องเงิน",
          date: "2024-11-04",
          total: "495.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นส พรพรหม มีครุฑ",
          date: "2024-11-04",
          total: "1868.22",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป กัญญาวีร์ สมดา",
          date: "2024-11-04",
          total: "363.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวศิลป์ศุภา เจิมขวัญ",
          date: "2024-11-04",
          total: "528.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป ณภัสนันท์ ศรีบุญรุ่งโรจน์",
          date: "2024-11-04",
          total: "48.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวชนารดี",
          date: "2024-11-04",
          total: "81.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ลักษิกา",
          date: "2024-11-04",
          total: "732.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวภัคจิราภรณ์ ตั้งประเสริฐ",
          date: "2024-11-04",
          total: "175.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นาย พิเชษฐ์พงศ์",
          date: "2024-11-04",
          total: "207.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวเกษรินทร์ หินเงิน",
          date: "2024-11-04",
          total: "84.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ภาวิณี คุรุภากรณ์",
          date: "2024-11-04",
          total: "183.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวณัฐธัญญา จตุรวัชรนาถ",
          date: "2024-11-04",
          total: "21.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป Fs",
          date: "2024-11-04",
          total: "390.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวสุธาทิพย์ โกมลพิภัทร",
          date: "2024-11-04",
          total: "345.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ปารียาวรรณ ป้อมจักรศิลป์",
          date: "2024-11-04",
          total: "21.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป เกศแก้ว เอี่ยมประชา",
          date: "2024-11-04",
          total: "1769.28",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวปริณดา ซ้อนงาม",
          date: "2024-11-04",
          total: "273.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวศศินภา ขัดสงคราม",
          date: "2024-11-04",
          total: "695.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.ศุภิสรา หล้าหนัก",
          date: "2024-11-04",
          total: "414.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.กมลชน พุทธเกิด",
          date: "2024-11-04",
          total: "135.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวกัญนภัสสรณ์ ทนดี",
          date: "2024-11-04",
          total: "69.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพัดชา ยุทธวา",
          date: "2024-11-04",
          total: "840.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นส.จันทัปปภา ฉ่ำนารายณ์",
          date: "2024-11-04",
          total: "96.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว รดา มะโนสุวรรณ",
          date: "2024-11-04",
          total: "667.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพิมพ์ลภัส",
          date: "2024-11-04",
          total: "546.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ฐิตาภรณ์ ชัยชมภู",
          date: "2024-11-04",
          total: "353.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวอาทิติญา เสมพูล",
          date: "2024-11-04",
          total: "116.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นาย ปวเรศ ดิลกวุฒิสิทธิ์",
          date: "2024-11-04",
          total: "6.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.กชกร  อินทรศร",
          date: "2024-11-04",
          total: "126.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว สิชาพัชร์ เจริญธนสินสกล",
          date: "2024-11-04",
          total: "88.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวกุลสตรี ธีรพงศ์",
          date: "2024-11-04",
          total: "562.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวสุพิชญา ฐิตินันทนนท์",
          date: "2024-11-04",
          total: "33.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว อภิชญา",
          date: "2024-11-04",
          total: "180.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวปิยพัชร์ ศรีวงค์ธนาชัย",
          date: "2024-11-04",
          total: "54.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวกัญจนพร",
          date: "2024-11-04",
          total: "23.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวธนัยนาถ ศรีบุญเรือง",
          date: "2024-11-04",
          total: "34.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.กนกวรรณ ภาระการ",
          date: "2024-11-04",
          total: "546.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวกุลิสรา เลอวงศ์รัตน์",
          date: "2024-11-04",
          total: "453.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ณัฐชยา คูโบตะ",
          date: "2024-11-04",
          total: "966.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.ภัทรนันท์ ดวงอินทร์",
          date: "2024-11-04",
          total: "210.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวสุพิชฌา  เสมอใจ",
          date: "2024-11-04",
          total: "143.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายวรชาติ ตั้งใจ",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวนุจรินทร์ จันทร์สอน",
          date: "2024-11-04",
          total: "240.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว อธิชา",
          date: "2024-11-04",
          total: "60.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวเยาวลักษณ์ บุญนำ",
          date: "2024-11-04",
          total: "72.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวแพรวา",
          date: "2024-11-04",
          total: "297.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว สมิตา คุณานนท์",
          date: "2024-11-04",
          total: "168.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป Miss Amornrat Wongsue",
          date: "2024-11-04",
          total: "99.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวกชกร บัวลอย",
          date: "2024-11-04",
          total: "387.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวศรุตยา สุวรรณสิงหราช",
          date: "2024-11-04",
          total: "288.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.ธันย์ชนก กุลชิงชัย",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป สรัลพร เจริญผล",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวสิริรัตติกาล สถิตเมธากุล",
          date: "2024-11-04",
          total: "147.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาววิรดา พึ่งตน",
          date: "2024-11-04",
          total: "312.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.พัทธ์ธีรา ธรรมชอบ",
          date: "2024-11-04",
          total: "45.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว อวิสรา มหามัติ",
          date: "2024-11-04",
          total: "114.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายบัลลังก์ หล้าเมืองฤทธิ์",
          date: "2024-11-04",
          total: "405.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส. ณัฐณิชา บัญชีเจริญ",
          date: "2024-11-04",
          total: "120.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส. ศุภานัน",
          date: "2024-11-04",
          total: "117.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ชัญญา จุ้ยพิทักษ์",
          date: "2024-11-04",
          total: "33.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพิมพ์ชนก ขาวะนา",
          date: "2024-11-04",
          total: "384.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวกมลวรรณ์ ปวนไฝ",
          date: "2024-11-04",
          total: "60.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายภูมิรพี เกียรติประภาพร",
          date: "2024-11-04",
          total: "22.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวชไมพร ภูสะเทียน",
          date: "2024-11-04",
          total: "72.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.เสาวลักษณ์   โดยหนอม",
          date: "2024-11-04",
          total: "324.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวณัฏฐา จันพิชัยโกศล",
          date: "2024-11-04",
          total: "84.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวศิรดา กีรติวศิน",
          date: "2024-11-04",
          total: "48.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวสุธาทิพย์ แสงภูติ",
          date: "2024-11-04",
          total: "423.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวสุภนิดา ชาติวรรณ",
          date: "2024-11-04",
          total: "69.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.ธัญลักษณ์ เนตยากร",
          date: "2024-11-04",
          total: "273.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวศุภาพิชญ์ สุขเจริญ",
          date: "2024-11-04",
          total: "540.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวณัฐธิดา จอมแก้ว",
          date: "2024-11-04",
          total: "36.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.ชโนทัย ทรงธรรม",
          date: "2024-11-04",
          total: "45.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวเบญจมาศ กิจการ",
          date: "2024-11-04",
          total: "10.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.กมลรัตน์ ทองดีเลิศ",
          date: "2024-11-04",
          total: "24.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวกัลยรัตน์ นันทจิตตานนท์",
          date: "2024-11-04",
          total: "24.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นาย นวมินทร์ ฟองคำ",
          date: "2024-11-04",
          total: "156.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว จุฬาลักษณ์ เยตา",
          date: "2024-11-04",
          total: "33.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว จันทร์ศรี ภักดีโต",
          date: "2024-11-04",
          total: "44.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวกาญจณวรรณ กล้ามาก",
          date: "2024-11-04",
          total: "409.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวยวิษฐา ต.เจริญ",
          date: "2024-11-04",
          total: "148.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายสถิตย์",
          date: "2024-11-04",
          total: "3.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวปราณิสา ศรีธีรกุล",
          date: "2024-11-04",
          total: "45.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวเปรมกมล เป็งบังวัน",
          date: "2024-11-04",
          total: "82.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวขวัญสุกานต์ บ่อทรัพย์",
          date: "2024-11-04",
          total: "240.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.จุฬาลักษณ์ ดวงติ๊บ",
          date: "2024-11-04",
          total: "81.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวณัฐวรรณ  แสนคำ",
          date: "2024-11-04",
          total: "33.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว พิชญาภัค ศิริรัตน์",
          date: "2024-11-04",
          total: "24.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ชโรบล สุหร่าย",
          date: "2024-11-04",
          total: "91.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว พัณณิตา",
          date: "2024-11-04",
          total: "66.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวธนพร นันตาสืบ",
          date: "2024-11-04",
          total: "66.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวณภัทร บุญเพิ่ม",
          date: "2024-11-04",
          total: "3.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวรินรดา",
          date: "2024-11-04",
          total: "78.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายกลวัชร ท้าวเทพ",
          date: "2024-11-04",
          total: "140.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวสุขสิริ จิตรัตน์",
          date: "2024-11-04",
          total: "140.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.ลักขณาวดี สาริบุตร",
          date: "2024-11-04",
          total: "27.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาววรรณวิสา ดาสิงห์",
          date: "2024-11-04",
          total: "114.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นส.กัญญภัส นามพร",
          date: "2024-11-04",
          total: "99.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป ณิชาภัทร",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวปริณดา ทองสันติ",
          date: "2024-11-04",
          total: "72.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวธิษณามดี ตันเรืองชาติ",
          date: "2024-11-04",
          total: "88.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นาย ชนาธิป",
          date: "2024-11-04",
          total: "114.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายเจนสรร เพชรทูล",
          date: "2024-11-04",
          total: "30.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว มณีรัตน์ ชีวินรักษาทรัพย์",
          date: "2024-11-04",
          total: "36.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป ธนวรรณ พิบูลย์",
          date: "2024-11-04",
          total: "54.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว โชติกา เมธา",
          date: "2024-11-04",
          total: "108.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวสุธีรา ถาริยะ",
          date: "2024-11-04",
          total: "240.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวจันทรวิมล มาซิว",
          date: "2024-11-04",
          total: "60.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวทักษพร",
          date: "2024-11-04",
          total: "81.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ศตพร รักษาสุขจิตร",
          date: "2024-11-04",
          total: "1650.55",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวทิพยนารี  คำชุ่ม",
          date: "2024-11-04",
          total: "27.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว พัชราพรรณ จันทร์ส่องแสง",
          date: "2024-11-04",
          total: "75.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวรมิตา",
          date: "2024-11-04",
          total: "205.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวตมิสา แก้วคำฟู",
          date: "2024-11-04",
          total: "93.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวเฉลิมขวัญ",
          date: "2024-11-04",
          total: "336.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป Miss Pimnatcha",
          date: "2024-11-04",
          total: "21.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ปัทมพร นาครักษา",
          date: "2024-11-04",
          total: "1201.83",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ชัญญานุช เครือยศ",
          date: "2024-11-04",
          total: "120.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวอิงอุษา เฉลยวาเรศ",
          date: "2024-11-04",
          total: "294.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวรวิสรา ซื่อตรง",
          date: "2024-11-04",
          total: "69.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวกนกกาญจน์ ชุมทัพ",
          date: "2024-11-04",
          total: "90.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวเมลดา ไชยมงคล",
          date: "2024-11-04",
          total: "572.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวชนิสรา สิงห์โต",
          date: "2024-11-04",
          total: "162.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวญาติกากานต์ คชสถาน",
          date: "2024-11-04",
          total: "84.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวสุธิษา กันทะวัง",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวรวีพร",
          date: "2024-11-04",
          total: "5.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพฤกษา คุณาศิระ",
          date: "2024-11-04",
          total: "12.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส ณัฏฐ์ชญาดา ศิริปิยะภิญญากุล",
          date: "2024-11-04",
          total: "738.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพัชรา แซ่จาง",
          date: "2024-11-04",
          total: "558.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพรพิชญ์ สีดาคำ",
          date: "2024-11-04",
          total: "315.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว กัลยรัตน์ มงคลเมธีกุล",
          date: "2024-11-04",
          total: "225.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว กนกวลัย มาลารัตน์",
          date: "2024-11-04",
          total: "111.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพิชญา สวัสดิ์รัมย์",
          date: "2024-11-04",
          total: "9.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป พิรญาณ์ เปาสอน",
          date: "2024-11-04",
          total: "42.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาววรรณกานต์ เชี่ยวพานิชย์",
          date: "2024-11-04",
          total: "153.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวอัญมณี บุญเชิด",
          date: "2024-11-04",
          total: "39.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาววิรงรอง",
          date: "2024-11-04",
          total: "36.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวสุภาวดี เชียงราย",
          date: "2024-11-04",
          total: "114.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวคณาลักษณ์ ทิพย์แก้ว",
          date: "2024-11-04",
          total: "360.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาววรรณิษา จงเสมอสิทธิ์",
          date: "2024-11-04",
          total: "34.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.สุกฤตา วงศ์ลังกา",
          date: "2024-11-04",
          total: "48.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพิมพ์ปพัชฌ์ ขาวผ่อง",
          date: "2024-11-04",
          total: "54.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพรรณี ปู่ยะ",
          date: "2024-11-04",
          total: "312.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพิชชาพร แก้วหนู",
          date: "2024-11-04",
          total: "15.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป ณิชารัตน์",
          date: "2024-11-04",
          total: "39.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวปานทิพย์ จำปาทอง",
          date: "2024-11-04",
          total: "30.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวฐิดาภา",
          date: "2024-11-04",
          total: "84.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.ธณัฐตา สกุลไทย",
          date: "2024-11-04",
          total: "360.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ฐิติพร    บุญประเสริฐ",
          date: "2024-11-04",
          total: "27.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวกิตติญา พิกุลทอง",
          date: "2024-11-04",
          total: "6.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวภควรัญญ์ พรอำนวยทรัพย์",
          date: "2024-11-04",
          total: "270.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวภัทรภรณ์",
          date: "2024-11-04",
          total: "32.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ณัฐธิดา รัชชามานนท์",
          date: "2024-11-04",
          total: "12.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวเบญญาภา กุนเทียน",
          date: "2024-11-04",
          total: "86.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.มนัสนันท์ เหมือนเหลา",
          date: "2024-11-04",
          total: "39.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.ชนกชนม์ เพชรพยอม",
          date: "2024-11-04",
          total: "12.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวชนิตสิรี ยอดปัญญา",
          date: "2024-11-04",
          total: "132.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวอิชยา พิลารักษ์",
          date: "2024-11-04",
          total: "816.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวณัฐพร รักษาทรัพย์",
          date: "2024-11-04",
          total: "930.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ณิชาพิชญ์ เฟื่องเพ็ชร์",
          date: "2024-11-04",
          total: "54.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวปุริสา มายอง",
          date: "2024-11-04",
          total: "693.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาววรัญญา",
          date: "2024-11-04",
          total: "132.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวธรรมิตาว์ รัตนะสุภา",
          date: "2024-11-04",
          total: "36.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวธัญชนก ไชยสวัสดิ์",
          date: "2024-11-04",
          total: "773.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวภัทร์พิชชา  บกแก้ว",
          date: "2024-11-04",
          total: "27.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ลัดดา ลุงคำแดง",
          date: "2024-11-04",
          total: "228.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวนภัสวรรณ ธงตา",
          date: "2024-11-04",
          total: "33.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพิชญ์สินี อมรนารา",
          date: "2024-11-04",
          total: "147.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายธภัทร บัวติ๊บ",
          date: "2024-11-04",
          total: "1262.94",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ภัณฑิรา มูลกันทะ",
          date: "2024-11-04",
          total: "9.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.กัลยกร ใจกล้า",
          date: "2024-11-04",
          total: "27.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.ปัณณพร จำนงค์ภักดิ์",
          date: "2024-11-04",
          total: "197.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวศิริณญา ฤทธิ์ศร",
          date: "2024-11-04",
          total: "84.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ณัฐชานันท์ คำแก้ว",
          date: "2024-11-04",
          total: "9.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวนยภัทร วัฒนาวงศ์",
          date: "2024-11-04",
          total: "102.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.จีราภรณ์ สมศรี",
          date: "2024-11-04",
          total: "225.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว เวธนี คำภีระ",
          date: "2024-11-04",
          total: "744.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวนราวัลย์ จันทร์สิงขรณ์",
          date: "2024-11-04",
          total: "12.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวสุมินตรา ไชยธรรม",
          date: "2024-11-04",
          total: "6.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว พิชชาภรณ์ เพิ่มพูล",
          date: "2024-11-04",
          total: "24.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ศศิกานต์",
          date: "2024-11-04",
          total: "9.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพีรพร",
          date: "2024-11-04",
          total: "24.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว อริยาพร ปะละใจ",
          date: "2024-11-04",
          total: "3.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป ศุพรรษา อุดมทรัพย์ปัญญา",
          date: "2024-11-04",
          total: "336.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ศิรดา ณะวรรณ์",
          date: "2024-11-04",
          total: "58.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวอรอนงค์  บุตรแสนโคตร",
          date: "2024-11-04",
          total: "36.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวชุติญา",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายกฤชเชาว์ ภูวิจิตร์",
          date: "2024-11-04",
          total: "24.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ริมปิง ประทุมพิทักษ์",
          date: "2024-11-04",
          total: "59.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวอรวรรณ ขันศิริ",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว กวินธิดา วสุวัต",
          date: "2024-11-04",
          total: "72.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป ธรัฐพงษ์ สุพรรณพิทักษ์",
          date: "2024-11-04",
          total: "13.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวชยุดา จันทร์สอน",
          date: "2024-11-04",
          total: "593.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว กมลลักษณ์ เย็นยอดวิชัย",
          date: "2024-11-04",
          total: "69.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายพงศกร โสมเสาวลักษณ์",
          date: "2024-11-04",
          total: "147.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวเกศินี",
          date: "2024-11-04",
          total: "84.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวนภัสสร คงเกตุ",
          date: "2024-11-04",
          total: "48.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวอภัสนันท์ หลีเพิ่มสุข",
          date: "2024-11-04",
          total: "30.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวชัชฎาภรณ์ จอมคำสิงห์",
          date: "2024-11-04",
          total: "36.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาววรินธร ธิตา",
          date: "2024-11-04",
          total: "14.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวณัฐธิดดา หอมดวง",
          date: "2024-11-04",
          total: "9.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวไรวินท์",
          date: "2024-11-04",
          total: "743.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.นริศรา",
          date: "2024-11-04",
          total: "23.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวสุตาภัทร กันอ่อง",
          date: "2024-11-04",
          total: "24.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวศิริทิพย์ แสงนุรักษ์",
          date: "2024-11-04",
          total: "336.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว กฤตติยาภรณ์",
          date: "2024-11-04",
          total: "19.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวสุภัทรา ลายิ้ม",
          date: "2024-11-04",
          total: "66.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวกฤษฎาภา มหาไม้",
          date: "2024-11-04",
          total: "105.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายธีรัช ใจตารักษ์",
          date: "2024-11-04",
          total: "48.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวสุชานันท์ แสงอ่อนวิมลใส",
          date: "2024-11-04",
          total: "43.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวแพรวา มนิลทิพย์",
          date: "2024-11-04",
          total: "63.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวณัจฉรียา ปัญญา",
          date: "2024-11-04",
          total: "36.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว อรุโณทัย ทิตา",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวจินต์พิศุทธิ์ จันทราช",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวมาลินี",
          date: "2024-11-04",
          total: "171.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวมณี มณีศรี",
          date: "2024-11-04",
          total: "9.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวภัทร์ชรินทร์ หอสุวรรณ์",
          date: "2024-11-04",
          total: "195.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นาย ศรศักดิ์ พันสาย",
          date: "2024-11-04",
          total: "59.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.นภัสวรรณ เส็งชื่น",
          date: "2024-11-04",
          total: "24.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.บัณฑิตา จันธิมา",
          date: "2024-11-04",
          total: "36.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวรัตนาพร ไชยวรรณ",
          date: "2024-11-04",
          total: "745.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว จิดาภา ยากรณ์",
          date: "2024-11-04",
          total: "106.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวศิริรดา จันทรศร",
          date: "2024-11-04",
          total: "12.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ฤทัยทิพย์",
          date: "2024-11-04",
          total: "35.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว อิสยาห์ ขวานทอง",
          date: "2024-11-04",
          total: "42.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ภัชศิญาภรณ์ บุญเกษม",
          date: "2024-11-04",
          total: "96.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวปิ่นนารี พร้อมมิตร",
          date: "2024-11-04",
          total: "486.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวจันทราทิพย์ ศรีวิกิจ",
          date: "2024-11-04",
          total: "180.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวกนกพร แก้วมา",
          date: "2024-11-04",
          total: "189.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพิมพ์บูรพา ยาวิปา",
          date: "2024-11-04",
          total: "540.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว พิมพ์นิภา คงนุ่น",
          date: "2024-11-04",
          total: "36.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวสิริมาภรณ์ กลายสุข",
          date: "2024-11-04",
          total: "15.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวสุรัสวดี ไวคุณา",
          date: "2024-11-04",
          total: "84.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส. กัญญ์ฐพิมพ์ ตุ้ยแก้ว",
          date: "2024-11-04",
          total: "24.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวณิชา ขุนรักษ์",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว เมภิกา",
          date: "2024-11-04",
          total: "12.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวปิยธิดา ชัยสีหา",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาววัชรมน วัฒนายากร",
          date: "2024-11-04",
          total: "21.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวณิชชาณัชย์",
          date: "2024-11-04",
          total: "24.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวอนันตญา วงษา",
          date: "2024-11-04",
          total: "15.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวทิพย์ธิชา ไชยโม",
          date: "2024-11-04",
          total: "540.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ณิชชานัท",
          date: "2024-11-04",
          total: "42.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวอาริยาดล ตันติวัฒน์พานิช",
          date: "2024-11-04",
          total: "4040.24",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวนภัสสร กันอ่อง",
          date: "2024-11-04",
          total: "6.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว พิณลดา ธนะขว้าง",
          date: "2024-11-04",
          total: "12.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว",
          date: "2024-11-04",
          total: "9.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวปพิชญา วงศ์น้อย",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นส.วิชญา เอี่ยมรัศมีกุล",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวจารุวรรณ กลิ่นแมน",
          date: "2024-11-04",
          total: "34.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว อัมฤทธิ์ วงชมพู",
          date: "2024-11-04",
          total: "190.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป ภัททิยา",
          date: "2024-11-04",
          total: "16.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวเจนนภา เป็นใจ",
          date: "2024-11-04",
          total: "15.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวสุจิรา ศรีโพธิ์",
          date: "2024-11-04",
          total: "34.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวกมนนิษฐ์ ฐิติพัชร์สาคร",
          date: "2024-11-04",
          total: "24.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวกุลบุตร ประทุมมลัย",
          date: "2024-11-04",
          total: "27.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายคณิศร สุภานัติ",
          date: "2024-11-04",
          total: "777.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นาย พัชรศิษฎ์ ด่วนทวีสุข",
          date: "2024-11-04",
          total: "3.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ชญาภรณ์ ดาวลับเมฆ",
          date: "2024-11-04",
          total: "72.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป ผู้เขียน",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวณัฐธิดา สุวรรณโคตร",
          date: "2024-11-04",
          total: "14.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายพรภวิษย์ ก่ำเเก้ว",
          date: "2024-11-04",
          total: "270.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป Naphat",
          date: "2024-11-04",
          total: "853.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ธัญชนก สุดจิตต์",
          date: "2024-11-04",
          total: "24.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายจารุเดช",
          date: "2024-11-04",
          total: "21.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวธนธรรณ์ ทองศรีจันทร์",
          date: "2024-11-04",
          total: "129.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.นรีกานต์ ชุมภู",
          date: "2024-11-04",
          total: "108.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.ภานุมาส",
          date: "2024-11-04",
          total: "30.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาววริษา",
          date: "2024-11-04",
          total: "3.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว สุดารัตน์ จันต๊ะพิงค์",
          date: "2024-11-04",
          total: "156.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายประกาศิต สอาดล้วน",
          date: "2024-11-04",
          total: "27.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.ณัฐฐญาดา เรือนป้อ",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพิมพ์ผกา",
          date: "2024-11-04",
          total: "36.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวณัฐกฤตา วงศ์คำพา",
          date: "2024-11-04",
          total: "6.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.จุติมน ดีแก้ว",
          date: "2024-11-04",
          total: "12.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส ธิญาดา แดงวิไล",
          date: "2024-11-04",
          total: "72.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวปิยาภรณ์ ยาพลัง",
          date: "2024-11-04",
          total: "9.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาววิภาดี ศิริมูลเครือ",
          date: "2024-11-04",
          total: "120.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว วณิชยา สิงห์แก้ว",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวจิตสุภา อินทวงษ์",
          date: "2024-11-04",
          total: "52.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวปรียานัฏก์",
          date: "2024-11-04",
          total: "9.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวธัญรดา ปรากฎวงศ์",
          date: "2024-11-04",
          total: "180.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวอรนาฎ มูลลารักษ์",
          date: "2024-11-04",
          total: "11.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวขวัญเกล้า ะยอม",
          date: "2024-11-04",
          total: "24.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวจิรารัตน์ บุญยงค์",
          date: "2024-11-04",
          total: "24.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว สุภัสสรา วิริยา",
          date: "2024-11-04",
          total: "48.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นส.ขวัญเมษา ท่าระเบียบ",
          date: "2024-11-04",
          total: "12.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพิมพ์ลดา สุริยะพรหม",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวแพรวา ศรีจาด",
          date: "2024-11-04",
          total: "45.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป Ms.Pimchanok",
          date: "2024-11-04",
          total: "4.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.พรนภัส",
          date: "2024-11-04",
          total: "27.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป ศิลาดล วินาวา",
          date: "2024-11-04",
          total: "15.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป ปิยดา",
          date: "2024-11-04",
          total: "35.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.สุดารัตน์ กัญจนิตานนท์",
          date: "2024-11-04",
          total: "36.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวณัฐกานต์วดี นิติรัช",
          date: "2024-11-04",
          total: "6.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.พัทธมน ปินตาสา",
          date: "2024-11-04",
          total: "21.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายภูมิ กิจสุวรรณ",
          date: "2024-11-04",
          total: "9.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวรับขวัญ เหลืองเพ็ชรงาม",
          date: "2024-11-04",
          total: "12.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป กชพร  คำแขก",
          date: "2024-11-04",
          total: "9.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ณัฐพร จันทร์แสงดี",
          date: "2024-11-04",
          total: "277.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นาย กฤษกร",
          date: "2024-11-04",
          total: "1003.95",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวชุติณัฎฐ์ เอกอัครอัญรัชต์",
          date: "2024-11-04",
          total: "1970.07",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป Onanong",
          date: "2024-11-04",
          total: "1394.47",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว",
          date: "2024-11-04",
          total: "15.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.ธรรมศิริ",
          date: "2024-11-04",
          total: "21.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวประกายแก้ว ไชยองค์การ",
          date: "2024-11-04",
          total: "321.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส. กมลชนก นิปกานนท์",
          date: "2024-11-04",
          total: "27.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายฐิติพล เลิศลลิตวงศ์",
          date: "2024-11-04",
          total: "90.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวฉัตรชนก ชโลธรพิเศษ",
          date: "2024-11-04",
          total: "45.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ศรุตา ศรีชูศิลป์",
          date: "2024-11-04",
          total: "60.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวอมรรัตน์ ปราณจันทร์",
          date: "2024-11-04",
          total: "46.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายณัฐนนท์ จิรัฏฐ์วราภรณ์",
          date: "2024-11-04",
          total: "15.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายยศพล เขียวแท้",
          date: "2024-11-04",
          total: "17.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวกมลวรรณ",
          date: "2024-11-04",
          total: "6.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายนนธวัชพิมโคตร",
          date: "2024-11-04",
          total: "30.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวชนิดา สุขเกษม",
          date: "2024-11-04",
          total: "59.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวณัฐนิช นิธิวัชรินทร์",
          date: "2024-11-04",
          total: "375.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวจินต์จุฑา ส่งฤทธิ์",
          date: "2024-11-04",
          total: "30.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายธนกฤต",
          date: "2024-11-04",
          total: "234.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวณัฐชา ตั้งวัชรินทร์",
          date: "2024-11-04",
          total: "63.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ณิชนันทน์ ปรางปราสาท",
          date: "2024-11-04",
          total: "24.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวณนนภัทร",
          date: "2024-11-04",
          total: "210.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.ชนิกานต์ กาญชนะสุวรรณ",
          date: "2024-11-04",
          total: "28.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว นันท์นภัส ผาณิตพจมาน",
          date: "2024-11-04",
          total: "34.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวณิศวรา เทพนิมิตร",
          date: "2024-11-04",
          total: "49.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป Ms. Narapath Vittayaamnuaykoon",
          date: "2024-11-04",
          total: "149.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป Ms. Chanarat",
          date: "2024-11-04",
          total: "111.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป Mr. Hance Adriene Pesarillo",
          date: "2024-11-04",
          total: "348.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป Ms. Areya Mekwattanakarn",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว พรพชร บรสทธ",
          date: "2024-11-04",
          total: "69.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ชนากาน จินดาโชตสิริ",
          date: "2024-11-04",
          total: "264.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป Napamas Phanthet",
          date: "2024-11-04",
          total: "27.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวรวิพร ทังเฮียง",
          date: "2024-11-04",
          total: "54.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวอันนา วงศ์วัฒนกิจ",
          date: "2024-11-04",
          total: "36.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส. นัทชา สุกฤตศาตนนท์",
          date: "2024-11-04",
          total: "72.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวมุทิตา",
          date: "2024-11-04",
          total: "12.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว สุชานันท์ สันทัด",
          date: "2024-11-04",
          total: "30.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นาย ณัฐชนน",
          date: "2024-11-04",
          total: "35.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวรัตนา",
          date: "2024-11-04",
          total: "30.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว จัสมิณ ขาวทอง",
          date: "2024-11-04",
          total: "30.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ปพิชฌา วีระเดชาพล",
          date: "2024-11-04",
          total: "378.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวทิฆัมพร",
          date: "2024-11-04",
          total: "135.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวดุษฎี",
          date: "2024-11-04",
          total: "60.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวขนิฐฐา ชื่นชัชวาลย์",
          date: "2024-11-04",
          total: "75.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวธัญรดา บูรณารมย์",
          date: "2024-11-04",
          total: "48.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวอภิชาดา ศรีระสันต์",
          date: "2024-11-04",
          total: "192.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวมิ่งขวัญ ศรีวัฒนาเมฆินทร์",
          date: "2024-11-04",
          total: "63.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวสุภัสสรา",
          date: "2024-11-04",
          total: "33.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ศรัญญา บุญหนุน",
          date: "2024-11-04",
          total: "36.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวนิรมล",
          date: "2024-11-04",
          total: "312.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวโชติญา ขำสุวรรณ",
          date: "2024-11-04",
          total: "305.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวฉัตรพร หินโน",
          date: "2024-11-04",
          total: "63.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายศักดา เสลาหอม",
          date: "2024-11-04",
          total: "30.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ปรียลักษณ์ วิจิตรปัญญารักษ์",
          date: "2024-11-04",
          total: "27.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวศิรดา ศรีรอด",
          date: "2024-11-04",
          total: "24.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวณัฐวลัญช์ น้อยถนอม",
          date: "2024-11-04",
          total: "46.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวชญานิษฐ์ โอภาสเสรีผดุง",
          date: "2024-11-04",
          total: "9.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวณิชารีย์ ทรัพย์ประพฤทธิ์",
          date: "2024-11-04",
          total: "36.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายนนทพัทธ์",
          date: "2024-11-04",
          total: "36.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว สุภาวิณี เขียวคล้าย",
          date: "2024-11-04",
          total: "9.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ฐิติวรญา",
          date: "2024-11-04",
          total: "40.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวธนัชพร แก้วประภาค",
          date: "2024-11-04",
          total: "93.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายนภัทร",
          date: "2024-11-04",
          total: "42.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวนันท์นภัส",
          date: "2024-11-04",
          total: "9.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวธัญชนก",
          date: "2024-11-04",
          total: "46.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายณพล พรรณเชษฐ์",
          date: "2024-11-04",
          total: "24.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.กิตติยา พิมลวัฒนา",
          date: "2024-11-04",
          total: "4.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวกมลนัทธ์ เทพขวัญ",
          date: "2024-11-04",
          total: "36.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวศศินา พรหมสมบูรณ์",
          date: "2024-11-04",
          total: "5.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวจินดาพร",
          date: "2024-11-04",
          total: "42.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ปุณยาพร คงศิริเรืองไล",
          date: "2024-11-04",
          total: "252.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายฉัตรดนัย ศรีประสาธน์",
          date: "2024-11-04",
          total: "12.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.อภิสรา",
          date: "2024-11-04",
          total: "12.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายพิริยะ พิริปุญโญ",
          date: "2024-11-04",
          total: "10.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป ทักษพร",
          date: "2024-11-04",
          total: "90.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพรทิวา",
          date: "2024-11-04",
          total: "90.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวชฎาพร นะมูลมอง",
          date: "2024-11-04",
          total: "9.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นาย พงษ์นเรศ คำแสนวงค์",
          date: "2024-11-04",
          total: "12.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวนฎา",
          date: "2024-11-04",
          total: "36.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.อภิษฎา เลาวานันท์พันธุ์",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวโยษิตา สินบัว",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นาย พศวีร์ พงศ์ปิยะประเสริฐ",
          date: "2024-11-04",
          total: "6.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาววิภาวี",
          date: "2024-11-04",
          total: "36.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายรัตน์ชนน อัมพรสุข",
          date: "2024-11-04",
          total: "30.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป ฌานวิทย์ ไม้สนธิ์",
          date: "2024-11-04",
          total: "240.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ปฏิญญา ทองฮีง",
          date: "2024-11-04",
          total: "306.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวณัฏฐ์นรี รูปเกลี้ยง",
          date: "2024-11-04",
          total: "285.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ดาริน  ฝายนายาง",
          date: "2024-11-04",
          total: "524.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวนพวรรณ  การค้า",
          date: "2024-11-04",
          total: "168.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวอลิชา ถาปามาตร",
          date: "2024-11-04",
          total: "465.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวกัลยารัตน์ วิลามาศ",
          date: "2024-11-04",
          total: "39.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวชนกนันท์ ศรีพนม",
          date: "2024-11-04",
          total: "11.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวธีรนาฏ น้ำยาทอง",
          date: "2024-11-04",
          total: "45.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวชลดา กองพอด",
          date: "2024-11-04",
          total: "465.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายเกียรติกมล สุวรรณวงค์",
          date: "2024-11-04",
          total: "33.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาววณิชญา นามมูลลา",
          date: "2024-11-04",
          total: "30.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพิชญ์นาฎ จันเพชร์",
          date: "2024-11-04",
          total: "243.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นาย กิตติภพ หอฤทธิเวช",
          date: "2024-11-04",
          total: "21.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ทอฝัน ศรีทาโล",
          date: "2024-11-04",
          total: "88.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายจักรพงษ์ หิรัญวาฑิต",
          date: "2024-11-04",
          total: "12.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวธัญญานุช เคนสุวรรณ",
          date: "2024-11-04",
          total: "788.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส. ศรีประภา ศรีเมือง",
          date: "2024-11-04",
          total: "57.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นาย ธนพัฒน์  อันทะโย",
          date: "2024-11-04",
          total: "243.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายสุวิทย์ มูลสาร",
          date: "2024-11-04",
          total: "32.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป Mr.Khunakon Chaiyalert",
          date: "2024-11-04",
          total: "165.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว อาทิตยา แสนมีมา",
          date: "2024-11-04",
          total: "32.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป Adithep Pasricha",
          date: "2024-11-04",
          total: "36.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายภัทรวุฒิ สุวรรณศร",
          date: "2024-11-04",
          total: "193.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป สุภัทรธิดา สระบัว",
          date: "2024-11-04",
          total: "298.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป มัณฑนา สมรฤทธิ์",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายฐิติพันธ์ วะเศษสร้อย",
          date: "2024-11-04",
          total: "154.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวปวันรัตน์ ธารามาศ",
          date: "2024-11-04",
          total: "60.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางกุหลาบ เพ็งพินิจ",
          date: "2024-11-04",
          total: "1014.43",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวอัจฉรา ประจันทร์สี",
          date: "2024-11-04",
          total: "128.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นาย เกียรติก้อง แก้วก่ำ",
          date: "2024-11-04",
          total: "15.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายภูมิพัฒน์ หล้าคอม",
          date: "2024-11-04",
          total: "444.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว จุฑามาศ พรมมา",
          date: "2024-11-04",
          total: "30.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวจิราวรรณ ศาสตร์สมัย",
          date: "2024-11-04",
          total: "75.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวนภสร พันธุ์แตง",
          date: "2024-11-04",
          total: "211.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวภาสินี คำขวา",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวณัฐกานต์ อุทุมพิรัตน์",
          date: "2024-11-04",
          total: "810.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.สิริญญา บุ้งทอง",
          date: "2024-11-04",
          total: "270.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวฐิติรัตน์ รัตนจตุพร",
          date: "2024-11-04",
          total: "52.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวปณตพร ภาคทอง",
          date: "2024-11-04",
          total: "570.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายหรรษธร รักษ์สิริวรกุล",
          date: "2024-11-04",
          total: "372.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว รสินทรา ยอดวิจารณ์",
          date: "2024-11-04",
          total: "2296.57",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นาย",
          date: "2024-11-04",
          total: "7405.95",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวนัทธกานต์ ใจเกื้อ",
          date: "2024-11-04",
          total: "423.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวชนากานต์ สุทธิรักษ์",
          date: "2024-11-04",
          total: "852.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว พัทธ์ธีราณี ยางธิสาร",
          date: "2024-11-04",
          total: "4785.79",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป Tipsukon",
          date: "2024-11-04",
          total: "927.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ธนภรณ์ ธรรมนิมิตร",
          date: "2024-11-04",
          total: "1915.36",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวภัสสญากรณ์",
          date: "2024-11-04",
          total: "451.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวมัญชุพร คีรีรัตน์",
          date: "2024-11-04",
          total: "1606.32",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพิยดา วงษ์พิทักษ์ชัย",
          date: "2024-11-04",
          total: "549.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว กาญจมาศย์",
          date: "2024-11-04",
          total: "90.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวปวริศา สุสุทธิ",
          date: "2024-11-04",
          total: "163.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวปาลิตา สายุปถัมภ์",
          date: "2024-11-04",
          total: "378.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวศุปณิภา ตันติชัยนุสรณ์",
          date: "2024-11-04",
          total: "2122.55",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ณิชกานต์ จุฑานฤนาท",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว อภิชญา ผาตินาวิน",
          date: "2024-11-04",
          total: "1024.32",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวธนพร ศรีบุญเรือง",
          date: "2024-11-04",
          total: "412.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป เสาวนีย์ จรุงศักดิ์สกุล",
          date: "2024-11-04",
          total: "75.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป Miss Panadda Meesakul",
          date: "2024-11-04",
          total: "1360.13",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.ภัคฐากัญญ์ คำลือ",
          date: "2024-11-04",
          total: "131.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวณัฐกานต์",
          date: "2024-11-04",
          total: "60.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป Noppanun",
          date: "2024-11-04",
          total: "21.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวมณิชญา บัวประทุม",
          date: "2024-11-04",
          total: "24.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวเพ็ญพิสุทธิ์",
          date: "2024-11-04",
          total: "1041.78",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพิยดา",
          date: "2024-11-04",
          total: "144.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายจิรพัฒน์ เกียรติสกุลทอง",
          date: "2024-11-04",
          total: "180.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวธนวรรณ โชติกมาศ",
          date: "2024-11-04",
          total: "984.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นส.นันทิตา",
          date: "2024-11-04",
          total: "3.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.นพมาศ คำพันธ์",
          date: "2024-11-04",
          total: "2275.62",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.ลลิล เดชรักษา",
          date: "2024-11-04",
          total: "2033.51",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวชุติกาญจน์",
          date: "2024-11-04",
          total: "270.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.ณัชชา เจริญพักตร์",
          date: "2024-11-04",
          total: "1186.70",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวชนิกา เหลืองศิลป",
          date: "2024-11-04",
          total: "1647.06",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวสุวิชญา เชยกลิ่นเทศ",
          date: "2024-11-04",
          total: "1006.28",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว แพรพลอย เถาว์ชาลี",
          date: "2024-11-04",
          total: "102.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพรนัชชา",
          date: "2024-11-04",
          total: "162.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวทอฝัน",
          date: "2024-11-04",
          total: "538.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวปุณณดา ไตรศิวะกุล",
          date: "2024-11-04",
          total: "263.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาววรินธร วรยศ",
          date: "2024-11-04",
          total: "417.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสวหทัยกาญจน์",
          date: "2024-11-04",
          total: "21.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส. รัญชิดา โชติรัตน์",
          date: "2024-11-04",
          total: "252.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวศศิประภา",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพิชามญชุ์",
          date: "2024-11-04",
          total: "728.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายสรยุทธ ใจเมตตา",
          date: "2024-11-04",
          total: "556.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป ณัฐนภัส ถาวรบรรจบ",
          date: "2024-11-04",
          total: "469.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวณัฐริยาธร",
          date: "2024-11-04",
          total: "275.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายปวริศฐ์ สังฆโร",
          date: "2024-11-04",
          total: "108.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวขวัญชนก ทองงิ้ว",
          date: "2024-11-04",
          total: "989.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพิสชา",
          date: "2024-11-04",
          total: "228.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวธมลวรรณ",
          date: "2024-11-04",
          total: "1971.23",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว สนธยา ประดิษฐศิลป์",
          date: "2024-11-04",
          total: "7.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวบุณยานุช อุ่ยตระกูล",
          date: "2024-11-04",
          total: "3052.59",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวนันทกานต์",
          date: "2024-11-04",
          total: "84.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพัฒน์มาศ สามารถกุล",
          date: "2024-11-04",
          total: "64.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพรชนก",
          date: "2024-11-04",
          total: "126.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป ญาณวิภา ทวีพันธ์",
          date: "2024-11-04",
          total: "974.85",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวเพชราภรณ์  คุ้มเจริญวงษ์",
          date: "2024-11-04",
          total: "552.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวมุทิตา ประกาศเภสัข",
          date: "2024-11-04",
          total: "375.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ปภัสสร",
          date: "2024-11-04",
          total: "429.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.ธัญรินทร์ บุญฤทธิ์",
          date: "2024-11-04",
          total: "90.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวสุทธิดา ภูสนาม",
          date: "2024-11-04",
          total: "678.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.ชฎาวรรณ ผิวงาม",
          date: "2024-11-04",
          total: "36.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.ณัฐภัทร อิ่นคำ",
          date: "2024-11-04",
          total: "165.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวอภิชญา เทพเสถียรธรรม",
          date: "2024-11-04",
          total: "189.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.ชญานันท์ ศรีศุกร์เจริญ",
          date: "2024-11-04",
          total: "612.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.พัชรินทร์",
          date: "2024-11-04",
          total: "420.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ญฎา สิงห์เขตต์",
          date: "2024-11-04",
          total: "281.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป กฤษฎา",
          date: "2024-11-04",
          total: "432.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวศศิริน แซ่เจีย",
          date: "2024-11-04",
          total: "1097.07",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวปภาวินตรี",
          date: "2024-11-04",
          total: "91.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว พราวรวี คล่องดี",
          date: "2024-11-04",
          total: "615.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.รศิวีร์",
          date: "2024-11-04",
          total: "180.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวปะนิดา",
          date: "2024-11-04",
          total: "106.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว สุธีมนต์ กิจพิทักษ์",
          date: "2024-11-04",
          total: "132.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป Ms.salisa",
          date: "2024-11-04",
          total: "12.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวนฤกมล โพธินาม",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวธรณ์รำไพ ถาวรกูล",
          date: "2024-11-04",
          total: "241.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวกฤติมา ชัยมาลา",
          date: "2024-11-04",
          total: "72.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวทัดดาว ชีพนุรัตน์",
          date: "2024-11-04",
          total: "2532.86",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวสิวรินทิรา ศิริกาญจนวงศ์",
          date: "2024-11-04",
          total: "201.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ชลดา ตีโยบลานทท์",
          date: "2024-11-04",
          total: "150.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว วรกมล",
          date: "2024-11-04",
          total: "136.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวชัชฎาภรณ์ คงสุวรรณ์",
          date: "2024-11-04",
          total: "438.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส. วราพร เลาห์กิติกูล",
          date: "2024-11-04",
          total: "108.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวธนพร เหมอยู่สุข",
          date: "2024-11-04",
          total: "729.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป พัชรมัย",
          date: "2024-11-04",
          total: "23.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวรุจิราภรณ์ จตุสัตถาพร",
          date: "2024-11-04",
          total: "114.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวสุภัสสรา ภควัตมงคล",
          date: "2024-11-04",
          total: "358.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว จิรัชฌา",
          date: "2024-11-04",
          total: "24.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาววัชราภรณ์",
          date: "2024-11-04",
          total: "48.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายโอบนิธิ",
          date: "2024-11-04",
          total: "108.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวอรจิรา พรหมศร",
          date: "2024-11-04",
          total: "24.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพุทธิชา คล้ายสุข",
          date: "2024-11-04",
          total: "165.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นาย นวพล",
          date: "2024-11-04",
          total: "15.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.พิมพ์กนก",
          date: "2024-11-04",
          total: "102.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.สุดารัตน์",
          date: "2024-11-04",
          total: "76.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.พัฒนวดี มัณตชาโต",
          date: "2024-11-04",
          total: "519.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวกชกร วรรณทอง",
          date: "2024-11-04",
          total: "12.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว กรรณิการ์ อุดม",
          date: "2024-11-04",
          total: "243.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพิชญาภา",
          date: "2024-11-04",
          total: "88.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.ภัทรินทร์",
          date: "2024-11-04",
          total: "12.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวปวริศา คงทัน",
          date: "2024-11-04",
          total: "87.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวธวัลรัตน์ ศรีสุข",
          date: "2024-11-04",
          total: "144.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป พัทธชญา แซ่ลี้",
          date: "2024-11-04",
          total: "204.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวจิรัสยา ตุ้มธรรมรงค์",
          date: "2024-11-04",
          total: "283.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นาวสาว สุภัชชา งามสิทธิพงศ์",
          date: "2024-11-04",
          total: "51.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวนพรัตน์",
          date: "2024-11-04",
          total: "198.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวสิริชา จำนงค์ศรี",
          date: "2024-11-04",
          total: "47.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายณฐพัชร์ เลี้ยงวัฒนหิรัณย์",
          date: "2024-11-04",
          total: "120.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นาย ภูวริสร์ เนตรประไพ",
          date: "2024-11-04",
          total: "96.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.ชาริสา พู่สิทธิ์",
          date: "2024-11-04",
          total: "957.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวนภัส ปวงคำ",
          date: "2024-11-04",
          total: "84.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว กุลิสรา เจริญสุข",
          date: "2024-11-04",
          total: "33.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวกุลยา สังขพิทักษ์",
          date: "2024-11-04",
          total: "333.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวอัญชุลี ปรียานนท์",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวเนตรตะวัน พ่วงเจริญ",
          date: "2024-11-04",
          total: "15.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวนฐอร",
          date: "2024-11-04",
          total: "32.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายธนชัย กานพรหมมา",
          date: "2024-11-04",
          total: "113.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นาวสาวอุศิมา ดีชู",
          date: "2024-11-04",
          total: "96.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวอนัญญา บุญมาก",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวณัฐฑิชา พรมทอง",
          date: "2024-11-04",
          total: "180.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.วชิรญาณ์ มูลศร",
          date: "2024-11-04",
          total: "27.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.ฐิติยา",
          date: "2024-11-04",
          total: "144.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.นิรมัย โพธิ์ศรี",
          date: "2024-11-04",
          total: "142.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวเจนจิรา ชัยมานันท์",
          date: "2024-11-04",
          total: "24.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ณฐินี แสงโสดา",
          date: "2024-11-04",
          total: "120.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป Nut",
          date: "2024-11-04",
          total: "174.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว บุษกร เมธากุลชัย",
          date: "2024-11-04",
          total: "129.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว จีดาภา สีโอ",
          date: "2024-11-04",
          total: "9.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวปณิฏฐารี เปล่งแสงทิพย์",
          date: "2024-11-04",
          total: "318.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวชนม์นิภา อ่ำช้าง",
          date: "2024-11-04",
          total: "135.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวอภิญญา สารวงษ์",
          date: "2024-11-04",
          total: "93.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวดวงนภา วรภาพ",
          date: "2024-11-04",
          total: "22.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวศศิปภัสร์ สินขจรภิรมย์",
          date: "2024-11-04",
          total: "54.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายพัทธณัฐดนย์ เเก้วทนงค์",
          date: "2024-11-04",
          total: "313.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว พรไพลิน เฟื่องใย",
          date: "2024-11-04",
          total: "27.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวณัฏฐ์ทิตา โพธิ์พูล",
          date: "2024-11-04",
          total: "35.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นันทพัทธ์",
          date: "2024-11-04",
          total: "72.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว พินัทดา ทองเนตร์",
          date: "2024-11-04",
          total: "24.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวอัสวานี ชัยเชียงเอม",
          date: "2024-11-04",
          total: "239.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป Ms.Chollarat Kumma",
          date: "2024-11-04",
          total: "144.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป กันตินันท์ ศรีน้ำเงิน",
          date: "2024-11-04",
          total: "72.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพัชรมัย",
          date: "2024-11-04",
          total: "29.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว พริมา",
          date: "2024-11-04",
          total: "70.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวนภามณี อาเก็บ",
          date: "2024-11-04",
          total: "42.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ธัญญารัตน์ สุขเกื้อ",
          date: "2024-11-04",
          total: "29.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ธีร์ลดา แป้นแหลม",
          date: "2024-11-04",
          total: "356.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวปณิษฐา เรืองสังข์",
          date: "2024-11-04",
          total: "183.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว พรธนิดา ษรจันทร์",
          date: "2024-11-04",
          total: "54.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวปวรรณรัตน์ ปฐมพัฒนา",
          date: "2024-11-04",
          total: "24.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวณัฏฐณิชา ชูเวช",
          date: "2024-11-04",
          total: "96.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวนงนภัส ปานพิมพ์ใหญ่",
          date: "2024-11-04",
          total: "6.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวชัญญานุช แสงกนึก",
          date: "2024-11-04",
          total: "70.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายธศิวัฒน์ เทนอิสสระ",
          date: "2024-11-04",
          total: "414.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว มนัสนันท์ มูลต๋า",
          date: "2024-11-04",
          total: "64.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป พิชชานันท์ ศุภะเรืองสิทธิ์",
          date: "2024-11-04",
          total: "144.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวนัทธมน วิทยาจรูญ",
          date: "2024-11-04",
          total: "69.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวชอบพอ",
          date: "2024-11-04",
          total: "84.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวนภัส วริทุม",
          date: "2024-11-04",
          total: "253.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวนิศรา อนุพงค์สิริสกุล",
          date: "2024-11-04",
          total: "113.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.ชนากานต์ คิดดี",
          date: "2024-11-04",
          total: "60.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป ชินวุฒ",
          date: "2024-11-04",
          total: "174.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.น้ำ",
          date: "2024-11-04",
          total: "9.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส. ปิยนันท์",
          date: "2024-11-04",
          total: "54.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวเขมิกา",
          date: "2024-11-04",
          total: "147.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.ดวงณัฐ วิทยาภิมัณฑน์",
          date: "2024-11-04",
          total: "99.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ปิยรัตน์",
          date: "2024-11-04",
          total: "51.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวชญากาณฑ์ โพธิ์ปาน",
          date: "2024-11-04",
          total: "78.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.ชัชชญา",
          date: "2024-11-04",
          total: "15.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวสุพรรณิการ์ มาตผาง",
          date: "2024-11-04",
          total: "66.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวชลลดา ทัพภวิมล",
          date: "2024-11-04",
          total: "21.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวธมลวรรณ คงหอม",
          date: "2024-11-04",
          total: "501.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาววีรยา เล็กวงษ์",
          date: "2024-11-04",
          total: "50.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวศิริกานดา ประมูลอรรถ",
          date: "2024-11-04",
          total: "6.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.ภูแก้ว แก้วคำศรี",
          date: "2024-11-04",
          total: "2012.56",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวศรีสกุล ธรรมาวุธ",
          date: "2024-11-04",
          total: "60.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นาย พิเชษฐ โรจนวิทยโอภาส",
          date: "2024-11-04",
          total: "6.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวณภัทรสร",
          date: "2024-11-04",
          total: "33.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ฐิติกานต์",
          date: "2024-11-04",
          total: "264.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป วราภรณ์ ศรีสุโน",
          date: "2024-11-04",
          total: "7.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวกัญญาพัชร",
          date: "2024-11-04",
          total: "45.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป ปลมต",
          date: "2024-11-04",
          total: "25.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวชนากานต์ สงอาจินต์",
          date: "2024-11-04",
          total: "25.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวคุณาพร ชิงดวง",
          date: "2024-11-04",
          total: "144.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวปัณฑิกา ญาติครบุรี",
          date: "2024-11-04",
          total: "150.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวเบญจมาส",
          date: "2024-11-04",
          total: "9.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.ทิพย์ตวรรณ สมัครการ",
          date: "2024-11-04",
          total: "288.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวฐิติภา ปัทมานนท์",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวรัตน์เกล้า",
          date: "2024-11-04",
          total: "24.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวปัณณธร ทือเกาะ",
          date: "2024-11-04",
          total: "10.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวปนัดดา เตียวสกุล",
          date: "2024-11-04",
          total: "24.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวเบญญาภา ตันเจริญ",
          date: "2024-11-04",
          total: "33.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.สุกัญญาณัฐ บัวผัน",
          date: "2024-11-04",
          total: "48.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.กาญจนาพร",
          date: "2024-11-04",
          total: "30.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวกัลยกร สิริธนะโชติ",
          date: "2024-11-04",
          total: "27.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวสุพิชชา ท่าจอหอ",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวนัสรินทร์",
          date: "2024-11-04",
          total: "44.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวภัทรานิษฐ์",
          date: "2024-11-04",
          total: "90.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ภัคจิรา บำรุงภักดิ์",
          date: "2024-11-04",
          total: "84.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ชญานิศ เรืองนุ่น",
          date: "2024-11-04",
          total: "30.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.ธฤตา ยงชัยหิรัญ",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพันธุ์ทิพา สุขสิริสรณ์",
          date: "2024-11-04",
          total: "12.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพรไพลิน",
          date: "2024-11-04",
          total: "4.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว พลอยนพกร กลิ่นอำพันธ์",
          date: "2024-11-04",
          total: "5.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาววนัสนันท์ จอจันทร์",
          date: "2024-11-04",
          total: "60.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวณตณกรรณ",
          date: "2024-11-04",
          total: "1.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวกุลฑีรา ทองเหมือน",
          date: "2024-11-04",
          total: "24.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวกุลปรียา",
          date: "2024-11-04",
          total: "84.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว พรรษา",
          date: "2024-11-04",
          total: "23.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวธารารัตน์ วิจาราณ์",
          date: "2024-11-04",
          total: "69.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายณัฐปคัลภ์ ศรีกัญจินะกุล",
          date: "2024-11-04",
          total: "24.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวกัญญารัตน์ ภิรมย์ปักษา",
          date: "2024-11-04",
          total: "72.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายพุทธิพงษ์ นาโควงค์",
          date: "2024-11-04",
          total: "12.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวชุติมา สุดใจ",
          date: "2024-11-04",
          total: "41.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวกิติยาภรณ์ ธนศภศิลป์",
          date: "2024-11-04",
          total: "42.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายอกณิษฐ์ นิมมานมงคล",
          date: "2024-11-04",
          total: "51.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส. สโรชา วิริยะศิริปัญญา",
          date: "2024-11-04",
          total: "12.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นาย อัตรคุปต์",
          date: "2024-11-04",
          total: "46.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวณภัทรชนม์ ศรีมงคล",
          date: "2024-11-04",
          total: "36.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายธนิสร สมพงษ์",
          date: "2024-11-04",
          total: "12.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวญาณุจจัย",
          date: "2024-11-04",
          total: "60.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวเมญาณี แม้นจิตร",
          date: "2024-11-04",
          total: "45.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวศุภิสรา กำเหนิดแจ้ง",
          date: "2024-11-04",
          total: "57.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว กษมา จันทร์เพ็ญ",
          date: "2024-11-04",
          total: "24.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายภคพล สงวนมานะศักดิ์",
          date: "2024-11-04",
          total: "24.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวชนัญดา บัวพันธ์",
          date: "2024-11-04",
          total: "36.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นันท์นภัส นพรัตน์อรุณ",
          date: "2024-11-04",
          total: "4.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวกมลนิตย์",
          date: "2024-11-04",
          total: "90.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายภานุกร ศักดิ์ศุภนรา",
          date: "2024-11-04",
          total: "42.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวณัฎฐณิชา โสวรรณี",
          date: "2024-11-04",
          total: "24.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว วณิศรา คำราชา",
          date: "2024-11-04",
          total: "12.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นาย ไข่ต้ม",
          date: "2024-11-04",
          total: "65.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวจิราภรณ์",
          date: "2024-11-04",
          total: "1041.78",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวณิชมน ดังชัยภูมิ",
          date: "2024-11-04",
          total: "3610.73",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายยศกร",
          date: "2024-11-04",
          total: "4376.64",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวภทรศรี มนต์ทอง",
          date: "2024-11-04",
          total: "970.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวธัญยธรณ์ มงคลไชยวัฒน์",
          date: "2024-11-04",
          total: "111.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวอัจฉริยาพร นันตา",
          date: "2024-11-04",
          total: "2456.04",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป มาริสา",
          date: "2024-11-04",
          total: "645.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายรณฤทธิ์ คล้ายใจตรง",
          date: "2024-11-04",
          total: "388.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นาวสาวปวีณนุช",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาววรัญญา ผูกจิตร",
          date: "2024-11-04",
          total: "534.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวเปมิกา พิมพ์กลาง",
          date: "2024-11-04",
          total: "118.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นาย พิพัฒน์พลเทวอนรัมย์",
          date: "2024-11-04",
          total: "570.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาววลัยพรรณ สุทธิมาตร์",
          date: "2024-11-04",
          total: "7.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวดรุ สอนวงแก้ว",
          date: "2024-11-04",
          total: "4.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายจิตติพัฒน์ ทานกิ่ง",
          date: "2024-11-04",
          total: "3.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.อมลวรรณ ประดาอินทร์",
          date: "2024-11-04",
          total: "3507.71",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวจิราภรณ์",
          date: "2024-11-04",
          total: "90.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวเพชรภัสสร นิลนนท์",
          date: "2024-11-04",
          total: "1871.71",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นาย เจษฎาภรณ์ เช่นพิมาย",
          date: "2024-11-04",
          total: "633.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวจิราภรณ์",
          date: "2024-11-04",
          total: "2805.24",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวนัฐนันฑ์ ดอกบัวเผื่อน",
          date: "2024-11-04",
          total: "1002.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวมะปราง แสงอรุณ",
          date: "2024-11-04",
          total: "102.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายธนาวุฒิ ท่วมประจักษ์",
          date: "2024-11-04",
          total: "152.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว กฤติยาภรณ์ เขื่อนคำ",
          date: "2024-11-04",
          total: "372.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายฐิติพงษ์",
          date: "2024-11-04",
          total: "648.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป Y",
          date: "2024-11-04",
          total: "120.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายกษิดิศ สุขวัฒน์",
          date: "2024-11-04",
          total: "729.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวกมลชนก",
          date: "2024-11-04",
          total: "385.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายชนาธิป พาณิชชำนาญกุล",
          date: "2024-11-04",
          total: "15.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวกนกวรรณ เอี่ยมนอก",
          date: "2024-11-04",
          total: "993.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ปาณิสรา กสิกิจ",
          date: "2024-11-04",
          total: "268.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป จิราภรณ์",
          date: "2024-11-04",
          total: "4001.25",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวชนันท์ภัสร์ ประดับศรี",
          date: "2024-11-04",
          total: "372.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นาสาวนิรุชา นามพรม",
          date: "2024-11-04",
          total: "103.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ปภัสสร นิติสาพร",
          date: "2024-11-04",
          total: "196.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส. ศิริลักษณ์  อุตสาห์",
          date: "2024-11-04",
          total: "682.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาววันวิสาข์  แถลงกัน",
          date: "2024-11-04",
          total: "37.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวสุปวีณ์ สมพาร",
          date: "2024-11-04",
          total: "132.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส. อัญชิษฐา นอกสระ",
          date: "2024-11-04",
          total: "37.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายธนภัทร อุทารสวัสดิ์",
          date: "2024-11-04",
          total: "594.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายกีรติ พิมล",
          date: "2024-11-04",
          total: "66.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายไกรวุฒิ รุกขชาติ",
          date: "2024-11-04",
          total: "814.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวสุจิวรรณ ราชคำ",
          date: "2024-11-04",
          total: "3.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพิมพ์ประภา ตอบกลาง",
          date: "2024-11-04",
          total: "9.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวกัญญาลักษณ์ เคียวกลางดอน",
          date: "2024-11-04",
          total: "36.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวมนัสชนก ทองรักสี",
          date: "2024-11-04",
          total: "66.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวจวงจันทร์ บ่อไทย",
          date: "2024-11-04",
          total: "9.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายกฤษณศักดิ์ มานะพิมพ์",
          date: "2024-11-04",
          total: "30.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายสิริพล ศิริจำรัส",
          date: "2024-11-04",
          total: "463.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวชลิดา การสมลาภ",
          date: "2024-11-04",
          total: "1722.72",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายนราวิชญ์ มะลิวัลย์",
          date: "2024-11-04",
          total: "177.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป ผศ.ดร.ศรันย์ คัมภีร์ภัทร",
          date: "2024-11-04",
          total: "217.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวเปรมศิริ นิตย์คำหาญ",
          date: "2024-11-04",
          total: "120.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายสรวิศ ธนวิสิทธิพงศ์",
          date: "2024-11-04",
          total: "2770.32",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาววริศรา นุชกลาง",
          date: "2024-11-04",
          total: "88.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวกนกวรรณ หาญชนะ",
          date: "2024-11-04",
          total: "434.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวภัทรมน ท้าวไทยชนะ",
          date: "2024-11-04",
          total: "72.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวชนัญภิกา โคมลอย",
          date: "2024-11-04",
          total: "50.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายพีระพันธ์ ชายเขียว",
          date: "2024-11-04",
          total: "579.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายตันติวุฒิ ตันวุฒิบัณฑิต",
          date: "2024-11-04",
          total: "282.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว จุฬาลักษณ์ บุบผาพรมราช",
          date: "2024-11-04",
          total: "544.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว มาริสา อัญญโพธิ์",
          date: "2024-11-04",
          total: "10.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวภัทรธิดา กมลกลาง",
          date: "2024-11-04",
          total: "14.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นาย รัชชานันท์ ชมจูมจัง",
          date: "2024-11-04",
          total: "864.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวภัทราวดี จันทร์พัฒน์",
          date: "2024-11-04",
          total: "15.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวอินทิรา ทรงราษี",
          date: "2024-11-04",
          total: "355.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว สุทธิดา รุณอากาศ",
          date: "2024-11-04",
          total: "24.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว แจ่มจรัส ศิริสุวรรณ์",
          date: "2024-11-04",
          total: "357.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวมณฑาทิพย์ เชื้อจิตร์",
          date: "2024-11-04",
          total: "105.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายกฤษณพงศ์",
          date: "2024-11-04",
          total: "106.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นาย รัฐภูมิ แซ่โง้ว",
          date: "2024-11-04",
          total: "1119.19",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวนันทกานต์ กิ่งหว้ากลาง",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวอรอนงค์ ทับเพชร",
          date: "2024-11-04",
          total: "479.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว สุดารัตน์ เจริญภู",
          date: "2024-11-04",
          total: "132.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวกมลวรรณ จันทรปัญญา",
          date: "2024-11-04",
          total: "280.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายนราวิชญ์ โทนุบล",
          date: "2024-11-04",
          total: "465.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายจิตติพงษ์ รักษาสิทธิ์",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว รมณ วรรณชากร",
          date: "2024-11-04",
          total: "66.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายกฤตพล มงคล",
          date: "2024-11-04",
          total: "197.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นาวสาว กรภัทร์ ถ่ายสูงเนิน",
          date: "2024-11-04",
          total: "21.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายระพีพัฒน์ นาคเจือ",
          date: "2024-11-04",
          total: "93.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป อธิชา สว่างแจ้ง",
          date: "2024-11-04",
          total: "139.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป พลอยศิริ บัวรอด",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวอภิญญา ทองสว่าง",
          date: "2024-11-04",
          total: "225.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายกิตติพงษ์",
          date: "2024-11-04",
          total: "30.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป กิตติทัต นับเจริญ",
          date: "2024-11-04",
          total: "144.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ศิริญาดา ปาละวงษ์",
          date: "2024-11-04",
          total: "9.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวลาวัณย์ เกลี้ยงกลิ่น",
          date: "2024-11-04",
          total: "546.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ขวัญชนก นิลมัย",
          date: "2024-11-04",
          total: "189.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวปาริชาต สุจริต",
          date: "2024-11-04",
          total: "285.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายอนุรักษ์ ชูศรี",
          date: "2024-11-04",
          total: "9.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวชลธิชา สิทธิพันธ์",
          date: "2024-11-04",
          total: "117.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป Tt",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายชนาธิป จันดา",
          date: "2024-11-04",
          total: "46.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายภัทรพงษ์ผลวัฒน์",
          date: "2024-11-04",
          total: "450.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวธนัชชพร ไพสันเทียะ",
          date: "2024-11-04",
          total: "7.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายชวรัฐ  นาริต",
          date: "2024-11-04",
          total: "15.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวสรัญญา เกิดมงคล",
          date: "2024-11-04",
          total: "3.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ขวัญฤทัย ยอดทอง",
          date: "2024-11-04",
          total: "6.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.อนันตญา เจนรบ",
          date: "2024-11-04",
          total: "234.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพรพรรณ",
          date: "2024-11-04",
          total: "758.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวธนัชชา สร้อยทรัพย์",
          date: "2024-11-04",
          total: "24.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวปวีณา โพธิ์อุดม",
          date: "2024-11-04",
          total: "181.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายธันวา ภูมะณีย์",
          date: "2024-11-04",
          total: "22.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ต้นน้ำ ษรจันทร์ศรี",
          date: "2024-11-04",
          total: "171.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ปรีดียาพร",
          date: "2024-11-04",
          total: "270.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว รัญญา เกษไธสง",
          date: "2024-11-04",
          total: "75.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายคณาธิป",
          date: "2024-11-04",
          total: "102.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวสุนิษา",
          date: "2024-11-04",
          total: "22.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นาย อิมรอน มะหะหมัดอารี",
          date: "2024-11-04",
          total: "21.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวชลธิชา  ต้นทอง",
          date: "2024-11-04",
          total: "42.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวเจนจิรา ภู่เรือน",
          date: "2024-11-04",
          total: "20.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวเจริญศรี โสรกนิษฐ์",
          date: "2024-11-04",
          total: "32.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวปัณณ์พิตรา มีสมศักดิ์",
          date: "2024-11-04",
          total: "16.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว นันทิชา สุขประเสริฐ",
          date: "2024-11-04",
          total: "24.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวทิพยวรรณ",
          date: "2024-11-04",
          total: "9.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวภัทรวรรณ",
          date: "2024-11-04",
          total: "128.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป อริสา อินอ่อน",
          date: "2024-11-04",
          total: "142.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายนัทธพงศ์ พันเชื้อ",
          date: "2024-11-04",
          total: "24.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ณัฐพัชร์ แสนรัมย์",
          date: "2024-11-04",
          total: "12.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นาย กัปตัน ศิริษา",
          date: "2024-11-04",
          total: "45.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวรวิสรา เวินสันเทียะ",
          date: "2024-11-04",
          total: "75.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว หทัยการต์",
          date: "2024-11-04",
          total: "1.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นส.ศุภาพิชญ์ พงษ์สวัสดิ์",
          date: "2024-11-04",
          total: "34.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายพีรพัฒน์ ทองเกลี้ยง",
          date: "2024-11-04",
          total: "58.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายสุรชัย แก้วหอม",
          date: "2024-11-04",
          total: "252.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายมนตรี โคตรฤชัย",
          date: "2024-11-04",
          total: "15.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายอนนต์ จันทร์แก้ว",
          date: "2024-11-04",
          total: "51.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวธัญภรณ์ ชาติเหิม",
          date: "2024-11-04",
          total: "14.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นาย ธนภัทร เสริมนอก",
          date: "2024-11-04",
          total: "54.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว พรภิมล",
          date: "2024-11-04",
          total: "131.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวอาทิตยา จิตต์ไชยวัฒน์",
          date: "2024-11-04",
          total: "15.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพลอยไพลิน เวียงสันเทียะ",
          date: "2024-11-04",
          total: "52.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายณัฐวุฒิ ฝาชัยภูมิ",
          date: "2024-11-04",
          total: "24.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวอมลรดา เรียงภวา",
          date: "2024-11-04",
          total: "34.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวปัทมพร ฉิมมาแก้ว",
          date: "2024-11-04",
          total: "110.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายธีรภัทร กุลสิงห์",
          date: "2024-11-04",
          total: "19.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวนันทิชา ช่างสอน",
          date: "2024-11-04",
          total: "7.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายธีรภัทร์ ประจำถิ่น",
          date: "2024-11-04",
          total: "23.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาววริสรา ปะโมทะโก",
          date: "2024-11-04",
          total: "9.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายญาณพัฒน์ สุขสมบัติ",
          date: "2024-11-04",
          total: "42.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวสุพัตรา นัยวิกุล",
          date: "2024-11-04",
          total: "42.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว วิรดี ตากิ่มนอก",
          date: "2024-11-04",
          total: "336.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวกัลยา แสนคูณ",
          date: "2024-11-04",
          total: "15.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส มุทธิกา ไชยภา",
          date: "2024-11-04",
          total: "36.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวกาญจนภัฏ แก้วแดง",
          date: "2024-11-04",
          total: "34.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวสิริปัญจรา พรหมนิล",
          date: "2024-11-04",
          total: "9.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวกัญชพร ฝางชัยภูมิ",
          date: "2024-11-04",
          total: "3.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส. พันพัสสา ใจวงศ์",
          date: "2024-11-04",
          total: "24.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวกัญญาวีร์ โมกศรี",
          date: "2024-11-04",
          total: "12.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นาย ภูชิต จันทร์อ่อน",
          date: "2024-11-04",
          total: "6.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพิมพ์ลภัส รัสมี",
          date: "2024-11-04",
          total: "240.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว วิชญาดา แก้วเงิน",
          date: "2024-11-04",
          total: "4.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพิมพ์วิภา แววจะบก",
          date: "2024-11-04",
          total: "15.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว เนตรดาว เรียบเรียง",
          date: "2024-11-04",
          total: "11.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวฮานีซะห์ ลาเตะ",
          date: "2024-11-04",
          total: "6.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นาย ธิติพัทธ์  แทนรินทร์",
          date: "2024-11-04",
          total: "24.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาววิภาดา กูกขุนทด",
          date: "2024-11-04",
          total: "12.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวกรวีร์ พิมสิม",
          date: "2024-11-04",
          total: "99.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวปริญญา",
          date: "2024-11-04",
          total: "15.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป กิ่งกาญจน์",
          date: "2024-11-04",
          total: "27.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวมุกมณี ศรีคำ",
          date: "2024-11-04",
          total: "536.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายภูริ แสงนวกิจ",
          date: "2024-11-04",
          total: "7.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวสิรินภานาถ ใต้กิ",
          date: "2024-11-04",
          total: "12.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวธัญญพัฒน์ ปัทธมาต",
          date: "2024-11-04",
          total: "17.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวกิรณา หิรัญถาวร",
          date: "2024-11-04",
          total: "6.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวปารัช หรั่งเล็ก",
          date: "2024-11-04",
          total: "9.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวเกษวณิช",
          date: "2024-11-04",
          total: "54.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพุธิตา ทรงจอหอ",
          date: "2024-11-04",
          total: "40.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวเกตสดา ปะถะมา",
          date: "2024-11-04",
          total: "17.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวศรุตา ภูมิดี",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวสุธิมา โคตรธรรม",
          date: "2024-11-04",
          total: "30.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายทวีศักดิ์ ช่วยพันธ์",
          date: "2024-11-04",
          total: "42.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ณริสสา คนรู้",
          date: "2024-11-04",
          total: "24.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวธนวรรณ พงศ์เลิศโกศล",
          date: "2024-11-04",
          total: "123.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพัตรพิมล ศุภเลิศ",
          date: "2024-11-04",
          total: "331.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว มนัสนันท์ จริตพานิช",
          date: "2024-11-04",
          total: "54.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวดวงพร ศรีรัตนะ",
          date: "2024-11-04",
          total: "21.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวแพรทอง พัฒนจักร์",
          date: "2024-11-04",
          total: "15.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป Miss Niracha Chaiya",
          date: "2024-11-04",
          total: "24.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวศิริวรรณ ม่วงอร่าม",
          date: "2024-11-04",
          total: "6.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวอริยา",
          date: "2024-11-04",
          total: "12.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายสุทธิรักษ์  สัชชานนท์",
          date: "2024-11-04",
          total: "30.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายเจตนิพัทธ กฤษดาเรืองศรี",
          date: "2024-11-04",
          total: "15.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพัชราพร โยธา",
          date: "2024-11-04",
          total: "50.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายเลิศอนันต์ สัพพวิญญูชน",
          date: "2024-11-04",
          total: "15.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวปริยากร เกตุกรรณ์",
          date: "2024-11-04",
          total: "29.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวปวีณา เขียวสว่าง",
          date: "2024-11-04",
          total: "15.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นาย ธนธรณ์ อุดมลาภ",
          date: "2024-11-04",
          total: "9.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว กิ่งกาญจน์ กองกาญจนะ",
          date: "2024-11-04",
          total: "15.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวจันทร์เจ้า พลทม",
          date: "2024-11-04",
          total: "120.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวปรียาภรณ์ โสชาลี",
          date: "2024-11-04",
          total: "30.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวกมลชนก กันพ้นภัย",
          date: "2024-11-04",
          total: "108.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายวชิรบดี วรรณไพบูลย์",
          date: "2024-11-04",
          total: "15.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวจิดาภา เตื่อยตุ่น",
          date: "2024-11-04",
          total: "17.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวปานรวี หวังเขตกลาง",
          date: "2024-11-04",
          total: "288.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายฉัตรมงคล ธนพุฒิพงศ์",
          date: "2024-11-04",
          total: "12.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวอรัญญา เวชวิมล",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นาย พรพิพัฒน์ จุมปา",
          date: "2024-11-04",
          total: "24.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวนิตยา สุภาพ",
          date: "2024-11-04",
          total: "228.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวสรัญญา",
          date: "2024-11-04",
          total: "24.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายวีระภัทร์ มามัง",
          date: "2024-11-04",
          total: "6.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.ธัญญดา ไทยพิทักษ์",
          date: "2024-11-04",
          total: "73.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพิชยาภรณ์  โอดรัมย์",
          date: "2024-11-04",
          total: "12.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ฐิติมาพร",
          date: "2024-11-04",
          total: "8.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวรภัทภร กองจะโป๊ะ",
          date: "2024-11-04",
          total: "12.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวสุฑามาศ มีเกาะ",
          date: "2024-11-04",
          total: "3.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวชมพูนุท เวชประสิทธิ์",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายธีรภัทร รักษาแสง",
          date: "2024-11-04",
          total: "41.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวณัฐชยา แสนเมือง",
          date: "2024-11-04",
          total: "21.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวภาวิดา สรรพสุข",
          date: "2024-11-04",
          total: "46.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวสมปรารถนา",
          date: "2024-11-04",
          total: "10.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวจริยา พวงทอง",
          date: "2024-11-04",
          total: "15.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวธิดารัตน์ กล้าหาญ",
          date: "2024-11-04",
          total: "9.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ณัฐริกา พุทธาวันดี",
          date: "2024-11-04",
          total: "3.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวญาณิศา ทองแพง",
          date: "2024-11-04",
          total: "24.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว กัญญารัตน์ พิลานนท์",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวสุกัญญา มากุญ",
          date: "2024-11-04",
          total: "43.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายชิษณุพงศ์ คากลาง",
          date: "2024-11-04",
          total: "1.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวเกศแก้ว ผลจำปา",
          date: "2024-11-04",
          total: "3.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายกัษณ ภูวภรณ์กุล",
          date: "2024-11-04",
          total: "36.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวธนภรณ์ เสมาปรุ",
          date: "2024-11-04",
          total: "23.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวกชพร อย่างบุญ",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาววราภรณ์ เชื้อเมือง",
          date: "2024-11-04",
          total: "12.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวรินรดา เจตน์นอก",
          date: "2024-11-04",
          total: "9.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวจุฑาทิพย์ กิจวิจิตร",
          date: "2024-11-04",
          total: "24.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวกนกพร กันทาเงิน",
          date: "2024-11-04",
          total: "21.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวฉัตรลัดดา ศรีซองเชษฐ์",
          date: "2024-11-04",
          total: "4.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.อลิษา จงสู้",
          date: "2024-11-04",
          total: "15.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวศิรินันท์ พูลศิลป์",
          date: "2024-11-04",
          total: "36.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวรุ่งทิวา แดวขุนทด",
          date: "2024-11-04",
          total: "8.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายอนุชา ทาประจิตร",
          date: "2024-11-04",
          total: "21.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวภัควลัญชญ์ อัครกิตติโภคิน",
          date: "2024-11-04",
          total: "29.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป อวิรุทธิ์ แสงแก้ว",
          date: "2024-11-04",
          total: "27.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพรรณวดี เทียนสว่าง",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวเจนจิรา ขันใกล้",
          date: "2024-11-04",
          total: "93.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ณัฐริกา คำไหล",
          date: "2024-11-04",
          total: "24.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายฟูไผท ดาบุตรดี",
          date: "2024-11-04",
          total: "12.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นาย ณัฐวุฒิ สิทธิอินทร์",
          date: "2024-11-04",
          total: "42.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวศรัญญา วรัญญานนท์",
          date: "2024-11-04",
          total: "4.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวนภาวดี ศรีแสง",
          date: "2024-11-04",
          total: "24.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายชิติพัทธ์ มีชัย",
          date: "2024-11-04",
          total: "60.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวศุภนิดา ศรีไพศาล",
          date: "2024-11-04",
          total: "21.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวรมิดา นาวารักษ์",
          date: "2024-11-04",
          total: "121.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวภัคชนก กุมภานนท์",
          date: "2024-11-04",
          total: "59.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพริริศา",
          date: "2024-11-04",
          total: "15.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวศุภาพิชญ์ บุญทรง",
          date: "2024-11-04",
          total: "42.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.พิชญา ฤทธิวุธ",
          date: "2024-11-04",
          total: "12.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวดรัลรัตน์ กองเพ็ชร",
          date: "2024-11-04",
          total: "41.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป จิรภิญญา ชุณหมุกดา",
          date: "2024-11-04",
          total: "149.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวบัณฑิตา อวยพร",
          date: "2024-11-04",
          total: "48.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายอดิศร บุญชู",
          date: "2024-11-04",
          total: "24.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวสิริกร วงศ์ปิยะนันต์",
          date: "2024-11-04",
          total: "135.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวกนกอร ชนะสงคราม",
          date: "2024-11-04",
          total: "10.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวสุภัสนันท์ แซ่ก้วย",
          date: "2024-11-04",
          total: "27.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวณิชาภัทร เดชพรหม",
          date: "2024-11-04",
          total: "34.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวแพรวไพลิน เชื้อผาเต่า",
          date: "2024-11-04",
          total: "2896.03",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายรัชพล งามจรัส",
          date: "2024-11-04",
          total: "549.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวปวีณา ภามี",
          date: "2024-11-04",
          total: "813.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวโชติกา บางบุญ",
          date: "2024-11-04",
          total: "1148.29",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวชมชนก สุพรรณกลาง",
          date: "2024-11-04",
          total: "981.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวศวิตา สง่าแรง",
          date: "2024-11-04",
          total: "28.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวศุภจิรา",
          date: "2024-11-04",
          total: "5760.64",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวจิราวรรณ ทิมาณะ",
          date: "2024-11-04",
          total: "4303.89",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวอรพินท์ ต่วนโต",
          date: "2024-11-04",
          total: "424.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวมนัสดา  เสดา",
          date: "2024-11-04",
          total: "132.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว มณทิรา ตาริยะ",
          date: "2024-11-04",
          total: "548.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพัชราภรณ์  ดอกไม้พุ่ม",
          date: "2024-11-04",
          total: "1492.25",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นาย ชิษณุพงศ์ เป่าป่า",
          date: "2024-11-04",
          total: "228.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว อลิศรา",
          date: "2024-11-04",
          total: "54.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวณัชฌา ชำนาญ",
          date: "2024-11-04",
          total: "666.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวเบญจรัตน์ สิริสุข",
          date: "2024-11-04",
          total: "606.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวปิยะนันท์",
          date: "2024-11-04",
          total: "30.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวประกายแก้ว ก้อนเอีย",
          date: "2024-11-04",
          total: "174.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว วรนุช ทองคำ",
          date: "2024-11-04",
          total: "2373.98",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวจิรนันท์ ปาลี",
          date: "2024-11-04",
          total: "45.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวณัฐริกาญจน์ วิชาวุฒิพงษ์",
          date: "2024-11-04",
          total: "246.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวญาติกากานต์ คชสถาน",
          date: "2024-11-04",
          total: "495.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวศมนพรรณ น้อยสวรรค์",
          date: "2024-11-04",
          total: "1079.61",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว นภัสสร. จิตรนาริญ",
          date: "2024-11-04",
          total: "37.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพันธ์วิรา แสงจันทร์",
          date: "2024-11-04",
          total: "29.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวกชพร มะลิต้น",
          date: "2024-11-04",
          total: "4.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวณัฐธศร เจริญ",
          date: "2024-11-04",
          total: "330.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวจิตราภรณ์ สุวรรณดี",
          date: "2024-11-04",
          total: "579.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวอมรรัตน์ โสฬา",
          date: "2024-11-04",
          total: "2586.99",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายกรวิชญ์ บุญเทพ",
          date: "2024-11-04",
          total: "97.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาววิลาวัณย์  ชนะภัย",
          date: "2024-11-04",
          total: "88.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวกมลวรรณ ม่วงทอง",
          date: "2024-11-04",
          total: "456.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว จิราวรรณ กรณ์รัชตไพศาล",
          date: "2024-11-04",
          total: "277.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวสุภาวดี ถุงคำ",
          date: "2024-11-04",
          total: "73.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว เสาวลักษณ์ ชะดี",
          date: "2024-11-04",
          total: "25.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวธัญชนก ธรรมศิริ",
          date: "2024-11-04",
          total: "78.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวธันยพร พรหมทอง",
          date: "2024-11-04",
          total: "1824.57",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวภัทรินทร์ กันหาคำ",
          date: "2024-11-04",
          total: "3842.95",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวนริศรา",
          date: "2024-11-04",
          total: "136.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวณัฐิกา จันทรสุวรรณ์",
          date: "2024-11-04",
          total: "12.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวสรินยา โพธิแต่ง",
          date: "2024-11-04",
          total: "129.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว พุฒน้ำบุตร อินทะ",
          date: "2024-11-04",
          total: "456.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวกัลยากร จันจินะ",
          date: "2024-11-04",
          total: "96.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวธันย์ชนก",
          date: "2024-11-04",
          total: "96.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว อาศิรา บุญก่อ",
          date: "2024-11-04",
          total: "60.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวภัทรนันท์ มหาวรรณ์",
          date: "2024-11-04",
          total: "60.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวภารดา ศรีสุทธิสัมพันธ์",
          date: "2024-11-04",
          total: "509.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป Salintip Thongchaem",
          date: "2024-11-04",
          total: "306.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวอารียา แนบเนียร",
          date: "2024-11-04",
          total: "258.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ภัทรวรรณ ดวงแก้ว",
          date: "2024-11-04",
          total: "276.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวญาณิศา แก้วก่า",
          date: "2024-11-04",
          total: "621.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายชูเกียรติ สีแก้ว",
          date: "2024-11-04",
          total: "124.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.ภัคจิรา บุญจันทร์",
          date: "2024-11-04",
          total: "42.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวธันยพร ศรีพิทักษ์",
          date: "2024-11-04",
          total: "382.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวเพ็ญศรี แซ่เจ๊า",
          date: "2024-11-04",
          total: "1271.67",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ลักษิกา ทองทะจิตร์",
          date: "2024-11-04",
          total: "378.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวธนพร ตันติไพบูลย์วงศ์",
          date: "2024-11-04",
          total: "33.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวมาริษา แก้วฟู",
          date: "2024-11-04",
          total: "80.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นาย ศุภณัฐ แสงตุ๊",
          date: "2024-11-04",
          total: "37.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาววัชรินทร์  คำคุ้ม",
          date: "2024-11-04",
          total: "574.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวศตพร",
          date: "2024-11-04",
          total: "963.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวธิติกาญจน์ สาระวิบูลย์สุข",
          date: "2024-11-04",
          total: "189.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายกรวิชญ์ บุญเทพ",
          date: "2024-11-04",
          total: "17.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวชนาพร สีพุธ",
          date: "2024-11-04",
          total: "420.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นาวสาวกนกพร แพรสุรินทร์",
          date: "2024-11-04",
          total: "372.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพิมมาดา จงมหาศาลชัย",
          date: "2024-11-04",
          total: "22.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวปรีญาเนตร รอดมณี",
          date: "2024-11-04",
          total: "60.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวกัลยาณี แก่นสน",
          date: "2024-11-04",
          total: "19.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาววศินี คณินรัตน์",
          date: "2024-11-04",
          total: "24.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป พรธีรา",
          date: "2024-11-04",
          total: "72.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ปิยพัชร์ พรสวัสดิ์กลิ่น",
          date: "2024-11-04",
          total: "222.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายชัยมงคล เสาหล่อน",
          date: "2024-11-04",
          total: "168.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป ชนิกานต์",
          date: "2024-11-04",
          total: "84.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว อนัญญา ถาวร",
          date: "2024-11-04",
          total: "33.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวภัทราพร แก้วสาแสน",
          date: "2024-11-04",
          total: "12.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป ณิชาภัทร ทาไร",
          date: "2024-11-04",
          total: "90.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพอลล่า คูเปอร์",
          date: "2024-11-04",
          total: "99.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวศุภัศสร สมท่า",
          date: "2024-11-04",
          total: "57.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวณัฐริกา สุยะตุ่น",
          date: "2024-11-04",
          total: "63.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ดลชยาพัชร์ อุทธาเครือ",
          date: "2024-11-04",
          total: "45.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวชุตินันท์ เพ็ชรมณี",
          date: "2024-11-04",
          total: "258.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นาย ธราเทพ เขตอนันต์",
          date: "2024-11-04",
          total: "160.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวณิชนันท์",
          date: "2024-11-04",
          total: "60.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาววศินี",
          date: "2024-11-04",
          total: "58.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายคุณวุติ เรืองศรี",
          date: "2024-11-04",
          total: "28.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวภัทรมน อ่อนแก้ว",
          date: "2024-11-04",
          total: "165.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวธนัฐดา ไฝสัมฤทธิ์",
          date: "2024-11-04",
          total: "259.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวเซนต์ แม่นจิตร",
          date: "2024-11-04",
          total: "99.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายครรชิต  สร้อยวงศ์คำ",
          date: "2024-11-04",
          total: "23.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวชัญญา ภู่กองเงิน",
          date: "2024-11-04",
          total: "216.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวชมภูนุช ชมพิกุล",
          date: "2024-11-04",
          total: "231.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.จิรัชญา พงษ์คุลีการ",
          date: "2024-11-04",
          total: "39.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวณิชาภัทร วิระปิง",
          date: "2024-11-04",
          total: "850.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพรรชณิการ์ ด้วงนา",
          date: "2024-11-04",
          total: "27.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวปุณยพร หมีทอง",
          date: "2024-11-04",
          total: "264.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพรณิชา กุตเปล่ง",
          date: "2024-11-04",
          total: "132.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวอริสรา วัฒนศัพท์",
          date: "2024-11-04",
          total: "12.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายทูละหม่อ คุ้มแสนแก้ว",
          date: "2024-11-04",
          total: "9.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวณัฐนันท์ เขียนวงษ์",
          date: "2024-11-04",
          total: "410.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ปาริฉัตร สิงห์แก้ว",
          date: "2024-11-04",
          total: "587.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว กนกภรณ์ สำลี",
          date: "2024-11-04",
          total: "456.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวบัญจรัตน์ นันทะวงค์",
          date: "2024-11-04",
          total: "297.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวจิตติยาภรณ์ จารณสกุล",
          date: "2024-11-04",
          total: "60.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.ภัคธีรา ตันตินวกุล",
          date: "2024-11-04",
          total: "75.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวกฤษณา กันทะวงค์",
          date: "2024-11-04",
          total: "102.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว อิสริยาภรณ์ ลิ้มสกุล",
          date: "2024-11-04",
          total: "248.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวกัญญารัตน์ พึ่งอบ",
          date: "2024-11-04",
          total: "57.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป คัชชา",
          date: "2024-11-04",
          total: "675.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวปานชนก ศรีพระจันทร์",
          date: "2024-11-04",
          total: "108.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายภัทรพล เจนจิตต์",
          date: "2024-11-04",
          total: "36.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นาย ณัฐวัฒน์ พาไธสง",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวแพรทองธาร จอยพุก",
          date: "2024-11-04",
          total: "81.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวเบญจพร",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวสายทิพย์ กรึกกรอง",
          date: "2024-11-04",
          total: "171.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวธนวรรณ กลมกลอม",
          date: "2024-11-04",
          total: "27.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ณัฐพร หัวนา",
          date: "2024-11-04",
          total: "189.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายอนุชา ฟูเต็มวงค์",
          date: "2024-11-04",
          total: "289.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวสวรินทร์ ถือแก้ว",
          date: "2024-11-04",
          total: "465.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวจุฑาทิพย์",
          date: "2024-11-04",
          total: "24.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวรมิตา ธิสรณ์",
          date: "2024-11-04",
          total: "132.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวมินลดา ดวงแก้ว",
          date: "2024-11-04",
          total: "48.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป Princharath",
          date: "2024-11-04",
          total: "409.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวชุติมา คำพันธ์",
          date: "2024-11-04",
          total: "342.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว มณีรินทร์ แสงทอง",
          date: "2024-11-04",
          total: "42.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวกฤษกมลพร ภักดี",
          date: "2024-11-04",
          total: "84.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป ปานไพลิน",
          date: "2024-11-04",
          total: "120.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวณัฐชยา คำเที่ยง",
          date: "2024-11-04",
          total: "17.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพิมมิกา",
          date: "2024-11-04",
          total: "21.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาววริศรา พาหา",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวภัทรนิษฐ์ จงปรีเปรม",
          date: "2024-11-04",
          total: "135.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป รุ่งนภา สมบูรณ์",
          date: "2024-11-04",
          total: "36.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวยลดา",
          date: "2024-11-04",
          total: "54.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวณัชชา กาหาวงค์",
          date: "2024-11-04",
          total: "348.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวนิภาภรณ์ ลาภคุณ",
          date: "2024-11-04",
          total: "6.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายณัฎฐพล ปันจุติ",
          date: "2024-11-04",
          total: "63.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ญาณิศา ลิ้มศรีประพันธ์",
          date: "2024-11-04",
          total: "1821.66",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพรวิไล วรินทร์",
          date: "2024-11-04",
          total: "114.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวภูริชญา จันทร์รังษีฉาย",
          date: "2024-11-04",
          total: "198.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป JR",
          date: "2024-11-04",
          total: "230.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวสุปรียา ปักษี",
          date: "2024-11-04",
          total: "180.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป จิรัญญา ด้วงมูล",
          date: "2024-11-04",
          total: "858.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ชลธิชา",
          date: "2024-11-04",
          total: "109.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพิชญาภา ผาใต้",
          date: "2024-11-04",
          total: "225.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวสุรีพร พุ่มทอง",
          date: "2024-11-04",
          total: "775.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวภาวิณี ทาแก้ว",
          date: "2024-11-04",
          total: "11.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวทิวตะวัน สอนกะสิน",
          date: "2024-11-04",
          total: "12.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวณัฐนรี โสสิงห์",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวสรัลชนา  การะเกษ",
          date: "2024-11-04",
          total: "36.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวกัญญาณัฐ นุชารัมย์",
          date: "2024-11-04",
          total: "23.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวกรวรรณ แก้วมะ",
          date: "2024-11-04",
          total: "435.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวกรสิริ โล่ห์คำ",
          date: "2024-11-04",
          total: "48.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวเกศรินทร์ แสนติ๊บ",
          date: "2024-11-04",
          total: "399.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวรตา หลีเกษม",
          date: "2024-11-04",
          total: "17.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวจิรวรรณ อินต๊ะมา",
          date: "2024-11-04",
          total: "243.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวศิริรัตน์ ทานัง",
          date: "2024-11-04",
          total: "90.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวกัญญาพัชร เขตสินยิ่ง",
          date: "2024-11-04",
          total: "300.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวณัฐณิชา",
          date: "2024-11-04",
          total: "37.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ช่อชมพู อังผาดผล",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว รัตนาภรณ์ โรจนแพทย์",
          date: "2024-11-04",
          total: "376.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวณัฐกฤตา อินอ่ำ",
          date: "2024-11-04",
          total: "330.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ดวงมณี",
          date: "2024-11-04",
          total: "66.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวปาณิศา แก่นคำ",
          date: "2024-11-04",
          total: "78.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวญาปกาญจน์ ยิ้มเจียม",
          date: "2024-11-04",
          total: "9.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ธารินี อินใหญ่",
          date: "2024-11-04",
          total: "297.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวธนัญชนก วิชนี",
          date: "2024-11-04",
          total: "783.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว กุลธิดา เกตุคง",
          date: "2024-11-04",
          total: "177.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวศศิกานต์ ไพรี",
          date: "2024-11-04",
          total: "30.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว กัญญาพัชร เขตสินยิ่ง",
          date: "2024-11-04",
          total: "205.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวปานชนก ศรีพระจันทร์",
          date: "2024-11-04",
          total: "36.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาววาสนา วิริยะพันธ์",
          date: "2024-11-04",
          total: "60.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวจุฑารัตน์ เนาว์ประดิษฐ์",
          date: "2024-11-04",
          total: "316.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวแพรรุ้ง อาจวาที",
          date: "2024-11-04",
          total: "7.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ศุภาพิชญ์ ลินลาด",
          date: "2024-11-04",
          total: "108.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวฐานิดา สุขศรี",
          date: "2024-11-04",
          total: "72.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวกันธิชา",
          date: "2024-11-04",
          total: "9.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวสุธาสินี",
          date: "2024-11-04",
          total: "93.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวชิดชนก ภู่ห้อย",
          date: "2024-11-04",
          total: "36.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวเพ็ญศรี แซ่เจ๊า",
          date: "2024-11-04",
          total: "45.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นาย กิตธิกรณ์ กุลวัฒชัยสีห์",
          date: "2024-11-04",
          total: "24.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพิชชาภา ชูวิรัตน์เจริญกิจ",
          date: "2024-11-04",
          total: "81.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป วชิรญาณ์ ระเวงวรรณ",
          date: "2024-11-04",
          total: "54.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป ณัฏฐณิชา ยามสุข",
          date: "2024-11-04",
          total: "90.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวสิริกร  หายโศก",
          date: "2024-11-04",
          total: "507.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว สุชาดา เทียมม่วง",
          date: "2024-11-04",
          total: "180.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพันธ์วิรา ขุนพรม",
          date: "2024-11-04",
          total: "237.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวกิรณา",
          date: "2024-11-04",
          total: "288.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวดวงหฤทัย อ้นพา",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวศศิวรรณ ศรีสมรส",
          date: "2024-11-04",
          total: "146.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวประกายเพชร สิงห์ทอง",
          date: "2024-11-04",
          total: "1094.16",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพัณณิตา พันธุ์พืช",
          date: "2024-11-04",
          total: "324.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวศุภกานต์ ธนเกียรติสกุล",
          date: "2024-11-04",
          total: "48.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวบูรณศรี คชฤทธิ์",
          date: "2024-11-04",
          total: "384.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวศิรินทรา",
          date: "2024-11-04",
          total: "12.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป อาณัติ อภิธนัง",
          date: "2024-11-04",
          total: "48.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวปภาดา ตั้งจริงใจ",
          date: "2024-11-04",
          total: "302.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวนภัทร ศุภกิจโกศล",
          date: "2024-11-04",
          total: "69.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว เขมจิรา รัตนประภาไพบูลย์",
          date: "2024-11-04",
          total: "12.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวสายเพชร เอี่ยมสะอาด",
          date: "2024-11-04",
          total: "45.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวประภาภรณ์ บุญนิยม",
          date: "2024-11-04",
          total: "12.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวอภิญญา วิสาสังข์",
          date: "2024-11-04",
          total: "15.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป ธนภรณ์ สังข์เงิน",
          date: "2024-11-04",
          total: "22.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวกันต์กมล เทศขำ",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวศุภาพิชญ์",
          date: "2024-11-04",
          total: "17.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวปวีณา สระทองพูล",
          date: "2024-11-04",
          total: "14.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป โสภณัฐ",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายนราวิชญ์ กายา",
          date: "2024-11-04",
          total: "84.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวณัฐนิชา เชื้อเนียม",
          date: "2024-11-04",
          total: "6.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวณัฐณิชา จันทร",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวสิริกร บุญไชยา",
          date: "2024-11-04",
          total: "21.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพรรณพร บุญเนียม",
          date: "2024-11-04",
          total: "87.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวรวิณท์นิภา",
          date: "2024-11-04",
          total: "24.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวริสกี",
          date: "2024-11-04",
          total: "280.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวภัณฑิรา สังวาลย์เพชร",
          date: "2024-11-04",
          total: "45.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวหทัยภัทร งามชัด",
          date: "2024-11-04",
          total: "27.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว วิโรษณาธ์ แก้วกุลวงษ์",
          date: "2024-11-04",
          total: "21.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวศิริพร ใจโต",
          date: "2024-11-04",
          total: "264.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายพิชัยยุทธ เชียงทา",
          date: "2024-11-04",
          total: "9.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวรัตติยาภรณ์",
          date: "2024-11-04",
          total: "7.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป Amonrat",
          date: "2024-11-04",
          total: "10.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว รุจิโชค พงศ์นรเศรษฐ์",
          date: "2024-11-04",
          total: "12.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวนันท์นภัส จังพินิจกุล",
          date: "2024-11-04",
          total: "270.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป ศิริประภา ไกรศรศิริกุล",
          date: "2024-11-04",
          total: "84.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายอริย์ธัช แป้นวงษ์",
          date: "2024-11-04",
          total: "12.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวณัชชา โพธิ์พุ่ม",
          date: "2024-11-04",
          total: "29.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว รมณ ชินสงคราม",
          date: "2024-11-04",
          total: "826.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ดาปนีย์ ลาภสวัสดิ์มงคล",
          date: "2024-11-04",
          total: "27.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว จุฑามาศ ไชยสถาน",
          date: "2024-11-04",
          total: "63.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวธนารีย์ เปรมปาน",
          date: "2024-11-04",
          total: "9.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป Chunchonnee",
          date: "2024-11-04",
          total: "12.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวนวภัทร์ ทามาศน้อย",
          date: "2024-11-04",
          total: "7.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวจอมขวัญ ชัยสัมฤทธิ์กิจ",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป ขวัญชนก",
          date: "2024-11-04",
          total: "47.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวสุพิชฌาย์ ก่อเขา",
          date: "2024-11-04",
          total: "6.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพัชรพร แก้วเรือง",
          date: "2024-11-04",
          total: "19.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป ฌานวิทย์ ไม้สนธิ์",
          date: "2024-11-04",
          total: "6.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวจิรัชยา โปธา",
          date: "2024-11-04",
          total: "26.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวเกวลี เสนามนตรี",
          date: "2024-11-04",
          total: "35.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพลอยไพลิน เดชพิมุข",
          date: "2024-11-04",
          total: "12.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวจิราภา ดอยแก้วขาว",
          date: "2024-11-04",
          total: "6.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวณัฐสุดา วงศ์ชุมภู",
          date: "2024-11-04",
          total: "34.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวสิขเรศ อินทภิรมย์",
          date: "2024-11-04",
          total: "9.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว อนันตญา เตชะวงค์",
          date: "2024-11-04",
          total: "9.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวจณาภรณ์ ฟองคำ",
          date: "2024-11-04",
          total: "15.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว กานต์มณี เปรียบเทียบ",
          date: "2024-11-04",
          total: "21.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพรรณกาญจน์ หมั่นเก็บ",
          date: "2024-11-04",
          total: "12.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวนริศรา คงเจริญ",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวดวงกมล แข็งการนา",
          date: "2024-11-04",
          total: "30.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวณัฎฐา เทพจัน",
          date: "2024-11-04",
          total: "105.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายเจตนิพัทธ์ ผงบุญธรรม",
          date: "2024-11-04",
          total: "4.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวชนิกานต์",
          date: "2024-11-04",
          total: "444.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว พิชญา",
          date: "2024-11-04",
          total: "3.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวดวงพร สมัครเขตกิจ",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ศิรภัสสร อนันตบุญ",
          date: "2024-11-04",
          total: "9.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายจีรวัฒน์ บัญชาชัย",
          date: "2024-11-04",
          total: "15.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวประภัสสร สินธุเดช",
          date: "2024-11-04",
          total: "21.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.ปาริชาติ ขุนศิริ",
          date: "2024-11-04",
          total: "6.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวเกศรินทร์ แสนติ๊บ",
          date: "2024-11-04",
          total: "27.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายบัญชา ภูยอด",
          date: "2024-11-04",
          total: "48.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพัทธ์ธีรา จันต๊ะวงศ์",
          date: "2024-11-04",
          total: "47.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวโยสิกา กุดโอภาส",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ณัฐกมล",
          date: "2024-11-04",
          total: "6.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป Thanyarak",
          date: "2024-11-04",
          total: "43.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.สุนิตา บำรุงไทย",
          date: "2024-11-04",
          total: "177.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวรสธร",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพิมญาดา เถาหน้อย",
          date: "2024-11-04",
          total: "16.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวรูสนา แวหะมะ",
          date: "2024-11-04",
          total: "27.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวปณิดา สุทธสิริ",
          date: "2024-11-04",
          total: "6.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวเสาวพร  ฤทธิ์กล้า",
          date: "2024-11-04",
          total: "144.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวหทัยทิพย์",
          date: "2024-11-04",
          total: "12.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวจิราภา บุญสนอง",
          date: "2024-11-04",
          total: "108.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวศิริรัตน์",
          date: "2024-11-04",
          total: "30.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว พรรษา ไทยานุสรณ์",
          date: "2024-11-04",
          total: "72.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวปพิชญา   ขยันดี",
          date: "2024-11-04",
          total: "9.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวครีมา ศรีมาวงศ์",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป ธนัชพร",
          date: "2024-11-04",
          total: "30.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป อาทิตยา ติ๊บใหม่",
          date: "2024-11-04",
          total: "11.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายศิวัช",
          date: "2024-11-04",
          total: "17.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป สองปนนท์  คำตื้อ",
          date: "2024-11-04",
          total: "1.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพุทธิชา",
          date: "2024-11-04",
          total: "12.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวธัญลักษณ์ แก้วก่า",
          date: "2024-11-04",
          total: "6.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว พิจาริน แก้วจินดา",
          date: "2024-11-04",
          total: "254.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.ปิยภา ปุลาตะโน",
          date: "2024-11-04",
          total: "34.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวนพัสสร แสนศาลา",
          date: "2024-11-04",
          total: "34.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวสุกัญญา แดงนา",
          date: "2024-11-04",
          total: "12.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวชนัตรา ถูไกรวงษ์",
          date: "2024-11-04",
          total: "117.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวณัฐกานต์ เกลี้ยงกลม",
          date: "2024-11-04",
          total: "105.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายธนภัทร นุชเจริญ",
          date: "2024-11-04",
          total: "134.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวมัณฑนา ทิวไผ่งาม",
          date: "2024-11-04",
          total: "48.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายธนาธิป อุดมเจตจำนง",
          date: "2024-11-04",
          total: "35.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส. ณิชา พงศ์ชาญวิทย์",
          date: "2024-11-04",
          total: "12.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป Miss Dathinee Niwasprakrit",
          date: "2024-11-04",
          total: "141.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวนภควรรณ สุกใส",
          date: "2024-11-04",
          total: "56.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพีรดา ปู่บุตรชา",
          date: "2024-11-04",
          total: "40.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป ณัฏฐริณีย์ มาตสี",
          date: "2024-11-04",
          total: "76.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวยศวดี ยศสุนทร",
          date: "2024-11-04",
          total: "1.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป เนตรอัปษร ขันทแพทย์",
          date: "2024-11-04",
          total: "12.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาววรรณศิการ์ ทองนำ",
          date: "2024-11-04",
          total: "14.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป วชิรพงศ์",
          date: "2024-11-04",
          total: "99.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวณัฐกานต์ เกลี้ยงกลม",
          date: "2024-11-04",
          total: "33.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายภูมิรพี สินทรจันทร์",
          date: "2024-11-04",
          total: "54.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวชนิดาภา",
          date: "2024-11-04",
          total: "24.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายกิตติภพ สิทธิชัยเนตร",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพรรณรมณ ราชคมน์",
          date: "2024-11-04",
          total: "37.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพัชพร จูมคำ",
          date: "2024-11-04",
          total: "12.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว นริศรา ไชยาศรี",
          date: "2024-11-04",
          total: "72.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ปุณยวีร์ สารกุล",
          date: "2024-11-04",
          total: "114.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวณัฐธิดา",
          date: "2024-11-04",
          total: "19.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายรัชชานนท์ ปัญญาสาย",
          date: "2024-11-04",
          total: "72.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวสันศนีย์ สารการ",
          date: "2024-11-04",
          total: "5.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว อภิณญา สีดาโคตร",
          date: "2024-11-04",
          total: "24.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวกนกวรรณ สายสวัสดิ์",
          date: "2024-11-04",
          total: "12.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวภูริชญา ทวีโภคอุดม",
          date: "2024-11-04",
          total: "23.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวอัสนี ชูกะชะบา",
          date: "2024-11-04",
          total: "5.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวอารยา สามารขึ้น",
          date: "2024-11-04",
          total: "208.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวรุ่งศิริกาญจน์ โกศลศักดิ์สกุล",
          date: "2024-11-04",
          total: "1.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวสิริณัฏฐ์ นำคิด",
          date: "2024-11-04",
          total: "30.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวปภาวินตรี",
          date: "2024-11-04",
          total: "72.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป จิราพัชร ทองเหล็กเพชร",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวกัญชพร",
          date: "2024-11-04",
          total: "39.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวธมล นาควัชรางกูร",
          date: "2024-11-04",
          total: "12.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.ฐิติรัตน์ เกตุสืบ",
          date: "2024-11-04",
          total: "298.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวปิยะดา สุรวิมลชัย",
          date: "2024-11-04",
          total: "0.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพิมประภา",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวชิดชนก อินทร์แก้ว",
          date: "2024-11-04",
          total: "72.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวชัญญารัตน์ เหลาะเหม",
          date: "2024-11-04",
          total: "99.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวปภาวรินทร์ วิชัยดิษฐ",
          date: "2024-11-04",
          total: "72.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป ทักษพร",
          date: "2024-11-04",
          total: "60.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายกฤษฎา",
          date: "2024-11-04",
          total: "132.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายณัฐพงศ์ เอมแย้ม",
          date: "2024-11-04",
          total: "6.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวตุลยา เสริมชีพ",
          date: "2024-11-04",
          total: "80.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวดีรรินทร์",
          date: "2024-11-04",
          total: "30.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.กมลพัชร ทำมา",
          date: "2024-11-04",
          total: "88.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวสุชาวลี",
          date: "2024-11-04",
          total: "12.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพิมพ์กนกนันท์ ผลเจริญ",
          date: "2024-11-04",
          total: "52.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นาย จิรภัทร ทะสะระ",
          date: "2024-11-04",
          total: "5.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นส. ศุภษร",
          date: "2024-11-04",
          total: "53.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวอริสา ปิ่นแก้ว",
          date: "2024-11-04",
          total: "41.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ฝนทิพย์ เสือสาด",
          date: "2024-11-04",
          total: "3.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ชาลิสา สุรินทร์สม",
          date: "2024-11-04",
          total: "255.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายทรัพย์ศิริ มณีรัตน์",
          date: "2024-11-04",
          total: "159.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวชนิกานต์ พันธุ์บ้านแหลม",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวกรรณภิรมย์ คลี่บำรุง",
          date: "2024-11-04",
          total: "30.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว อริสา มงกุฏสุวรรณ",
          date: "2024-11-04",
          total: "35.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวนวพร วงษ์แสน",
          date: "2024-11-04",
          total: "19.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว เพลงพิณ",
          date: "2024-11-04",
          total: "12.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวอโนชา",
          date: "2024-11-04",
          total: "12.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวภัทรภร",
          date: "2024-11-04",
          total: "8.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวรัฐนันท์ ศิริวรรณ",
          date: "2024-11-04",
          total: "39.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายณัพล นิ่มแก้ว",
          date: "2024-11-04",
          total: "34.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวสุชญา",
          date: "2024-11-04",
          total: "41.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว สุพัตรา  ซื่อสัตย์",
          date: "2024-11-04",
          total: "33.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวรัตนาภรณ์ แก้วทอง",
          date: "2024-11-04",
          total: "12.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวกุลณัฐ แก้วขาว",
          date: "2024-11-04",
          total: "15.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวอุไรวรรณ",
          date: "2024-11-04",
          total: "21.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวสุพรรณนิกา คะเนนอก",
          date: "2024-11-04",
          total: "6.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวอุไรรัตน์ โพธิ์พ่วง",
          date: "2024-11-04",
          total: "11.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว พุทธิดา ดุสิตงามมงคล",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายจิรฐิติ บุญยัง",
          date: "2024-11-04",
          total: "60.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวชุติมา นัดสูงวงค์",
          date: "2024-11-04",
          total: "42.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวหวันยิหวา สุขสมใจ",
          date: "2024-11-04",
          total: "861.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.อภิญญา จันทน์แดง",
          date: "2024-11-04",
          total: "77.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวณัฏฐ์นรี ก้านคำ",
          date: "2024-11-04",
          total: "72.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป วราลี จริณรักษ์",
          date: "2024-11-04",
          total: "41.40",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวสุพิชชา  มีไผ่ขอ",
          date: "2024-11-04",
          total: "30.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวประทานพร ดวงเทพ",
          date: "2024-11-04",
          total: "9.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป Ms.Preeyaporn",
          date: "2024-11-04",
          total: "90.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวภัทรวรรณ ยศบรรดาศักดิ์",
          date: "2024-11-04",
          total: "30.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวพิยดา",
          date: "2024-11-04",
          total: "30.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวสิราวรรณ เจิมดี",
          date: "2024-11-04",
          total: "12.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวรินรดา",
          date: "2024-11-04",
          total: "15.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป สำนักพิมพ์ภาษาและวัฒนธรรม",
          date: "2024-11-04",
          total: "124.20",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายนภสินธุ์ โภคทรัพย์",
          date: "2024-11-04",
          total: "27.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป น.ส.ตาคลี คำสิงห์",
          date: "2024-11-04",
          total: "12.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวอมลณัฐ",
          date: "2024-11-04",
          total: "3.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาววิภาดา มากดำ",
          date: "2024-11-04",
          total: "12.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ณภัชชา",
          date: "2024-11-04",
          total: "3.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวธัญวลัย พุทธิปัญญา",
          date: "2024-11-04",
          total: "0.60",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาววรรณลักษณ์ ช่วยชูหนู",
          date: "2024-11-04",
          total: "12.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาว ธัญชนก",
          date: "2024-11-04",
          total: "2.10",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายณัฐชนน ชัฎสุนทร",
          date: "2024-11-04",
          total: "18.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวอัศมะฮ์ เส้นขาว",
          date: "2024-11-04",
          total: "1.80",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นางสาวบุษราภรณ์ การจะโนศรี",
          date: "2024-11-04",
          total: "3.00",
        },
        {
          name: "ค่าช่วยขาย / จัดทำชีทสรุป นายวิมุตติ งามดีแท้",
          date: "2024-11-04",
          total: "30.00",
        },
      ],
    };
  }

  async componentDidMount() {}

  render() {
    return (
      <React.Fragment>
        {this.state.out.map((data, i) => {
          return this.renderPage(data, i);
        })}
      </React.Fragment>
    );
  }

  renderPage = (data, index) => {
    if (data.name.includes("ค่าทำ") && parseFloat(data.total) > 1000)
      data.total = ((parseFloat(data.total) * 100) / 98.5)
        .toFixed(2)
        .toString();
    return (
      <div className={style.page} key={index}>
        <div className={style.header}>ใบรับรองแทนใบเสร็จรับเงิน</div>
        <div className={style.rightText}>เลขที่ M4-{index}</div>
        <div className={style.rightText}>วันที่ {data.date}</div>

        <div className={style.topRow}>
          <div className={style.tr1}>วัน เดือน ปี</div>
          <div className={style.tr2}>รายละเอียดรายจ่าย</div>
          <div className={style.tr3}>จำนวนเงิน (บาท)</div>
          <div className={style.tr4}>หมายเหตุ</div>
        </div>

        <div className={style.dataRow}>
          <div className={style.td1}>{data.date}</div>
          <div className={style.td2}>{data.name}</div>
          <div className={style.td3}>{data.total.split(".")[0]}</div>
          <div className={style.td4}>.{data.total.split(".")[1]}</div>
          <div className={style.td5}></div>
        </div>
        <div className={style.dataRow}>
          <div className={style.td1} />
          <div className={style.td2} />
          <div className={style.td3} />
          <div className={style.td4} />
          <div className={style.td5} />
        </div>
        <div className={style.dataRow}>
          <div className={style.td1} />
          <div className={style.td2} />
          <div className={style.td3} />
          <div className={style.td4} />
          <div className={style.td5} />
        </div>
        <div className={style.dataRow}>
          <div className={style.td1} />
          <div className={style.td2} />
          <div className={style.td3} />
          <div className={style.td4} />
          <div className={style.td5} />
        </div>
        <div className={style.dataRow}>
          <div className={style.td1} />
          <div className={style.td2} />
          <div className={style.td3} />
          <div className={style.td4} />
          <div className={style.td5} />
        </div>
        <div className={style.dataRow}>
          <div className={style.td1} />
          <div className={style.td2} />
          <div className={style.td3} />
          <div className={style.td4} />
          <div className={style.td5} />
        </div>
        <div className={style.dataRow}>
          <div className={style.td1} />
          <div className={style.td2} />
          <div className={style.td3} />
          <div className={style.td4} />
          <div className={style.td5} />
        </div>

        <div className={style.preRow}>
          <div className={style.tp1}>รวมทั้งสิ้น</div>
          <div className={style.tp2}>{data.total.split(".")[0]}</div>
          <div className={style.tp3}>.{data.total.split(".")[1]}</div>
        </div>

        <div className={style.lastRow}>
          ตัวอักษร ({Util.bahttext(data.total)})
        </div>

        <div className={style.downtext1}>
          ข้าพเจ้า นายอดิเทพ ปัสรีจา (ผู้เบิกจ่าย) ตำแหน่ง (กรรมการบริษัท)
          ขอรับรองว่ารายจ่ายข้างต้นนี้ ไม่อาจเรียกใบเสร็จรับเงินจากผู้รับได้
          และข้าพเจ้าได้จ่ายไปในงานของทาง บริษัท มอร์ เซอร์วิสเซส จำกัด โดยแท้
          ตั้งแต่วันที่ {data.date} ถึงวันที่ {data.date}
        </div>

        <div className={style.righttextname3}>
          ลงชื่อ <b>อดิเทพ ปัสรีจา</b> (ผู้เบิกจ่าย)
        </div>
        <div className={style.righttextname4}>(นายอดิเทพ ปัสรีจา)</div>

        <div className={style.righttextname}>
          ลงชื่อ <b>ภูมิไผท พันธิตพงษ์</b> (ผู้อนุมัติ)
        </div>
        <div className={style.righttextname2}>(นายภูมิไผท พันธิตพงษ์)</div>

        <div className={style.downtext}>สำหรับบัญชี</div>
        <div className={style.downtext}>
          จ่ายผ่านการ<b>โอนเงิน</b>เมื่อวันที่ {data.date}
        </div>
        <div className={style.downtextlast}>
          **เอกสารนี้ใช้ทดแทนเอกสารที่ไม่สามารถได้รับใบเสร็จรับเงินได้
          สำหรับผู้ขายหรือผู้ให้บริการที่ไม่อยู่ในระบบภาษีมูลค่าเพิ่มเพื่อใช้เป็นเอกสารประกอบใบเบิกเงินที่สำรองจ่ายไปก่อน
        </div>
      </div>
    );
  };

  getNextMonth = () => {
    if (this.state.month + 1 > 12) return "01";
    else {
      if (this.state.month + 1 < 10)
        return "0" + (this.state.month + 1).toString();
      else return this.state.month + 1;
    }
  };

  getNextYear = () => {
    if (this.state.month + 1 > 12) return this.state.year + 544;
    else return this.state.year + 543;
  };
}

export default withRouter(withAlert()(EditSheet));
